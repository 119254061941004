import { FC } from 'react';

import { Add } from '@material-ui/icons';

import NormalTooltip from '../../../NormalTooltip/NormalTooltip';
import { Button } from '../../../scales';

type IntegrationsLoggedInEmptyStateProps = {
  canCreateChangeEvent: boolean;
  openCreateChangeEventModal: () => void;
};

const IntegrationsLoggedInEmptyState: FC<IntegrationsLoggedInEmptyStateProps> = ({
  canCreateChangeEvent,
  openCreateChangeEventModal,
}) => {
  return (
    <div className="flex flex-col gap-3 pt-2">
      <NormalTooltip
        title={
          !canCreateChangeEvent ? 'Change Item status to Accepted to create a Change Event' : ''
        }
      >
        <div className="flex w-min">
          <Button
            disabled={!canCreateChangeEvent}
            label="Procore Change Event"
            onClick={openCreateChangeEventModal}
            startIcon={<Add />}
            type="secondary"
          />
        </div>
      </NormalTooltip>
    </div>
  );
};

export default IntegrationsLoggedInEmptyState;
