import { ItemDueState, TimelineItemLegend } from '../timeline/timeline.types';

import WithoutDueDateLink from './WithoutDueDateLink';

export default function TimelineItemsLegend({
  data,
  projectID,
  total,
  withoutDueDateCount,
}: {
  data: TimelineItemLegend;
  projectID: UUID;
  total: number;
  withoutDueDateCount: number;
}) {
  const { counter } = data;
  const decided = counter[ItemDueState.Decided];
  const upcoming = counter[ItemDueState.Upcoming];
  const pastDue = counter[ItemDueState.PastDue];

  const legendEntry = (icon: JSX.Element, text: string) => (
    <div className="flex items-center pl-3">
      <div className="p-1">{icon}</div>
      <div className="p-1">
        <div className="type-label">{text}</div>
      </div>
    </div>
  );

  const withoutDueDateElement = (
    <WithoutDueDateLink projectID={projectID} withoutDueDateCount={withoutDueDateCount} />
  );

  const totalElement = (
    <div className="flex items-center pl-3">
      <div className="p-1">
        <div className="type-label">{`Total Items (${total})`}</div>
      </div>
    </div>
  );

  const pastDueImg = <div className="h-2 w-2 self-center bg-entities-item-pastdue" />;
  const upcomingImg = <div className="h-2 w-2 self-center bg-entities-item-upcoming" />;
  const decidedImg = <div className="h-2 w-2 self-center bg-entities-item-decided" />;

  return (
    <div className="flex h-7 w-full items-center pt-1">
      {legendEntry(pastDueImg, `Past Due (${pastDue})`)}
      {legendEntry(upcomingImg, `Upcoming (${upcoming})`)}
      {legendEntry(decidedImg, `Decided (${decided})`)}
      {withoutDueDateElement}
      {totalElement}
    </div>
  );
}
