import { InsightsInput, InsightsQuery, InsightsQueryVariables } from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { insightsProjectIDsQuery } from './queries';

export const useHasInsightsProjectsQuery = (input: InsightsInput) => {
  const insightsProjects = useQuery<InsightsQuery, InsightsQueryVariables>(
    insightsProjectIDsQuery,
    {
      variables: { input },
      skip: !input.companyID,
    }
  )?.data?.insights;
  if (!insightsProjects) {
    return false;
  }
  return insightsProjects.length > 0;
};
