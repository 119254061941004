import { FC, useState } from 'react';

import { forecastingEventTypes } from '../../../../analytics/analyticsEventProperties';
import { YC_GROUPS, YC_PROJ_LEAD } from '../../../../features';
import { ForecastingProjectsSortKey } from '../../../../generated/graphql';
import { useHasFeature } from '../../../../hooks/useHasFeature';
import { Button, Checkbox, SortManager, Table } from '../../../scales';
import FloatingFooter from '../../common/FloatingFooter';
import useSendForecastingAnalytics from '../../hooks/useSendForecastingAnalytics';

import CostCell from './cells/CostCell';
import LastUpdatedCell from './cells/LastUpdatedCell';
import MilestoneCell from './cells/MilestoneCell';
import ProjectCell from './cells/ProjectCell';

type Props = {
  loading: boolean;
  onCreateComparisonReport: (selectedProjectIDs: string[]) => void;
  onFetchMore: () => void;
  projects: ForecastingProject[];
  searchText: string;
  sortManager: SortManager;
};

const ForecastingProjectsList: FC<Props> = (props) => {
  const isGroupsEnabled = useHasFeature(YC_GROUPS);
  const isProjectLeadEnabled = useHasFeature(YC_PROJ_LEAD);
  const sendForecastingAnalytics = useSendForecastingAnalytics();

  const [selectedProjects, setSelectedProjects] = useState<Map<UUID, true>>(new Map());

  const onClearProjectSelection = () => setSelectedProjects(new Map());
  const onToggleProjectSelection = (projectID: UUID) => {
    setSelectedProjects((prevState) => {
      const newState = new Map(Array.from(prevState.entries()));
      if (newState.has(projectID)) {
        newState.delete(projectID);
      } else {
        newState.set(projectID, true);
        sendForecastingAnalytics(forecastingEventTypes.SELECT_CTA, {
          type: 'project',
          location: 'exploreProjects',
          selectedID: projectID,
        });
      }

      return newState;
    });
  };

  const onCreateComparisonReport = () => {
    const projectIDs = Array.from(selectedProjects.keys());
    props.onCreateComparisonReport(projectIDs);

    sendForecastingAnalytics(forecastingEventTypes.CREATE_REPORT_CTA, {
      location: 'exploreProjects',
      projectIDs,
    });
  };

  return (
    <>
      <Table
        columnWidths={[
          'minmax(300px, 6fr)',
          'minmax(200px, 2fr)',
          'minmax(200px, 1fr)',
          'minmax(200px, 1fr)',
          'minmax(120px, 1.2fr)',
        ]}
        entries={props.projects.map((project) => {
          const key = project.id;
          const isSelected = !!selectedProjects.get(project.id);

          return [
            {
              key,
              component: (
                <ProjectCell
                  project={project}
                  searchText={props.searchText}
                  showOrgs={isGroupsEnabled}
                  showProjectLead={isProjectLeadEnabled}
                />
              ),
            },
            { key, component: <MilestoneCell project={project} /> },
            { key, component: <CostCell project={project} /> },
            {
              key,
              component: <LastUpdatedCell project={project} />,
            },
            {
              key,
              centered: true,
              component: (
                <div className="ml-auto mr-auto">
                  <Checkbox
                    isSelected={isSelected}
                    onChange={() => onToggleProjectSelection(project.id)}
                  >
                    <></>
                  </Checkbox>
                </div>
              ),
            },
          ];
        })}
        headerContent={[
          {
            copy: 'Projects',
            key: 'projects',
            headerSortKey: ForecastingProjectsSortKey.NAME,
          },
          {
            copy: 'Active milestone',
            key: 'active-milestone',
            headerSortKey: ForecastingProjectsSortKey.MILESTONE_NAME,
          },
          {
            copy: 'Estimate',
            key: 'estimate',
            headerSortKey: ForecastingProjectsSortKey.ESTIMATE,
          },
          {
            copy: 'Last updated',
            key: 'last-updated',
            headerSortKey: ForecastingProjectsSortKey.UPDATED_AT,
          },
          { copy: 'Add to compare', key: 'add-to-compare' },
        ]}
        loading={props.loading}
        onNeedMoreData={props.onFetchMore}
        sortManager={props.sortManager}
      />
      {selectedProjects.size ? (
        <FloatingFooter>
          <div style={{ marginLeft: 'auto' }}>{selectedProjects.size} projects selected</div>
          <Button label="Clear" onClick={onClearProjectSelection} type="tertiary" />
          <Button
            label="Create a comparison report"
            onClick={onCreateComparisonReport}
            type="primary"
          />
        </FloatingFooter>
      ) : null}
    </>
  );
};

export default ForecastingProjectsList;
