import { useState } from 'react';

import { getTodayUtcNoon } from '../../../utilities/dates';
import { isNonNullable } from '../../../utilities/types';
import InsightsContingencies from '../../dragon-scales/TimelineCharts/InsightsContingencies/InsightsContingencies';
import InsightsBaseLine from '../../dragon-scales/TimelineCharts/InsightsCost/InsightsBaseLine';
import { InsightsProject } from '../hooks/useInsightsProjectsQuery';
import { getRange } from '../utils';

import { BASE_HEIGHT, CHART_HEIGHT, TOTAL_HEIGHT } from './utils';

export default function ProjectContingenciesAndAllowances(props: {
  isProjectDetails?: boolean;
  project: InsightsProject;
  startStr: string;
  endStr: string;
  hasAllowance?: boolean;
  hasContingency?: boolean;
  today?: Date;
  onClick?: () => void;
}) {
  const today = props.today ?? getTodayUtcNoon();
  const [hoverDate, setHoverDate] = useState<Date | undefined>(undefined);

  const data = props.project.contingenciesTimeseries.map((ca) => {
    return {
      date: ca.date,
      contingency: {
        total: ca.contingencyTotal,
        numDraws: ca.contingencyNumDraws,
        remaining: ca.contingencyRemaining,
        pending: ca.contingencyPending,
        draw: ca.contingencyDraw,
      },
      allowances: {
        total: ca.allowanceTotal,
        numDraws: ca.allowanceNumDraws,
        remaining: ca.allowanceRemaining,
        pending: ca.allowancePending,
        draw: ca.allowanceDraw,
      },
    };
  });
  const milestones = props.project?.milestones || [];

  const totalRange = [props.startStr, props.endStr] as [string, string];

  // TODO: base range is calculated from milestones
  const baseRange = getRange([...milestones, ...data].filter(isNonNullable));

  const chart = (
    <InsightsContingencies
      data={data}
      hasAllowance={props.hasAllowance === undefined ? true : props.hasAllowance}
      hasContingency={props.hasContingency === undefined ? true : props.hasContingency}
      height={CHART_HEIGHT}
      hoverDate={hoverDate}
      setHoverDate={setHoverDate}
      today={today}
      totalRange={totalRange}
    />
  );
  const base = (
    <InsightsBaseLine
      baseRange={baseRange}
      data={milestones}
      height={BASE_HEIGHT}
      hoverDate={hoverDate}
      today={today}
      totalRange={[new Date(totalRange[0]), new Date(totalRange[1])]}
    />
  );
  return (
    <div
      key={props.project.id}
      className={`flex h-[${TOTAL_HEIGHT}px] min-w-full cursor-pointer flex-col bg-background-primary`}
      onClick={props.onClick}
      onKeyPress={props.onClick}
    >
      {chart}
      {!props.isProjectDetails && base}
    </div>
  );
}
