import { FC, useState } from 'react';

import { Add } from '@material-ui/icons';

import {
  ProgramReportingAnalyticsEvent,
  ProgramReportingTypes,
} from '../../../analytics/analyticsEventProperties';
import { NS_PROGRAM_REPORTING } from '../../../features';
import { useHasFeature } from '../../../hooks/useHasFeature';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import DialogsProgram from '../../Dialogs/DialogsProgram/DialogsProgram';
import { Button } from '../../scales';

const NewProgramButton: FC = () => {
  const hasProgramReportingFeature = useHasFeature(NS_PROGRAM_REPORTING);

  const sendAnalytics = useSendAnalytics();

  const [isOpen, setIsOpen] = useState(false);

  // ToDo: What to do if somebody is not an admin on any projects, and how to determine that?
  if (!hasProgramReportingFeature) return null;

  return (
    <>
      <Button
        endIcon={<Add />}
        label="New Program"
        onClick={() => {
          setIsOpen(true);
          sendAnalytics(ProgramReportingAnalyticsEvent(ProgramReportingTypes.OPEN_PROGRAM_DIALOG));
        }}
        type="secondary"
      />
      <DialogsProgram isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default NewProgramButton;
