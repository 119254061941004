import { useCallback, useMemo, useState } from 'react';

import { searchEventTypes } from '../../../analytics/analyticsEventProperties';
import {
  ForecastingProjectsFilters,
  ForecastingProjectsSortBy,
  ForecastingProjectsSortKey,
  SearchResultType,
  SortDirection,
} from '../../../generated/graphql';
import { getSortManager } from '../../ForecastingRoute/ForecastingExplore/projects/hooks/useForecastingProjectsQueryParams';
import useCoreSearchPermissions from '../../HomeTab/Search/hooks/useCoreSearchPermissions';
import useSendSearchAnalytics from '../../HomeTab/Search/hooks/useSendSearchAnalytics';
import { SearchToggleValue } from '../../HomeTab/Search/SearchToggle';

import useProjectsCountsQuery from './useProjectsCountsQuery';

type SearchProjectsVariables = {
  filters: ForecastingProjectsFilters;
  search: string;
  sortBy: ForecastingProjectsSortBy;
  toggleValue: SearchToggleValue;
};

const ALL_PROJECTS_LABEL = 'All';
const MY_PROJECTS_LABEL = 'My Projects';

const DEFAULT_QUERY: SearchProjectsVariables = {
  filters: {
    companies: [],
    estimateCostRange: {},
    locations: [],
    milestoneDateRange: {},
    milestoneDesignPhases: [],
    projectTypes: [],
    statuses: [],
    types: [],
  },
  search: '',
  sortBy: {
    sortDirection: SortDirection.SORT_DESCENDING,
    sortKey: ForecastingProjectsSortKey.UPDATED_AT,
  },
  toggleValue: SearchToggleValue.ALL,
};

export default (includeCompanyProjects: boolean) => {
  const { hasAllProjectsAccess } = useCoreSearchPermissions();
  const sendSearchAnalytics = useSendSearchAnalytics();

  const { data: projectsCountData } = useProjectsCountsQuery();
  const projectsCounts = projectsCountData?.projectsCounts ?? {
    allProjectsCount: 0,
    myProjectsCount: 0,
  };

  const [filters, setFilters] = useState<ForecastingProjectsFilters>(DEFAULT_QUERY.filters);
  const [toggleValue, setToggleValue] = useState(
    hasAllProjectsAccess ? DEFAULT_QUERY.toggleValue : SearchToggleValue.MY
  );
  const [search, setSearch] = useState(DEFAULT_QUERY.search);
  const [sortBy, setSortBy] = useState<ForecastingProjectsSortBy>(DEFAULT_QUERY.sortBy);
  const [unitID, setUnitID] = useState<UUID>();
  const analyticEventTypeName = 'Projects';

  const onChangeFilters = useCallback(
    (
      filterName: keyof ForecastingProjectsFilters,
      filterValue: ForecastingProjectsFilters[keyof ForecastingProjectsFilters],
      isSendAnalytics = true
    ) => {
      setFilters((prevState) => ({
        ...prevState,
        [filterName]: filterValue,
      }));
      if (isSendAnalytics) {
        sendSearchAnalytics(searchEventTypes.SEARCH_FILTERS, {
          filterSelected: filterValue,
          filterType: filterName,
          type: analyticEventTypeName,
          filterSection: 'projectsFilter',
        });
      }
    },
    [sendSearchAnalytics]
  );

  const sortManager = useMemo(() => getSortManager(sortBy, setSortBy), [sortBy]);
  const toggleCounts = useMemo(
    () => ({
      all: projectsCounts.allProjectsCount,
      my: projectsCounts.myProjectsCount,
    }),
    [projectsCounts.allProjectsCount, projectsCounts.myProjectsCount]
  );
  const toggleParams = useMemo(() => {
    if (!hasAllProjectsAccess || !includeCompanyProjects) return undefined;
    return {
      counts: toggleCounts,
      labels: { all: ALL_PROJECTS_LABEL, my: MY_PROJECTS_LABEL },
      onChange: setToggleValue,
      value: toggleValue,
    };
  }, [includeCompanyProjects, hasAllProjectsAccess, toggleCounts, toggleValue]);

  return useMemo(
    () => ({
      onChangeFilters,
      onChangeSearch: setSearch,
      onChangeUnitID: setUnitID,
      onResetFilters: () => setFilters(DEFAULT_QUERY.filters),
      sortManager,
      toggleCounts,
      toggleParams,
      variables: {
        filters,
        searchResultType:
          includeCompanyProjects && toggleValue === SearchToggleValue.ALL
            ? SearchResultType.ALL
            : SearchResultType.MY,
        search,
        sortBy,
        unitID,
        pagination: {
          offset: 0,
          limit: 25,
        },
      },
    }),
    [
      includeCompanyProjects,
      filters,
      onChangeFilters,
      search,
      sortBy,
      sortManager,
      toggleCounts,
      toggleParams,
      toggleValue,
      unitID,
    ]
  );
};
