import { isEqual } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useUpdate } from 'react-use';

import { Close } from '@material-ui/icons';

import {
  LeadEvent,
  LeadKey,
  PageViews,
  leadEvent,
} from '../../../../analytics/analyticsEventProperties';
import { JoinProjectRoutes } from '../../../../api/gqlEnums';
import { YC_PROJ_LEAD } from '../../../../features';
import { useCompanyUserQuery } from '../../../../hooks/useCompanyUserQuery';
import { useHasFeature } from '../../../../hooks/useHasFeature';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { getProjectStatsLink } from '../../../CompanyTab/CompanyTabUtils';
import { BabyButton, Button, StatusBanner } from '../../../scales';

import { useProjectsWithoutLeadsQuery } from './hooks/useProjectsWithoutLeadsQuery';

const PROJECT_LEADS_KEY = 'PROJECT_LEADS_KEY';
const PROJECT_LEADS_SESSION_KEY = 'PROJECT_LEADS_SESSION_KEY';

export default function LeadsBanner(props: {
  page: PageViews;
  projectID?: UUID;
  description: string;
}) {
  const sendAnalytics = useSendAnalytics();
  const bannerName = 'Unassigned Project Lead';
  const isProjectLeadEnabled = useHasFeature(YC_PROJ_LEAD);
  const forceUpdate = useUpdate();
  const navigate = useNavigate();

  const companyUserQueryResult = useCompanyUserQuery();
  const role = companyUserQueryResult.data?.companyUser?.role?.name;
  const isAdmin = role === 'Admin';
  const projectsWithoutLeadsQueryResult = useProjectsWithoutLeadsQuery(
    !isAdmin || !isProjectLeadEnabled
  );
  const projectIDs = (projectsWithoutLeadsQueryResult.data?.searchProjects?.data || []).map(
    ({ id }) => id
  );
  // Hide if the project has a lead
  if (props.projectID && !projectIDs.includes(props.projectID)) return null;
  if (!isAdmin) return null;
  const storedIDsJSON = localStorage.getItem(`${PROJECT_LEADS_KEY}`);
  let storedIDs = [];
  if (storedIDsJSON) {
    try {
      storedIDs = JSON.parse(storedIDsJSON);
    } catch (error) {
      // it's fine, ignore it...
    }
  }
  if (!isProjectLeadEnabled) return null;
  if (projectsWithoutLeadsQueryResult.loading) return null;

  const startAt = localStorage.getItem('user_session_start_at');
  const storedStartAt = localStorage.getItem(`${PROJECT_LEADS_SESSION_KEY}`);

  const hasNewProjects = !isEqual(storedIDs, projectIDs);
  const hasNewSession = startAt !== storedStartAt;
  if (!hasNewSession) return null;
  const shouldDisplay = hasNewProjects && hasNewSession;
  if (!shouldDisplay) return null;

  const onLater = () => {
    localStorage.setItem(`${PROJECT_LEADS_SESSION_KEY}`, startAt ?? '');
    forceUpdate();
    sendAnalytics(
      leadEvent(LeadKey.BANNER, LeadEvent.DISMISS, {
        page: props.page,
        banner: bannerName,
      })
    );
  };
  const onUpdateNow = () => {
    let to = '';
    switch (props.page) {
      case PageViews.DASHBOARD:
        to = generateSharedPath(JoinProjectRoutes.SETTINGS, { projectId: props.projectID });
        break;
      case PageViews.PROJECT_LIST:
        to = getProjectStatsLink({});
        break;
      default:
        break;
    }
    navigate(to);
    sendAnalytics(
      leadEvent(LeadKey.BANNER, LeadEvent.UPDATE, {
        page: props.page,
        banner: bannerName,
      })
    );
  };
  const onClose = () => {
    localStorage.setItem(`${PROJECT_LEADS_KEY}`, JSON.stringify(projectIDs));
    forceUpdate();
    sendAnalytics(
      leadEvent(LeadKey.BANNER, LeadEvent.CLOSE, {
        page: props.page,
        banner: bannerName,
      })
    );
  };

  const banner = (
    <div className="flex w-full items-center">
      <div>
        <div className="type-body1">Help your colleagues find projects more easily.</div>
        <div className="type-label">{props.description}</div>
      </div>
      <div className="ml-auto flex items-center">
        <Button label="Remind me later" onClick={onLater} type="tertiary" />
        <Button label="Update Now" onClick={onUpdateNow} type="secondary" />
        <div className="p-3">
          <BabyButton aria-label="Close banner" icon={<Close />} onClick={onClose} />
        </div>
      </div>
    </div>
  );

  return (
    <div className="px-5 py-3">
      <StatusBanner type="warning">{banner}</StatusBanner>
    </div>
  );
}
