import { getTodayAtUtcNoon } from '../../../../utilities/dates';
import { useItemsTimeline } from '../../../Timeline/hooks/ItemsHook';
import useMemoWrapper from '../../../useMemoWrapper';
import { computeItemDecidedDate } from '../../utils';

import { useItemStatusEventsQuery } from './useItemStatusEventsQuery';

export function useItems(projectID: UUID, milestoneID: UUID) {
  const itemStatusEventsQueryResult = useItemStatusEventsQuery(projectID, milestoneID || '');
  const itemsTimelineQueryResult = useItemsTimeline(projectID);
  const itemsList = itemsTimelineQueryResult.data?.itemsList?.items ?? [];
  const today = getTodayAtUtcNoon();
  const items = useMemoWrapper(
    computeItemDecidedDate,
    itemsList,
    today,
    itemStatusEventsQueryResult.data
  );
  return {
    items,
    loading: itemsTimelineQueryResult.loading || itemStatusEventsQueryResult.loading,
  };
}
