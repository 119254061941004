import { FC } from 'react';

import ChartsPieGraphGradient from './ChartsPieGraphGradient';
import ChartsPieGraphLabels from './ChartsPieGraphLabels';
import ChartsPieGraphPaths from './ChartsPieGraphPaths';
import { CenterLabel, PieChartData, PieChartOptions, PieChartSize } from './ChartsPieGraphUtils';

type Props = {
  centerLabel?: CenterLabel;
  chartSize: PieChartSize;
  colors: string[];
  data: PieChartData[];
  dataOther?: PieChartData[]; // Represents data that is combined into "Other" entry (data from this is used for tooltips)
  displayLegendTooltip?: boolean;
  displaySectionTooltip?: boolean;
  isCurrency?: boolean;
  labelStyle?: string;
  options?: PieChartOptions;
  title?: string;
};
const ChartsPieGraph: FC<Props> = ({
  centerLabel,
  chartSize,
  colors,
  data,
  dataOther,
  displayLegendTooltip,
  displaySectionTooltip,
  isCurrency,
  labelStyle,
  options,
  title,
}) => {
  if (!data) return <></>;

  return (
    <div style={{ position: 'relative' }}>
      <svg height={chartSize.diameter} width={chartSize.diameter}>
        {/* If there is only a single data entry then we can just draw a circle */}
        {data.length > 1 ? (
          <ChartsPieGraphPaths
            chartSize={chartSize}
            colors={colors}
            data={data}
            dataOther={dataOther}
            displaySectionTooltip={displaySectionTooltip}
            isCurrency={isCurrency}
            options={options}
          />
        ) : (
          <circle cx="50%" cy="50%" fill={colors[0]} r={chartSize.diameter / 2} />
        )}
        <ChartsPieGraphGradient chartSize={chartSize} options={options} />
        {chartSize.insideDiameter ? ( // A white circle to block out the center
          <circle
            className="fill-background-primary"
            cx="50%"
            cy="50%"
            r={chartSize.insideDiameter / 2}
          />
        ) : undefined}
        <ChartsPieGraphLabels
          centerLabel={centerLabel}
          chartSize={chartSize}
          colors={colors}
          data={data}
          displayLegendTooltip={displayLegendTooltip}
          isCurrency={isCurrency}
          labelStyle={labelStyle}
          title={title}
        />
      </svg>
    </div>
  );
};

export default ChartsPieGraph;
