import { formatCost } from '../../utilities/currency';
import BuildingCommercial from '../Icons/BuildingCommercial';
import Location from '../Icons/Location';
import Program from '../Icons/Program';
import { Chip } from '../scales';

type Props = {
  programName: string;
  numberOfProjects: number;
  location: string;
  programType: string;
  combinedEstimate: number;
  combinedBudget: number;
  combinedRunningTotal: number;
};

export default function ProgramDashboardHeader({
  programName,
  numberOfProjects,
  location,
  programType,
  combinedEstimate,
  combinedBudget,
  combinedRunningTotal,
}: Props) {
  return (
    <div className="flex w-full justify-between gap-2">
      <div className="flex flex-col gap-2">
        <div className="type-heading2">{programName}</div>
        <div className="flex gap-2">
          <Chip icon={<Program />} text={`${numberOfProjects} Projects`} />
          <Chip icon={<Location />} text={location} />
          <Chip icon={<BuildingCommercial />} text={programType} />
        </div>
      </div>
      <div className="flex flex-col justify-end gap-2">
        <div className="flex justify-between gap-6 type-body2">
          <div>Combined Estimate</div>
          <div className="type-table-number">{formatCost(combinedEstimate)}</div>
        </div>
        <div className="flex justify-between gap-6 type-body2">
          <div>Combined Running Total</div>
          <div className="type-table-number">{formatCost(combinedRunningTotal)}</div>
        </div>
        <div className="flex justify-between gap-6 text-entities-milestone type-body2">
          <div>Combined Budget</div>
          <div className="type-table-number">{formatCost(combinedBudget)}</div>
        </div>
      </div>
    </div>
  );
}
