import React from 'react';

import { Org, OrgNode } from '../../../generated/graphql';
import { chartColors } from '../../../theme/colors';
import ChartsPieGraph from '../../Charts/ChartsD3/ChartsPieGraph/ChartsPieGraph';
import useMemoWrapper from '../../useMemoWrapper';
import { HeaderDisplayBy } from '../InsightsListHeaderPieBar';

interface HeaderDonutChartsProps {
  insightsProjects: InsightsProjects[];
  orgs: Org[];
  selectedDisplayBy: HeaderDisplayBy;
}

const HeaderDonutCharts: React.FC<HeaderDonutChartsProps> = ({
  insightsProjects,
  orgs,
  selectedDisplayBy,
}) => {
  const pieChartDataTypes = useMemoWrapper(
    generatePieChartDataTypes,
    insightsProjects,
    selectedDisplayBy
  );
  const pieChartDataLeads = useMemoWrapper(
    generatePieChartDataLeads,
    insightsProjects,
    selectedDisplayBy
  );
  const { pieChartTopOrgs, selectedOrgsData, pieChartOtherOrgs } = useMemoWrapper(
    generatePieChartDataOrgs,
    insightsProjects,
    orgs,
    selectedDisplayBy
  );

  return (
    <div className="flex gap-2">
      {/* Orgs Donut */}
      {selectedOrgsData.map((orgData, i) => {
        return (
          <div key={orgData.orgName}>
            <div>
              <ChartsPieGraph
                centerLabel={{
                  label: orgData.orgName,
                  sublabel: `(${String(pieChartTopOrgs[i].length)})`,
                }}
                chartSize={{
                  diameter: 100,
                  insideDiameter: 70,
                }}
                colors={chartColors}
                data={pieChartTopOrgs[i]}
                dataOther={pieChartOtherOrgs[i]}
                displayLegendTooltip
                displaySectionTooltip
                isCurrency={Boolean(selectedDisplayBy === HeaderDisplayBy.VOLUME)}
                labelStyle="type-label"
                title={orgData.orgName}
              />
            </div>
          </div>
        );
      })}

      {/* Leads Donut */}
      {pieChartDataLeads.topEntries.length > 0 && (
        <div>
          <ChartsPieGraph
            centerLabel={{
              label: 'Leads',
              sublabel: `(${String(pieChartDataLeads.topEntries.length)})`,
            }}
            chartSize={{
              diameter: 100,
              insideDiameter: 70,
            }}
            colors={chartColors}
            data={pieChartDataLeads.topEntries}
            displayLegendTooltip
            displaySectionTooltip
            isCurrency={Boolean(selectedDisplayBy === HeaderDisplayBy.VOLUME)}
            labelStyle="type-label"
            title="Leads"
          />
        </div>
      )}

      {/* Types Donut */}
      {pieChartDataTypes.topEntries.length > 0 && (
        <div>
          <ChartsPieGraph
            centerLabel={{
              label: 'Type',
              sublabel: `(${String(pieChartDataTypes.topEntries.length)})`,
            }}
            chartSize={{
              diameter: 100,
              insideDiameter: 70,
            }}
            colors={chartColors}
            data={pieChartDataTypes.topEntries}
            displayLegendTooltip
            displaySectionTooltip
            isCurrency={Boolean(selectedDisplayBy === HeaderDisplayBy.VOLUME)}
            labelStyle="type-label"
            title="Types"
          />
        </div>
      )}
    </div>
  );
};

export default HeaderDonutCharts;

//
// Project Types Util
//
const generatePieChartDataTypes = (
  insightsProjects: InsightsProjects[],
  selectedDisplayBy: HeaderDisplayBy
) => {
  const typeSummaryMap: Record<string, { count: number; total: number }> = {};

  // Process insightsProjects in a single loop
  insightsProjects.forEach(({ type, runningTotal }) => {
    if (!typeSummaryMap[type]) {
      typeSummaryMap[type] = { count: 0, total: 0 };
    }

    typeSummaryMap[type].count += 1;

    if (runningTotal !== undefined && runningTotal !== null) {
      typeSummaryMap[type].total += Number(runningTotal);
    }
  });

  // Map and sort the result
  const pieChartDataTypes = Object.entries(typeSummaryMap)
    .map(([name, { count, total }]) => ({
      name,
      share: selectedDisplayBy === HeaderDisplayBy.COUNT ? count : total,
    }))
    .sort((a, b) => b.share - a.share); // Sort by share value

  let topEntries = pieChartDataTypes;
  let otherEntries: { name: string; share: number }[] = [];

  // If more than 19 entries, combine the rest into "Other"
  if (pieChartDataTypes.length > 19) {
    topEntries = pieChartDataTypes.slice(0, 19);
    otherEntries = pieChartDataTypes.slice(19);

    const otherShare = otherEntries.reduce((acc, { share }) => acc + share, 0);

    topEntries.push({ name: 'Other Project Types', share: otherShare });
  }

  return {
    topEntries, // The top 19 entries + "Other"
    otherEntries, // The entries that were combined into "Other"
  };
};

//
// Project Leads Util
//
const generatePieChartDataLeads = (
  insightsProjects: InsightsProjects[],
  selectedDisplayBy: HeaderDisplayBy
) => {
  const leadSummaryMap: Record<string, { count: number; total: number }> = {};

  // Step 1: Process each insight project
  insightsProjects.forEach(({ projectLeadName, runningTotal }) => {
    let lead = projectLeadName?.trim();

    if (!lead || lead === '') {
      lead = 'Unassigned'; // Assign to "Unassigned" if lead is missing or empty
    }

    if (!leadSummaryMap[lead]) {
      leadSummaryMap[lead] = { count: 0, total: 0 };
    }

    leadSummaryMap[lead].count += 1;

    if (runningTotal !== undefined && runningTotal !== null) {
      leadSummaryMap[lead].total += Number(runningTotal);
    }
  });

  // Step 2: Map the results
  let pieChartDataLeads = Object.entries(leadSummaryMap).map(([name, { count, total }]) => ({
    name,
    share: selectedDisplayBy === HeaderDisplayBy.COUNT ? count : total,
  }));

  // Step 3: Sort by share value, but ensure "Unassigned" is always last
  pieChartDataLeads = pieChartDataLeads.sort((a, b) => {
    if (a.name === 'Unassigned') return 1; // "Unassigned" should come last
    if (b.name === 'Unassigned') return -1; // Ensure other entries come before "Unassigned"
    return b.share - a.share; // Sort by share value for all other entries
  });

  let topEntries = pieChartDataLeads;
  let otherEntries: { name: string; share: number }[] = [];

  // If more than 19 entries, combine the rest into "Other"
  if (pieChartDataLeads.length > 19) {
    topEntries = pieChartDataLeads.slice(0, 19);
    otherEntries = pieChartDataLeads.slice(19);

    const otherShare = otherEntries.reduce((acc, { share }) => acc + share, 0);

    topEntries.push({ name: 'Other Project Leads', share: otherShare });
  }

  return {
    topEntries, // The top 19 entries + "Other"
    otherEntries, // The entries that were combined into "Other"
  };
};

//
// Orgs Util functions
//
const findTopLevelNode = (node: OrgNode, nodeMap: Map<string, OrgNode>): OrgNode => {
  let currentNode = node;
  while (currentNode.parentID) {
    currentNode = nodeMap.get(currentNode.parentID)!;
  }
  return currentNode;
};

const generatePieChartDataOrgs = (
  insightsProjects: InsightsProjects[],
  orgs: Org[],
  selectedDisplayBy: HeaderDisplayBy
) => {
  const nodeMap: Map<string, OrgNode> = new Map();
  orgs.forEach((org) => {
    org.nodes.forEach((node: OrgNode) => {
      nodeMap.set(node.id, node);
    });
  });

  // Create a record of top level node for each node
  const nodeToTopLevelMap: Record<string, string> = {};
  const nodeToTopLevelMapIDs: Record<string, OrgNode> = {};
  orgs.forEach((org) => {
    org.nodes.forEach((node: OrgNode) => {
      const topLevelNode = findTopLevelNode(node, nodeMap);
      nodeToTopLevelMap[node.name] = topLevelNode.name;
      nodeToTopLevelMapIDs[node.id] = topLevelNode;
    });
  });

  // Start with creating the organized data for pie charts
  const selectedOrgsData: {
    orgName: string;
    selectedNodeDetails: {
      selectedOrgName: string;
      selectedOrgTopLevelNodeName: string;
      projectID: string;
      runningTotal: number;
    }[];
  }[] = orgs.map((org) => ({
    orgName: org.name,
    selectedNodeDetails: [],
  }));

  // Loop through all projects and find nodes and relate them to its parent top level node
  insightsProjects.forEach((project) => {
    project.orgNodes.forEach((orgNode) => {
      const matchedOrg = orgs.find((org) =>
        org.nodes.some((node: OrgNode) => node.id === orgNode?.id)
      );

      if (matchedOrg) {
        const orgIndex = selectedOrgsData.findIndex(
          (orgData) => orgData.orgName === matchedOrg.name
        );

        if (orgIndex !== -1 && orgNode?.name) {
          const topLevelNodeName = nodeToTopLevelMapIDs[orgNode.id].name;

          // Ensure that only one entry exists with the same selectedOrgTopLevelNodeName and projectID
          const isRepeated = selectedOrgsData[orgIndex].selectedNodeDetails.some(
            (detail) =>
              detail.selectedOrgTopLevelNodeName === topLevelNodeName &&
              detail.projectID === project.id
          );

          if (!isRepeated) {
            selectedOrgsData[orgIndex].selectedNodeDetails.push({
              selectedOrgName: orgNode?.name,
              selectedOrgTopLevelNodeName: topLevelNodeName,
              projectID: project.id,
              runningTotal: project.runningTotal || 0,
            });
          }
        }
      }
    });
  });

  // Create data for the donut chart
  const pieChartDataOrgs: {
    name: string;
    share: number;
  }[][] = [];

  selectedOrgsData.forEach((orgData) => {
    const topLevelNodeCount: Record<string, number> = {};

    orgData.selectedNodeDetails.forEach((detail) => {
      const topLevelNodeName = detail.selectedOrgTopLevelNodeName;
      const incrementValue =
        selectedDisplayBy === HeaderDisplayBy.COUNT ? 1 : Number(detail.runningTotal);

      topLevelNodeCount[topLevelNodeName] =
        (topLevelNodeCount[topLevelNodeName] || 0) + incrementValue;
    });

    const pieChartDataForOrg = Object.entries(topLevelNodeCount)
      .map(([name, share]) => ({
        name,
        share,
      }))
      .sort((a, b) => b.share - a.share); // Sort by share value

    // Confirm adding only data for orgs that have entries in the current projects list
    if (orgData.selectedNodeDetails.length > 0) pieChartDataOrgs.push(pieChartDataForOrg);
  });

  // Get the first 3 entries of selectedOrgsData where selectedNodeDetails have entries
  const filteredSelectedOrgsData = selectedOrgsData
    .filter((orgData) => orgData.selectedNodeDetails.length > 0)
    .slice(0, 3);

  // Process for entries greater than 19 and create the "Other" entry for the remaining
  const pieChartTopOrgs: {
    name: string;
    share: number;
  }[][] = [];
  const pieChartOtherOrgs: {
    name: string;
    share: number;
  }[][] = [];

  pieChartDataOrgs.forEach((orgData, i) => {
    let topEntries = orgData;
    let otherEntries: { name: string; share: number }[] = [];

    // If more than 19 entries, combine the rest into "Other"
    if (orgData.length > 19) {
      topEntries = orgData.slice(0, 19);
      otherEntries = orgData.slice(19);

      const otherShare = otherEntries.reduce((acc, { share }) => acc + share, 0);

      topEntries.push({ name: `Other ${filteredSelectedOrgsData[i].orgName}`, share: otherShare });
    }

    pieChartTopOrgs.push(topEntries);
    pieChartOtherOrgs.push(otherEntries);
  });

  return { pieChartTopOrgs, selectedOrgsData: filteredSelectedOrgsData, pieChartOtherOrgs };
};
