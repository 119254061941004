/* eslint-disable no-param-reassign */
import { MockedResponse } from 'apollo-link-mock';
import _ from 'lodash';

import { ApolloQueryResult, NetworkStatus } from '@apollo/client';

import { NULL_ID } from '../../../constants';
import { FeatureFlagQuery, TimelineQuery } from '../../../generated/graphql';
import { featureFlagQuery } from '../../contexts/queries';
import { InsightsProject } from '../hooks/useInsightsProjectsQuery';

export const timelineMocks: TimelineQuery = {
  timeline: {
    __typename: 'Timeline',
    activities: [
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: 'f93003e3-d95e-4d9b-adc5-05e7f9e8900a',
        itemCount: 11,
        milestone: {
          __typename: 'Milestone',
          description: '',
          id: 'f93003e3-d95e-4d9b-adc5-05e7f9e8900a',
          itemsCount: 11,
          name: 'Pursuit',
        },
        name: 'Pursuit',
        startDate: '2023-07-29T04:00:00Z',
        type: 2,
      },
      {
        __typename: 'TimelineActivity',
        children: [
          'fd2da030-064e-4913-a9ee-c840cbfcab4c',
          '16814351-c09a-4041-a720-f61cbbf8982d',
          '19fba484-8f26-4c3a-92bb-9bb445742e11',
          'd8040076-0099-4911-b871-e15a12627f39',
          'aecb0408-3660-4287-994b-ebb05a659c8e',
        ],
        endDate: '2023-09-20T05:00:00Z',
        id: '5db9d42a-2e9f-4a59-9507-7bbc3045600e',
        itemCount: 0,
        milestone: null,
        name: 'Pursuit Set',
        startDate: '2023-07-30T04:00:00Z',
        type: 1,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: 'fd2da030-064e-4913-a9ee-c840cbfcab4c',
        itemCount: 0,
        milestone: null,
        name: 'Pursuit Set Issued',
        startDate: '2023-08-01T04:00:00Z',
        type: 0,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: '16814351-c09a-4041-a720-f61cbbf8982d',
        itemCount: 1,
        milestone: null,
        name: 'OAC #1',
        startDate: '2023-08-04T05:00:00Z',
        type: 0,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: '19fba484-8f26-4c3a-92bb-9bb445742e11',
        itemCount: 3,
        milestone: null,
        name: 'OAC #2',
        startDate: '2023-08-26T05:00:00Z',
        type: 0,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: 'd8040076-0099-4911-b871-e15a12627f39',
        itemCount: 0,
        milestone: null,
        name: 'Pursuit Budget Sent',
        startDate: '2023-08-29T05:00:00Z',
        type: 0,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: '2023-09-19T05:00:00Z',
        id: 'aecb0408-3660-4287-994b-ebb05a659c8e',
        itemCount: 0,
        milestone: null,
        name: 'Decisions for Pursuit Set',
        startDate: '2023-08-30T05:00:00Z',
        type: 1,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: '7c49b80d-a309-417a-833f-bf810da3bdea',
        itemCount: 3,
        milestone: {
          __typename: 'Milestone',
          description: '',
          id: '7c49b80d-a309-417a-833f-bf810da3bdea',
          itemsCount: 3,
          name: '50% SD',
        },
        name: '50% SD',
        startDate: '2023-09-21T05:00:00Z',
        type: 2,
      },
      {
        __typename: 'TimelineActivity',
        children: [
          '38bf86c4-0aae-4a64-ac46-83e1e5516140',
          'cabfa929-8ea8-4b12-a22c-0578ae047417',
          '94e8be2f-9efe-4594-8e6e-ba364828eba3',
          'b4e4695a-88b8-4ba6-98c5-3dccba27aead',
          'd40c4b27-d385-4784-9956-2aade2c35c6c',
          'e98100c1-1497-44cb-8dbc-4a6f51c84f1b',
        ],
        endDate: '2024-01-07T04:00:00Z',
        id: '9db9bb24-3a37-45be-82c6-7b2a8f9466fd',
        itemCount: 0,
        milestone: null,
        name: '50% Schematic Design',
        startDate: '2023-09-22T05:00:00Z',
        type: 1,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: '38bf86c4-0aae-4a64-ac46-83e1e5516140',
        itemCount: 14,
        milestone: null,
        name: 'OAC #3',
        startDate: '2023-09-29T05:00:00Z',
        type: 0,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: 'cabfa929-8ea8-4b12-a22c-0578ae047417',
        itemCount: 0,
        milestone: null,
        name: '50% SD Set Issued',
        startDate: '2023-10-02T05:00:00Z',
        type: 0,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: '94e8be2f-9efe-4594-8e6e-ba364828eba3',
        itemCount: 8,
        milestone: null,
        name: 'OAC #4',
        startDate: '2023-11-05T05:00:00Z',
        type: 0,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: 'b4e4695a-88b8-4ba6-98c5-3dccba27aead',
        itemCount: 0,
        milestone: null,
        name: '50% SD Budget Sent',
        startDate: '2023-11-12T05:00:00Z',
        type: 0,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: '2023-12-26T04:00:00Z',
        id: 'd40c4b27-d385-4784-9956-2aade2c35c6c',
        itemCount: 0,
        milestone: null,
        name: 'Decisions for 50% SD',
        startDate: '2023-11-13T05:00:00Z',
        type: 1,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: 'e98100c1-1497-44cb-8dbc-4a6f51c84f1b',
        itemCount: 6,
        milestone: null,
        name: 'OAC #5',
        startDate: '2023-12-10T04:00:00Z',
        type: 0,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: '2024-02-12T04:00:00Z',
        id: 'afc58e91-e130-45a9-b382-31d49f977c42',
        itemCount: 0,
        milestone: null,
        name: 'ON HOLD - Funding Issues',
        startDate: '2023-12-28T04:00:00Z',
        type: 1,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: 'fd8b5c7d-4327-4eca-91cb-7ea16c181275',
        itemCount: 9,
        milestone: {
          __typename: 'Milestone',
          description: '',
          id: 'fd8b5c7d-4327-4eca-91cb-7ea16c181275',
          itemsCount: 9,
          name: '100% SD',
        },
        name: '100% SD',
        startDate: '2024-02-13T04:00:00Z',
        type: 2,
      },
      {
        __typename: 'TimelineActivity',
        children: [
          'ce4ba2a3-1611-451b-ae4a-ab3d59d2b539',
          '576f5139-dbb0-4208-9c34-56fa09dec87d',
          'b38ff684-1bdb-4a62-aeda-b8090cef0492',
          '43347541-5854-4cdf-9710-44ff7fe9ec50',
          '516d40d3-394a-4a4c-a133-abfc418c7046',
          '84a77cbc-f8d2-41f4-b704-927efb2c9579',
        ],
        endDate: '2024-05-02T04:00:00Z',
        id: 'ae544cba-4a2a-450b-a913-271d00dee6c0',
        itemCount: 0,
        milestone: null,
        name: '100% Schematic Design',
        startDate: '2024-02-13T04:00:00Z',
        type: 1,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: 'ce4ba2a3-1611-451b-ae4a-ab3d59d2b539',
        itemCount: 1,
        milestone: null,
        name: 'OAC #6',
        startDate: '2024-02-14T04:00:00Z',
        type: 0,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: '576f5139-dbb0-4208-9c34-56fa09dec87d',
        itemCount: 0,
        milestone: null,
        name: '100% SD Set Issued',
        startDate: '2024-02-29T04:00:00Z',
        type: 0,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: 'b38ff684-1bdb-4a62-aeda-b8090cef0492',
        itemCount: 0,
        milestone: null,
        name: '100% SD Budget Sent',
        startDate: '2024-03-15T04:00:00Z',
        type: 0,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: '2024-05-01T04:00:00Z',
        id: '43347541-5854-4cdf-9710-44ff7fe9ec50',
        itemCount: 0,
        milestone: null,
        name: 'Decisions for 100% SD',
        startDate: '2024-03-16T04:00:00Z',
        type: 1,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: '516d40d3-394a-4a4c-a133-abfc418c7046',
        itemCount: 2,
        milestone: null,
        name: 'OAC #7',
        startDate: '2024-03-24T04:00:00Z',
        type: 0,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: '84a77cbc-f8d2-41f4-b704-927efb2c9579',
        itemCount: 1,
        milestone: null,
        name: 'OAC #8',
        startDate: '2024-04-16T04:00:00Z',
        type: 0,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: '7270f8a2-3768-4632-9787-a2bfcd90f534',
        itemCount: 40,
        milestone: {
          __typename: 'Milestone',
          description: '75% CD Set is due on 06/18/2021',
          id: '7270f8a2-3768-4632-9787-a2bfcd90f534',
          itemsCount: 38,
          name: '100% DD',
        },
        name: '100% DD',
        startDate: '2024-05-03T04:00:00Z',
        type: 3,
      },
      {
        __typename: 'TimelineActivity',
        children: [
          '0b0ded15-b58b-457d-b08d-70be241537d6',
          '97bce62f-f430-41dd-9403-6764cd8745c4',
          'd2c48ad1-3acf-4483-bf51-242d7d7a7f76',
          '1f4a36f4-5b66-4535-a016-31c98145ba2c',
        ],
        endDate: '2024-07-16T04:00:00Z',
        id: '490273d0-1de0-4c35-b358-7528a8070bc6',
        itemCount: 0,
        milestone: null,
        name: '100% Design Development',
        startDate: '2024-05-04T04:00:00Z',
        type: 1,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: '0b0ded15-b58b-457d-b08d-70be241537d6',
        itemCount: 0,
        milestone: null,
        name: '100% DD Set Issued',
        startDate: '2024-05-12T04:00:00Z',
        type: 0,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: '97bce62f-f430-41dd-9403-6764cd8745c4',
        itemCount: 2,
        milestone: null,
        name: 'OAC #9',
        startDate: '2024-05-15T04:00:00Z',
        type: 0,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: 'd2c48ad1-3acf-4483-bf51-242d7d7a7f76',
        itemCount: 0,
        milestone: null,
        name: '100% DD Estimate Sent',
        startDate: '2024-05-31T04:00:00Z',
        type: 0,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: '2024-07-16T04:00:00Z',
        id: '1f4a36f4-5b66-4535-a016-31c98145ba2c',
        itemCount: 0,
        milestone: null,
        name: 'Decisions for Design Development',
        startDate: '2024-06-01T04:00:00Z',
        type: 1,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: '604fe4a5-a0f0-43a2-96a6-320f8f75de11',
        itemCount: 0,
        milestone: null,
        name: '95% CD SET DUE FROM DESIGN TEAM',
        startDate: '2024-07-28T04:00:00Z',
        type: 0,
      },
      {
        __typename: 'TimelineActivity',
        children: [
          '604fe4a5-a0f0-43a2-96a6-320f8f75de11',
          '6fcb1742-a7d9-4d9c-87f2-9190253b9019',
          '8623eb03-6241-40bb-91dd-000e8c61f781',
        ],
        endDate: '2024-09-30T05:00:00Z',
        id: '000fdec8-f7cc-46b9-bacb-498673d87434',
        itemCount: 0,
        milestone: null,
        name: '95% Construction Drawings',
        startDate: '2024-07-28T04:00:00Z',
        type: 1,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: '2024-09-30T05:00:00Z',
        id: '6fcb1742-a7d9-4d9c-87f2-9190253b9019',
        itemCount: 0,
        milestone: null,
        name: 'HOLD FOR FINAL DESIGN DECISIONS',
        startDate: '2024-08-31T05:00:00Z',
        type: 1,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: '8623eb03-6241-40bb-91dd-000e8c61f781',
        itemCount: 0,
        milestone: null,
        name: '95% CD ESTIMATE DUE TO OWNER',
        startDate: '2024-09-01T05:00:00Z',
        type: 0,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: 'fd2b92e3-3b94-40e5-9946-6a67eabb3209',
        itemCount: 0,
        milestone: {
          __typename: 'Milestone',
          description: '',
          id: 'fd2b92e3-3b94-40e5-9946-6a67eabb3209',
          itemsCount: 0,
          name: 'Design Build Milestone',
        },
        name: 'Design Build Milestone',
        startDate: '2024-09-25T18:27:00Z',
        type: 2,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: '2024-10-18T05:00:00Z',
        id: '47de5a27-e4e7-4df1-a0fd-22f0050a8c64',
        itemCount: 0,
        milestone: null,
        name: 'HOLD FOR FINAL CONTRACT NEGOTIATIONS',
        startDate: '2024-10-01T05:00:00Z',
        type: 1,
      },
      {
        __typename: 'TimelineActivity',
        children: [],
        endDate: null,
        id: '68119d93-405c-40bd-8a87-6e28301e2314',
        itemCount: 0,
        milestone: null,
        name: 'GMP ESTABLISHED / CONTRACT SIGNING',
        startDate: '2024-10-19T05:00:00Z',
        type: 0,
      },
    ],
  },
} as TimelineQuery;

const projectRaw: InsightsProject = {
  __typename: 'InsightsProject',
  budget: 56400000000,
  contingenciesTimeseries: [
    {
      date: '2024-08-29T12:00:00.000Z',
      allowanceRemaining: 4400000,
      allowancePending: 1600000,
      allowanceTotal: 4400000,
      allowanceDraw: 2000000,
      allowanceNumDraws: 2000000,
      contingencyRemaining: 13000000,
      contingencyPending: 2000000,
      contingencyTotal: 13000000,
      contingencyDraw: 10000000,
      contingencyNumDraws: 10000000,
    },
    {
      date: '2024-09-05T12:00:00.000Z',
      allowanceRemaining: 3600000,
      allowancePending: 0,
      allowanceTotal: 3600000,
      allowanceDraw: 2000000,
      allowanceNumDraws: 2000000,
      contingencyRemaining: 12000000,
      contingencyPending: 0,
      contingencyTotal: 12000000,
      contingencyDraw: 10000000,
      contingencyNumDraws: 10000000,
    },
    {
      date: '2024-09-12T12:00:00.000Z',
      allowanceRemaining: 2800000,
      allowancePending: -1200000,
      allowanceTotal: 2800000,
      allowanceDraw: 2000000,
      allowanceNumDraws: 2000000,
      contingencyRemaining: 11000000,
      contingencyPending: -1500000,
      contingencyTotal: 11000000,
      contingencyDraw: 10000000,
      contingencyNumDraws: 10000000,
    },
    {
      date: '2024-09-19T12:00:00.000Z',
      allowanceRemaining: 3200000,
      allowancePending: 1200000,
      allowanceTotal: 3200000,
      allowanceDraw: 2000000,
      allowanceNumDraws: 2000000,
      contingencyRemaining: 11500000,
      contingencyPending: 1500000,
      contingencyTotal: 11500000,
      contingencyDraw: 10000000,
      contingencyNumDraws: 10000000,
    },
    {
      date: '2024-09-26T12:00:00.000Z',
      allowanceRemaining: 2800000,
      allowancePending: 0,
      allowanceTotal: 2800000,
      allowanceDraw: 2000000,
      allowanceNumDraws: 2000000,
      contingencyRemaining: 11000000,
      contingencyPending: 0,
      contingencyTotal: 11000000,
      contingencyDraw: 10000000,
      contingencyNumDraws: 10000000,
    },
  ],
  costTimeseries: [
    {
      date: '2024-09-05T12:00:00.000Z',
      budget: 54650000000,
      estimate: 56750000000,
      runningTotal: 56400000000,
      pendingMax: 55700000000,
      pendingMin: 55560000000,
    },
    {
      date: '2024-09-12T12:00:00.000Z',
      budget: 55000000000,
      estimate: 57100000000,
      runningTotal: 56050000000,
      pendingMax: 55910000000,
      pendingMin: 55350000000,
    },
    {
      date: '2024-09-19T12:00:00.000Z',
      budget: 54300000000,
      estimate: 56400000000,
      runningTotal: 56575000000,
      pendingMax: 55770000000,
      pendingMin: 55105000000,
    },
    {
      date: '2024-09-26T12:00:00.000Z',
      budget: 55300000000,
      estimate: 57400000000,
      runningTotal: 57575000000,
      pendingMax: 58770000000,
      pendingMin: 53105000000,
    },
  ],
  deliveryType: 'CM at Risk',
  demoData: null,
  dueNext14DaysItems: 10,
  estimate: 58400000000,
  hasAccess: true,
  id: '9dcc799f-160c-45f5-ae49-635f71855bf3',
  lastUpdated: '2024-08-16T23:33:22.374822Z',
  lastUpdatedBy: 'george.currie',
  location: 'Baltimore, MD, USA',
  milestoneDesignPhase: 'Schematic Design',
  milestoneGSF: '510485',
  milestones: [
    {
      __typename: 'InsightsMilestone',
      active: true,
      date: '2024-07-20T14:03:00.000Z',
      designPhase: 'Schematic Design',
      id: '16cd58ed-06de-4529-8f57-9fda792c362a',
      name: '100% SD Est Reduced Floor Plan_Vermeulen Reconciled',
    },
  ],
  name: 'Johns Hopkins University Life Sciences Building',
  orgNodes: [{ __typename: 'OrgNode', id: '00000000-0000-0000-0000-000000000000', name: '' }],
  pastDueItems: 25,
  pendingItemsDueInFuture: 15,
  pendingItemsMissingDueDates: 20,
  pendingItemsPastDue: 10,
  projectLeadName: null,
  runningTotal: 57400000000,
  status: 'Active',
  thumbnail: '1218b449-4a82-48da-8edb-72ee17c29cd2',
  type: 'Biotech/Life Sciences',
  demoDataReferenceDate: '2024-09-25T12:00:00.000Z',
  itemsDueDateSeriesMockData: [
    {
      date: '2022-06-29T12:00:00.000Z',
      dueDates: { past: 0, upcoming: 0, decided: 1, without: 0 },
    },
    {
      date: '2023-01-29T12:00:00.000Z',
      dueDates: { past: 1, upcoming: 0, decided: 2, without: 1 },
    },
    {
      date: '2023-06-29T12:00:00.000Z',
      dueDates: { past: 2, upcoming: 0, decided: 3, without: 1 },
    },
    {
      date: '2024-01-29T12:00:00.000Z',
      dueDates: { past: 1, upcoming: 0, decided: 4, without: 2 },
    },
    {
      date: '2024-09-26T12:00:00.000Z',
      dueDates: { past: 0, upcoming: 0, decided: 5, without: 1 },
    },
    {
      date: '2024-11-29T12:00:00.000Z',
      dueDates: { past: 0, upcoming: 3, decided: 1, without: 0 },
    },
  ],
  default: {
    demoDataReferenceDate: '2024-09-25T12:00:00.000Z',
    runningTotal: 57400000000,
    budget: 56400000000,
    estimate: 58400000000,
    pendingItemsPastDue: 10,
    pendingItemsDueInFuture: 15,
    pendingItemsMissingDueDates: 20,
    pastDueItems: 25,
    dueNext14DaysItems: 10,
    costTimeseries: [
      {
        date: '2024-09-04T12:00:00.000Z',
        budget: 54650000000,
        estimate: 56750000000,
        runningTotal: 56400000000,
        pendingMax: 55700000000,
        pendingMin: 55560000000,
      },
      {
        date: '2024-09-11T12:00:00.000Z',
        budget: 55000000000,
        estimate: 57100000000,
        runningTotal: 56050000000,
        pendingMax: 55910000000,
        pendingMin: 55350000000,
      },
      {
        date: '2024-09-18T12:00:00.000Z',
        budget: 54300000000,
        estimate: 56400000000,
        runningTotal: 56575000000,
        pendingMax: 55770000000,
        pendingMin: 55105000000,
      },
      {
        date: '2024-09-25T12:00:00.000Z',
        budget: 55300000000,
        estimate: 57400000000,
        runningTotal: 57575000000,
        pendingMax: 58770000000,
        pendingMin: 53105000000,
      },
    ],
    contingenciesTimeseries: [
      {
        date: '2024-08-28T12:00:00.000Z',
        allowanceRemaining: 4400000,
        allowancePending: 1600000,
        allowanceTotal: 4400000,
        allowanceDraw: 2000000,
        allowanceNumDraws: 2000000,
        contingencyRemaining: 13000000,
        contingencyPending: 2000000,
        contingencyTotal: 13000000,
        contingencyDraw: 10000000,
        contingencyNumDraws: 10000000,
      },
      {
        date: '2024-09-04T12:00:00.000Z',
        allowanceRemaining: 3600000,
        allowancePending: 0,
        allowanceTotal: 3600000,
        allowanceDraw: 2000000,
        allowanceNumDraws: 2000000,
        contingencyRemaining: 12000000,
        contingencyPending: 0,
        contingencyTotal: 12000000,
        contingencyDraw: 10000000,
        contingencyNumDraws: 10000000,
      },
      {
        date: '2024-09-11T12:00:00.000Z',
        allowanceRemaining: 2800000,
        allowancePending: -1200000,
        allowanceTotal: 2800000,
        allowanceDraw: 2000000,
        allowanceNumDraws: 2000000,
        contingencyRemaining: 11000000,
        contingencyPending: -1500000,
        contingencyTotal: 11000000,
        contingencyDraw: 10000000,
        contingencyNumDraws: 10000000,
      },
      {
        date: '2024-09-18T12:00:00.000Z',
        allowanceRemaining: 3200000,
        allowancePending: 1200000,
        allowanceTotal: 3200000,
        allowanceDraw: 2000000,
        allowanceNumDraws: 2000000,
        contingencyRemaining: 11500000,
        contingencyPending: 1500000,
        contingencyTotal: 11500000,
        contingencyDraw: 10000000,
        contingencyNumDraws: 10000000,
      },
      {
        date: '2024-09-25T12:00:00.000Z',
        allowanceRemaining: 2800000,
        allowancePending: 0,
        allowanceTotal: 2800000,
        allowanceDraw: 2000000,
        allowanceNumDraws: 2000000,
        contingencyRemaining: 11000000,
        contingencyPending: 0,
        contingencyTotal: 11000000,
        contingencyDraw: 10000000,
        contingencyNumDraws: 10000000,
      },
    ],
    itemsDueDateSeriesMockData: [
      {
        date: '2022-06-28T12:00:00.000Z',
        dueDates: { past: 0, upcoming: 0, decided: 1, without: 0 },
      },
      {
        date: '2023-01-28T12:00:00.000Z',
        dueDates: { past: 1, upcoming: 0, decided: 2, without: 1 },
      },
      {
        date: '2023-06-28T12:00:00.000Z',
        dueDates: { past: 2, upcoming: 0, decided: 3, without: 1 },
      },
      {
        date: '2024-01-28T12:00:00.000Z',
        dueDates: { past: 1, upcoming: 0, decided: 4, without: 2 },
      },
      {
        date: '2024-09-25T12:00:00.000Z',
        dueDates: { past: 0, upcoming: 0, decided: 5, without: 1 },
      },
      {
        date: '2024-11-28T12:00:00.000Z',
        dueDates: { past: 0, upcoming: 3, decided: 1, without: 0 },
      },
    ],
  },
} as unknown as InsightsProject;

export const project: InsightsProject = updateDates(projectRaw);

// Function to recursively update all fields with the exact key 'date'
function updateDates(obj: InsightsProject) {
  return _.cloneDeep(
    _.forOwn(obj, (value, key, object) => {
      if (_.isObject(value)) {
        // Recursively process nested objects
        // @ts-ignore TODO: nothing
        object[key] = updateDates(value);
      } else if (key === 'date') {
        // @ts-ignore TODO: nothing
        object[key] = new Date(value);
      }
    })
  );
}

const resultFF: ApolloQueryResult<FeatureFlagQuery> = {
  data: {
    loadFeatureFlags: ['YC_INSIGHTS_V2', 'YC_DEMO_MOCKS_INSIGHTS_V2'],
  },
  loading: false,
  networkStatus: NetworkStatus.ready,
};

export const FFMocks = {
  ...resultFF,
  startPolling: (): void => undefined,
  stopPolling: (): void => undefined,
  subscribeToMore: () => () => {},
  updateQuery: () => undefined,
  refetch: () => Promise.resolve(resultFF),
  variables: undefined,
  fetchMore: () => Promise.resolve(resultFF),
};

export const FeatureMocks: MockedResponse = {
  request: {
    query: featureFlagQuery,
    variables: {
      projectID: NULL_ID,
      userEmail: NULL_ID,
    },
  },
  result: FFMocks,
};
