import { useLocation } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { getTodayUtcNoon } from '../../../utilities/dates';
import TimelineLegend from '../../Timeline/TimelineLegend/TimelineLegend';
import ContingencyLegend from '../Charts/Legends/ContingencyLegend';
import CostLegend from '../Charts/Legends/CostLegend';
import ItemsLegend from '../Charts/Legends/ItemsLegend';
import ProjectTimelineChart from '../Charts/ProjectTimelineChart';
import { InsightsProject as InsightsProjectType } from '../hooks/useInsightsProjectsQuery';
import ProjectContingenciesAndAllowances from '../ProjectBabyCharts/ProjectContingenciesAndAllowances';
import ProjectCost from '../ProjectBabyCharts/ProjectCost';
import ProjectItems from '../ProjectBabyCharts/ProjectItems';
import { getProjectsRange } from '../ProjectsTables/utils';
import { InsightsTabId } from '../types';

import Section from './Section';

type Props = { project: InsightsProjectType };

export default function InsightsProject({ project }: Props) {
  const [startDate, endDate] = getProjectsRange(getTodayUtcNoon());
  const { hash } = useLocation();
  const onUpdateHash = (hash: string) => {
    const el = document.querySelector(hash);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  useDebounce(() => onUpdateHash(hash), 100, [hash]);

  if (!project) return null;

  return (
    <div className="w-full bg-background-primary">
      <Section
        id={`${InsightsTabId.Timeline}`}
        legend={<TimelineLegend noPhase />}
        title="Timeline"
      >
        <ProjectTimelineChart
          endStr={endDate.toISOString()}
          project={project}
          startStr={startDate.toISOString()}
        />
      </Section>
      <Section id={`${InsightsTabId.Costs}`} legend={<CostLegend />} title="Cost Trends">
        <ProjectCost
          endStr={endDate.toISOString()}
          isProjectDetails
          project={project}
          startStr={startDate.toISOString()}
        />
      </Section>
      <Section id={`${InsightsTabId.Items}`} title="Items">
        <Section
          id={`${InsightsTabId.Items}`}
          legend={<ItemsLegend />}
          title={<div className="type-heading3">By decision/due date</div>}
        >
          <ProjectItems
            endStr={endDate.toISOString()}
            isProjectDetails
            project={project}
            startStr={startDate.toISOString()}
          />
        </Section>
      </Section>
      <Section
        id={`${InsightsTabId.Contingencies}`}
        legend={<ContingencyLegend />}
        title="Contingencies & Allowances"
      >
        <ProjectContingenciesAndAllowances
          endStr={endDate.toISOString()}
          isProjectDetails
          project={project}
          startStr={startDate.toISOString()}
        />
      </Section>
    </div>
  );
}
