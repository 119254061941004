import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';
import { ReactNode } from 'react';

const PADDING = 5;

type Data = { date: Date; value: number };

//* * Container that is used to display content like text on a timeline chart */
export default function TimelineContainer(props: {
  bounds?: { left: number; right: number };
  content: ReactNode;
  data: Data;
  disabled?: boolean;
  height: number;
  placement?: 'left' | 'right';
  width: number;
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
}) {
  if (props.disabled) return null;

  let positionX = props.x(props.data.date) ?? 0;
  const positionY = props.y(props.data.value) ?? 0;

  const { bounds, placement } = props;

  if (placement) {
    if (placement === 'right') {
      positionX += props.width / 2 - PADDING;
    } else if (props.placement === 'left') {
      positionX -= props.width / 2 - 2 * PADDING;
    }
  }

  if (bounds && positionX + props.width / 2 > bounds.right) {
    positionX = bounds.right - props.width / 2;
  } else if (bounds && positionX - props.width / 2 < bounds.left) {
    positionX = bounds.left + props.width / 2;
  }

  const transform = `translate(${positionX}, ${positionY})`;

  return (
    <g transform={transform}>
      <g transform={`translate(${-props.width / 2}, ${props.height / 2})`}>
        <rect
          height={props.height}
          rx={4}
          style={{
            fill: 'var(--colors-type-secondary)',
            strokeWidth: 1,
            stroke: 'var(--colors-border)',
          }}
          width={props.width}
          x="0"
        />
      </g>
      <g transform={`translate(${-props.width / 2 + 8}, ${props.height + 6})`}>{props.content}</g>
    </g>
  );
}
