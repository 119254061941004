// import { useParams } from 'react-router-dom';

import ProgramDashboard from './ProgramDashboard';

export default function ProgramDashboardRoute() {
  // const { programID } = useParams();

  // if (!programID) {
  //   throw new Error('Failed to get programID param');
  // }

  return <ProgramDashboard />;
}
