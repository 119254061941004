import { NavigateFunction } from 'react-router-dom';

import {
  AnalyticsEvent,
  LeadEvent,
  LeadKey,
  leadEvent,
} from '../../../../../analytics/analyticsEventProperties';
import { UNASSIGNED } from '../../../../../constants';
import { CreateProjectMutationVariables, Org } from '../../../../../generated/graphql';
import { useCreateCollaborators } from '../../../../Collaborators/hooks';
import { useSetProjectLead } from '../../../../Collaborators/hooks/projectLead';
import OrgSelector from '../../../../CompanyTab/CompanyTabOrganizations/OrgSelector/OrgSelector';
import { Lightbulb } from '../../../../Icons/Lightbulb';
import { useSetProjectOrgs } from '../../../../Organizations/hooks/hooks';
import {
  Button,
  Callout,
  DialogContent,
  DialogFlowStep,
  Select,
  SelectEntry,
} from '../../../../scales';
import FooterRightLoadingProject from '../../FooterRightLoadingProject';

type Props = {
  activeUsers: CompanyUser[];
  hasKeyDatesFeature: boolean;
  createCollaborators: ReturnType<typeof useCreateCollaborators>[0];
  isGroupsFeature: boolean;
  navigate: NavigateFunction;
  orgs: Org[];
  project: CreateProjectMutationVariables;
  projectID: UUID | null;
  projectLeadID: UUID | undefined;
  projectLeadEntries: SelectEntry[];
  publishedOrgs: Org[] | undefined;
  sendAnalytics: (event: AnalyticsEvent) => void;
  setProject: React.Dispatch<React.SetStateAction<CreateProjectMutationVariables>>;
  setProjectLeadID: React.Dispatch<React.SetStateAction<UUID | undefined>>;
  setProjectLead: ReturnType<typeof useSetProjectLead>[0];
  setProjectOrgs: ReturnType<typeof useSetProjectOrgs>[0];
  submitted: boolean;
  templateProject: ProjectTemplateProject | undefined;
};

export default function getLeadStep(props: Props): DialogFlowStep {
  const {
    activeUsers,
    createCollaborators,
    hasKeyDatesFeature,
    isGroupsFeature,
    navigate,
    orgs,
    project,
    projectID,
    projectLeadEntries,
    projectLeadID,
    publishedOrgs,
    sendAnalytics,
    setProject,
    setProjectLead,
    setProjectLeadID,
    setProjectOrgs,
    submitted,
    templateProject,
  } = props;

  return {
    renderFooterLeft: hasKeyDatesFeature ? undefined : () => null, // if feature, default back button, else no back button
    renderContent: () => (
      <DialogContent>
        <div className="flex h-100 flex-col justify-between">
          <div className="flex flex-col gap-2">
            {submitted ? (
              <div className="flex justify-center p-4">
                <img
                  alt="BuildingNewProject First Stage"
                  src="/img/NewProject/BuildingNewProject_FirstStage.gif"
                  width={360}
                />
              </div>
            ) : (
              <div className="flex justify-center p-4">
                <img
                  alt="BuildingNewProject Completed pStage"
                  src="/img/NewProject/BuildingNewProject_CompleteStage.gif"
                  width={360}
                />
              </div>
            )}
            <div className="mb-1">
              <Select
                entries={projectLeadEntries}
                isSearchable
                label={`${activeUsers[0]?.company?.name} Project Lead`}
                onChange={(userID) => {
                  setProjectLeadID(userID);
                  sendAnalytics(
                    leadEvent(LeadKey.NEW_PROJECT, LeadEvent.OPTIONAL_LEADS, { userID })
                  );
                }}
                value={projectLeadID}
              />
            </div>
            {isGroupsFeature && publishedOrgs && publishedOrgs.length > 0 && (
              <div className="mb-2">
                <OrgSelector
                  isHiddenForNoOrgs
                  label="Organizations"
                  onChange={(orgNodeIDs) => {
                    setProject({ ...project, orgNodeIDs });
                    sendAnalytics(
                      leadEvent(LeadKey.NEW_PROJECT, LeadEvent.OPTIONAL_ORGS, { orgNodeIDs })
                    );
                  }}
                  orgNodes={templateProject?.orgNodes}
                  orgs={orgs}
                />
              </div>
            )}
          </div>
          <div className="flex pb-2 pt-4">
            <Callout fullWidth>
              <div className="flex items-center gap-1">
                <Lightbulb />
                <div className="inline type-body3">
                  Tip: Help your colleagues find this project more easily. This is optional and you
                  can always do it later.
                </div>
              </div>
            </Callout>
          </div>
        </div>
      </DialogContent>
    ),
    renderFooterRight: () => {
      return props.submitted ? (
        <FooterRightLoadingProject projectName={project.name} />
      ) : (
        <Button
          disabled={submitted}
          label="Finish"
          onClick={async () => {
            if (projectID !== null) {
              const projectLeadEmail = activeUsers.find((user) => user.user?.id === projectLeadID)
                ?.user?.email;
              if (projectLeadEmail) {
                await createCollaborators(projectID, [
                  {
                    userEmail: projectLeadEmail,
                    allTrades: true,
                    roleName: 'Administrator',
                    responsibility: '',
                    trades: [],
                  },
                ]);
              }
              if (projectLeadID && projectLeadID !== UNASSIGNED) {
                await setProjectLead({
                  variables: {
                    projectID,
                    userID: projectLeadID,
                  },
                });
                sendAnalytics(
                  leadEvent(LeadKey.NEW_PROJECT, LeadEvent.SET, { userID: projectLeadID })
                );
              }
              if (project.orgNodeIDs) {
                await setProjectOrgs({
                  variables: {
                    input: {
                      projectID,
                      nodeIDs: project.orgNodeIDs,
                    },
                  },
                });
              }
              sendAnalytics(leadEvent(LeadKey.NEW_PROJECT, LeadEvent.FINISH));
              navigate(`/${projectID}/settings/`);
            }
            navigate(`/${projectID}/settings/`);
          }}
          type="primary"
        />
      );
    },
  };
}
