import { FC, useState } from 'react';

import { INTEGRATIONS } from '../../../constants';
import { DD_AUTODESK_PCO_INTEGRATION } from '../../../features';
import { SourceSystem } from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useHasFeature';
import useItemIntegrationObjectsCountQuery from '../../../hooks/useItemIntegrationObjectsCountQuery';
import { constantCountLabel } from '../../../utilities/string';
import { useUserSourcesQuery } from '../../DocumentMagic/hooks';

import IntegrationsDataWrapper from './Integrations/IntegrationsDataWrapper';
import IntegrationsDataWrapperNew from './Integrations/IntegrationsDataWrapperNew';
import IntegrationsHeader from './Integrations/IntegrationsHeader';
import { ItemInfo } from './Integrations/IntegrationsUtils';
import ItemDetailsCollapse from './ItemDetailsCollapse';

type IntegrationsCollapseProps = {
  canCreateChangeEvent: boolean;
  canDeleteItemIntegration: boolean;
  itemInfo: ItemInfo;
};

const IntegrationsCollapse: FC<IntegrationsCollapseProps> = ({
  canCreateChangeEvent,
  canDeleteItemIntegration,
  itemInfo,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const userSources = useUserSourcesQuery().data?.userSources;
  const isLoggedIntoProcore = !!userSources?.sources.some(
    (s) => s.sourceSystem === SourceSystem.PROCORE
  );

  const countData = useItemIntegrationObjectsCountQuery({ itemID: itemInfo.id });
  const count = countData?.data?.itemIntegrationObjectsCount ?? 0;

  const headerContent = (
    <IntegrationsHeader
      isLoggedIntoProcore={isLoggedIntoProcore}
      name={constantCountLabel(INTEGRATIONS, count)}
      userSources={userSources}
    />
  );

  const hasAutodeskPCOIntegrationFeature = useHasFeature(DD_AUTODESK_PCO_INTEGRATION);

  return (
    <ItemDetailsCollapse
      canExpand
      headerContent={headerContent}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    >
      {hasAutodeskPCOIntegrationFeature ? (
        userSources && (
          <IntegrationsDataWrapperNew
            canCreateChangeEvent={canCreateChangeEvent}
            canDeleteItemIntegration={canDeleteItemIntegration}
            itemInfo={itemInfo}
            userSources={userSources}
          />
        )
      ) : (
        <IntegrationsDataWrapper
          canCreateChangeEvent={canCreateChangeEvent}
          canDeleteItemIntegration={canDeleteItemIntegration}
          isItemSidebar={false}
          itemInfo={itemInfo}
          userSources={userSources}
        />
      )}
    </ItemDetailsCollapse>
  );
};

export default IntegrationsCollapse;
