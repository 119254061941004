import { TermKey } from '../../api/gqlEnums';

export const TerminologyDefaults = {
  [TermKey.ESTIMATE]: 'Estimate',
  [TermKey.TARGET]: 'Budget',
  [TermKey.DELTA]: 'Delta',
  [TermKey.RUNNING_TOTAL]: 'Running total',
  [TermKey.GAP]: 'Gap',
  [TermKey.DIRECT_COST]: 'Direct costs',
  [TermKey.MARKUP]: 'Markups',
};

export const TerminologyDescriptions = (t: TermStore) => (term: TermKey) => {
  const descriptions = {
    [TermKey.ESTIMATE]:
      'The original baseline estimate for the project in any given milestone. Items are considered as changes relative to this baseline estimate.',
    [TermKey.TARGET]: 'The target budget amount allocated for the project.',
    [TermKey.DELTA]: `The difference between the ${t.lowerCase(
      TermKey.TARGET
    )} and the ${t.lowerCase(TermKey.ESTIMATE)}: negative if the ${t.lowerCase(
      TermKey.ESTIMATE
    )} is over ${t.lowerCase(TermKey.TARGET)}, positive if under.`,
    [TermKey.RUNNING_TOTAL]: `The ${t.lowerCase(
      TermKey.ESTIMATE
    )} plus any accepted items: represents the real-time project costs.`,
    [TermKey.GAP]: `The difference between the ${t.lowerCase(TermKey.TARGET)} and the ${t.lowerCase(
      TermKey.RUNNING_TOTAL
    )}: represents the amount of change still needed to meet the ${t.lowerCase(TermKey.TARGET)}.`,
    [TermKey.DIRECT_COST]: `Cost of materials, labor, equipment, etc. Included in subtotals "without ${t.lowerCase(
      TermKey.MARKUP
    )}"`,
    [TermKey.MARKUP]: 'Indirect below-the-line costs.',
  };
  return descriptions[term];
};
