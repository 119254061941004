/* eslint-disable no-underscore-dangle */
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';

import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { getJoinHeaders } from '../headers';

import { handleErrors } from './errors';
import { typeDefs } from './extendTypeDefs';
import httpLink from './HttpLink';
import introspectionFragmentMatcher from './introspection-fragment-matcher.json';
import { projectCompsSetInputVar } from './reactiveVars';
import typePolicies from './typePolicies';
import { offsetLimitArrayPagination, offsetLimitObjectPagination } from './utilities';

const setHeaders = new ApolloLink((operation, forward) => {
  // add the recent-activity custom header to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...getJoinHeaders(window.location.pathname),
    },
  }));
  return forward(operation);
});

// we need to check localStorage for the token every request. See https://www.apollographql.com/docs/react/networking/authentication/#header

const authLink = (auth) =>
  setContext(async (_, { headers }) => {
    let accessToken;
    // If we are in an integration testing environment, we do this to skip auth0 and use a token from localStorage
    if (localStorage.getItem('skip_user_profile_fetch') === 'true') {
      accessToken = localStorage.getItem('access_token');
    } else {
      accessToken = await auth.getAccessTokenSilently();
      if (window.location.hostname === 'localhost') {
        localStorage.setItem('voyager_access_token', accessToken);
      }
    }

    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
      },
    };
  });

const apolloClient = (auth) =>
  new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: introspectionFragmentMatcher.possibleTypes,
      typeDefs, // provide our client-side schema
      typePolicies: {
        Query: {
          fields: {
            projectCompSettingsReactive: {
              read() {
                return projectCompsSetInputVar();
              },
            },
            projects: offsetLimitArrayPagination([
              'projectsFilterBy',
              'projectsSortBy',
              'projectsSearch',
              'includeCompanyProjects',
            ]),
            forecastingProjects: offsetLimitArrayPagination([
              'filters',
              'search',
              'sortBy',
              'unitID',
            ]),
            forecastingItems: offsetLimitObjectPagination([
              'filters',
              'projectsFilters',
              'search',
              'sortBy',
              'unitID',
            ]),
            searchProjects: offsetLimitObjectPagination([
              'filters',
              'searchResultType',
              'search',
              'sortBy',
              'unitID',
            ]),
            searchItems: offsetLimitObjectPagination([
              'filters',
              'projectsFilters',
              'searchResultType',
              'search',
              'sortBy',
              'unitID',
            ]),
            projectActivity: offsetLimitObjectPagination([
              'projectID',
              'filterByUserID',
              'costMode',
              'dateFilter',
            ]),
          },
        },
        // Type policies definitions:
        ...typePolicies,
      },
    }),
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
    link: ApolloLink.from([
      // IMPORTANT: This is Join's Error-handling
      onError(({ graphQLErrors, networkError }) => handleErrors(graphQLErrors, networkError, auth)),
      setHeaders,
      authLink(auth).concat(httpLink),
    ]),
  });

export default apolloClient;
