import { YC_DEMO_MOCKS_INSIGHTS_V2 } from '../../../features';
import {
  InsightsInput,
  InsightsProjectsQuery,
  InsightsProjectsQueryVariables,
} from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { getTodayUtcNoon } from '../../../utilities/dates';
import { ItemTimeSeries } from '../../dragon-scales/TimelineCharts/InsightsItems/types';
import { fixMocksToday } from '../utils';

import { parseDemoData } from './demoData';
import { insightsProjectsQuery } from './queries';

export type InsightsProject = InsightsProjects & {
  itemsDueDateSeriesMockData?: ItemTimeSeries[];
};

export const useInsightsProjectsQuery = (input: InsightsInput) => {
  const useDemoData = useHasFeature(YC_DEMO_MOCKS_INSIGHTS_V2);
  const insightsProjectsData = useQuery<InsightsProjectsQuery, InsightsProjectsQueryVariables>(
    insightsProjectsQuery,
    {
      variables: { input },
      skip: !input.companyID,
    }
  )?.data?.insights;

  if (!useDemoData) {
    return insightsProjectsData;
  }
  return insightsProjectsData?.map((project) => {
    const parsed = parseDemoData(project.demoData || '');
    const parsedProject: InsightsProject = {
      ...project,
      ...parsed,
    };

    const referenceDate = parsed.demoDataReferenceDate;
    const delta = getTodayUtcNoon().getTime() - referenceDate.getTime();
    parsedProject.costTimeseries = fixMocksToday(parsedProject.costTimeseries, delta);
    parsedProject.contingenciesTimeseries = fixMocksToday(
      parsedProject.contingenciesTimeseries,
      delta
    );
    parsedProject.milestones = fixMocksToday(parsedProject.milestones, delta);
    parsedProject.itemsDueDateSeriesMockData = fixMocksToday(
      parsedProject.itemsDueDateSeriesMockData || [],
      delta
    );

    return parsedProject;
  });
};
