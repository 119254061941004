import { FC, useState } from 'react';

import { ToastType } from '../../../../api/gqlEnums';
import { SourceSystem, SourceType } from '../../../../generated/graphql';
import { setToast } from '../../../../hooks/useToastParametersLocalQuery';
import {
  useLoadSourceHubProjects,
  useLoadSourceHubRootQuery,
} from '../../../DocumentMagic/FileExplorer/hooks/FileExplorerHooks';
import { Button, Dialog, DialogContent, StatusBanner } from '../../../scales';

import { useLinkIntegrationsEntityMutation } from './hooks/useLinkIntegrationsEntityMutation';
import IntegrationsSourceCompanyProjectSelect from './IntegrationsSourceCompanyProjectSelect';
import ProcoreSVGLoggedIn from './ProcoreSVGLoggedIn';

type IntegrationsLinkAccountsModalProps = {
  isLinkingAccounts: boolean;
  closeLinkAccountsModal: () => void;
  loggedInProcoreSource?: {
    sourceSystem: SourceSystem;
    id: string;
    email?: string | null;
  };
  savedSourceProjectID?: string;
  savedSourceCompanyID?: string;
  refetchLinkedProjectQuery: () => void;
  unlinkProcoreUser: () => void;
  joinProjectID: UUID;
};

const IntegrationsLinkAccountsModal: FC<IntegrationsLinkAccountsModalProps> = ({
  isLinkingAccounts,
  closeLinkAccountsModal,
  loggedInProcoreSource,
  unlinkProcoreUser,
  savedSourceProjectID,
  savedSourceCompanyID,
  refetchLinkedProjectQuery,
  joinProjectID,
}) => {
  const hasLinkedProcoreProject = !!(savedSourceCompanyID && savedSourceProjectID);

  const [companyID, setCompanyID] = useState<string | undefined>(savedSourceCompanyID);
  const [projectID, setProjectID] = useState<string | undefined>(savedSourceProjectID);

  // Procore companies and projects queries
  const procoreCompaniesData = useLoadSourceHubRootQuery(
    loggedInProcoreSource?.sourceSystem,
    loggedInProcoreSource?.id
  ).data?.sourceHubRoot;
  const procoreCompanies = procoreCompaniesData?.hubs;
  const procoreProjectsData = useLoadSourceHubProjects(
    loggedInProcoreSource?.sourceSystem,
    loggedInProcoreSource?.id,
    companyID
  ).data?.sourceHubProjects;
  const procoreProjects = procoreProjectsData?.projects;

  const hasProcoreQuerySourceError = !!(
    procoreCompaniesData?.sourceError || procoreProjectsData?.sourceError
  );

  const [linkIntegrationsEntity] = useLinkIntegrationsEntityMutation({
    // we need to refetch the saved linked Procore project if they update the link
    onCompleted: refetchLinkedProjectQuery,
  });

  return (
    <Dialog
      footerRight={
        <div className="flex gap-2">
          <Button
            disabled={!loggedInProcoreSource}
            label="Sign Out"
            onClick={() => {
              if (loggedInProcoreSource) {
                unlinkProcoreUser();
                closeLinkAccountsModal();
                setToast(
                  {
                    message:
                      !companyID || !projectID
                        ? 'Failed to link Procore account with Join account'
                        : 'Successfully signed out of Procore account',
                  },
                  ToastType.SERVER_ERROR
                );
              }
            }}
            type="secondary"
          />
          <Button
            disabled={
              !loggedInProcoreSource ||
              !companyID ||
              !projectID ||
              (companyID === savedSourceCompanyID && projectID === savedSourceProjectID)
            }
            label={hasLinkedProcoreProject ? 'Update and Remove Existing Links' : 'Link Account'}
            onClick={() => {
              if (loggedInProcoreSource && companyID && projectID)
                linkIntegrationsEntity(
                  loggedInProcoreSource?.sourceSystem,
                  SourceType.SOURCE_PROJECT,
                  projectID,
                  companyID,
                  joinProjectID
                );
              setToast(
                { message: 'Procore Company and Project successfully linked' },
                ToastType.SUCCESS
              );
              closeLinkAccountsModal();
            }}
            type="primary"
          />
        </div>
      }
      isOpen={isLinkingAccounts}
      onClose={closeLinkAccountsModal}
      title={hasLinkedProcoreProject ? 'Edit Procore Linked Account' : 'Link Accounts'}
    >
      <DialogContent>
        <div className="flex flex-col items-center gap-6">
          {hasProcoreQuerySourceError && (
            <StatusBanner type="error">
              There was an error loading your Procore
              {procoreCompaniesData?.sourceError ? ' companies' : ' projects'}. Please sign out of
              your Procore account in Join and try again.
            </StatusBanner>
          )}
          {!hasProcoreQuerySourceError && procoreCompanies && loggedInProcoreSource && (
            <>
              <div className="type-body1">
                Link your Procore account by selecting the Company and Project that correspond to
                this Join project
              </div>
              <div className="flex flex-col items-center gap-1">
                <div className="h-14 w-14">
                  <ProcoreSVGLoggedIn />
                </div>
                <div className="type-body2">
                  Signed into Procore as <b>{loggedInProcoreSource?.email}</b>
                </div>
              </div>
              <IntegrationsSourceCompanyProjectSelect
                hubID={companyID}
                projectID={projectID}
                setCompanyID={(value) => {
                  setProjectID(undefined);
                  setCompanyID(value);
                }}
                setProjectID={setProjectID}
                sourceHubs={procoreCompanies}
                sourceProjects={procoreProjects}
                sourceSystem={SourceSystem.PROCORE}
              />
              {(companyID && companyID !== savedSourceCompanyID) ||
                (projectID && projectID !== savedSourceProjectID && (
                  <div className="max-w-sm">
                    <StatusBanner type="warning">
                      Changing your Company or Project will remove any linked Change Events
                    </StatusBanner>
                  </div>
                ))}
            </>
          )}
          {!loggedInProcoreSource && (
            <>
              <div className="type-body2">
                Complete Procore Login in external tab before continuing here...
              </div>
              <div className="h-14 w-14">
                <ProcoreSVGLoggedIn />
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default IntegrationsLinkAccountsModal;
