import { FC } from 'react';

import {
  FILTER_DELIVERY_METHOD,
  FILTER_DESIGN_PHASE,
  FILTER_PROJECT_COMPANIES,
  FILTER_PROJECT_LEAD,
  FILTER_PROJECT_LOCATION,
  FILTER_PROJECT_ORGANIZATION,
  FILTER_PROJECT_STATUS,
  FILTER_PROJECT_TYPE,
} from '../../../constants';
import {
  ProjectFilterManager,
  ProjectFilterSetSettings,
  getSelectedForFilterType,
  getTitleForFilterType,
} from '../../ProjectsList/ProjectsListUtils';
import { MultiSelect } from '../../scales';

type InsightsFilterSelectProps = {
  filterManager: ProjectFilterManager;
  filterType: string;
  setSettings: ProjectFilterSetSettings;
};

const InsightsFilterSelect: FC<InsightsFilterSelectProps> = ({
  filterManager,
  filterType,
  setSettings,
}) => {
  const { clearFilterType, setFilter } = filterManager;
  const title = getTitleForFilterType(filterType);
  const options = getLabelsForFilterType(filterManager, filterType);
  const [selected] = getSelectedForFilterType(filterManager, filterType);

  return (
    <div className="px-2">
      <MultiSelect
        defaultValue={undefined}
        entries={options}
        isClearable
        isSearchable
        label={title}
        onChange={(selectedValues: string[]) => {
          // Determine IDs that have been added
          const newlySelectedIDs = selectedValues.filter((id) => !selected.includes(id));
          // Determine IDs that have been removed (deselected)
          const removedIDs = selected.filter((id) => !selectedValues.includes(id));

          if (selectedValues.length === 0) {
            // Clear All
            clearFilterType(filterType, setSettings);
          } else if (newlySelectedIDs.length)
            // Add Filter
            setFilter({ type: filterType, value: newlySelectedIDs[0] }, setSettings);
          else if (removedIDs.length)
            // Remove Filter
            setFilter({ type: filterType, value: removedIDs[0] }, setSettings);
        }}
        placeholder={`Find a ${title.toLowerCase()}...`}
        value={selected}
      />
    </div>
  );
};

export default InsightsFilterSelect;

const getLabelsForFilterType = (filterManager: ProjectFilterManager, filterType: string) => {
  const options = {
    [FILTER_PROJECT_LEAD]: [
      { id: 'Unassigned', name: 'Unassigned', label: 'Unassigned' },
      ...(filterManager.filterOptions?.projectLeads || []),
    ],
    [FILTER_PROJECT_ORGANIZATION]: filterManager.filterOptions?.organizationNodes || [],
    [FILTER_PROJECT_LOCATION]: filterManager.filterOptions?.locations || [],
    [FILTER_PROJECT_TYPE]: filterManager.filterOptions?.types || [],
    [FILTER_PROJECT_STATUS]: filterManager.filterOptions?.statuses || [],
    [FILTER_PROJECT_COMPANIES]: filterManager.filterOptions?.companies || [],
    [FILTER_DESIGN_PHASE]: filterManager.filterOptions?.designPhases || [],
    [FILTER_DELIVERY_METHOD]: filterManager.filterOptions?.deliveryMethods || [],
  };

  const selectedOptions = options[filterType as keyof typeof options] || [];

  return selectedOptions.map((option) => {
    if (typeof option === 'string') {
      return { id: option, name: option, label: option };
    }
    // This covers both ProjectType and OrgNode
    return { id: option.id, name: option.name, label: option.name };
  });
};
