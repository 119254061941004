import { FC } from 'react';

const ProcoreSVGLoggedIn: FC = () => {
  return (
    <svg
      fill="none"
      height="100%"
      viewBox="0 0 20 20"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_574_72810)">
        <path
          d="M14.8547 2.44922H5.06672C3.22272 2.44922 1.95605 3.71722 1.95605 5.55989V14.5466C1.95605 16.3892 3.22272 17.6572 5.06672 17.6572H14.8587C16.7014 17.6572 17.9694 16.3892 17.9694 14.5466V12.0132H14.2787L12.6667 14.7732H7.13605L4.37072 10.0532L7.13605 5.32922H12.6667L14.2801 8.09455H17.9667V5.56122C17.9654 3.71722 16.6974 2.44922 14.8547 2.44922Z"
          fill="black"
        />
        <path
          d="M8.51857 7.40381L7.02124 10.0531L8.51857 12.5878H11.5132L13.0106 10.0531L11.5132 7.40381H8.51857Z"
          fill="#FF5200"
        />
      </g>
      <defs>
        <clipPath id="clip0_574_72810">
          <rect fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProcoreSVGLoggedIn;
