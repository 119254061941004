import { gql } from '../../../../../api/graphqlFragments';

export const searchProjectsForProjectSelection = gql`
  query searchProjectsForProjectSelection(
    $filters: ForecastingProjectsFilters!
    $searchResultType: SearchResultType
    $search: String!
    $sortBy: ForecastingProjectsSortBy!
    $pagination: Pagination!
  ) {
    searchProjects(
      filters: $filters
      searchResultType: $searchResultType
      search: $search
      sortBy: $sortBy
      pagination: $pagination
    ) {
      data {
        budget
        estimate
        id
        isAdmin
        location
        milestoneName
        name
        runningTotal
        status
        thumbnail
        type
        updatedAt
        updatedBy
      }
      total
    }
  }
`;

export const searchProjects = gql`
  query searchProjects(
    $filters: ForecastingProjectsFilters!
    $searchResultType: SearchResultType
    $search: String!
    $sortBy: ForecastingProjectsSortBy!
    $pagination: Pagination!
  ) {
    searchProjects(
      filters: $filters
      searchResultType: $searchResultType
      search: $search
      sortBy: $sortBy
      pagination: $pagination
    ) {
      data {
        budget
        estimate
        hasAccess
        id
        location
        milestoneDate
        milestoneID
        milestoneName
        name
        orgNodes {
          id
          name
        }
        projectLeadName
        runningTotal
        status
        thumbnail
        type
        updatedAt
        updatedBy
      }
      total
    }
  }
`;

export const searchProjectsIds = gql`
  query searchProjectsIds(
    $filters: ForecastingProjectsFilters!
    $searchResultType: SearchResultType
    $search: String!
    $sortBy: ForecastingProjectsSortBy!
    $pagination: Pagination!
  ) {
    searchProjects(
      filters: $filters
      searchResultType: $searchResultType
      search: $search
      sortBy: $sortBy
      pagination: $pagination
    ) {
      data {
        id
      }
      total
    }
  }
`;
