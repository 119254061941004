import { ItemTimeSeries } from '../../dragon-scales/TimelineCharts/InsightsItems/types';

import * as hardcodedDemoData from './exampleDemoData.json';

export type InsightsProjectDemoData = InsightsProjects & {
  demoDataReferenceDate: Date;
  itemsDueDateSeriesMockData?: ItemTimeSeries[];
};

// List of date field names that need to be parsed as dates, and not just strings.
const dateFields = ['date', 'demoDataReferenceDate'];

export const parseDemoData = (raw: string) => {
  let str = raw;
  if (raw.length === 0) {
    str = JSON.stringify(hardcodedDemoData);
  }

  try {
    const dd = JSON.parse(str, (key, value) => {
      if (dateFields.includes(key)) {
        return new Date(value);
      }
      return value;
    }) as InsightsProjectDemoData;
    // roll forward demodata.
    return dd;
  } catch {
    // Don't error, just skip.
    return {} as InsightsProjectDemoData;
  }
};
