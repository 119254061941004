import { FC, useState } from 'react';

import {
  CompanyAdminView,
  LeadEvent,
  LeadKey,
  leadEvent,
} from '../../../analytics/analyticsEventProperties';
import {
  FILTER_PROJECT_LEAD,
  FILTER_PROJECT_LOCATION,
  FILTER_PROJECT_ORGANIZATION,
  FILTER_PROJECT_STATUS,
  FILTER_PROJECT_TYPE,
  PROJECTS,
} from '../../../constants';
import { YC_GROUPS, YC_PROJ_LEAD } from '../../../features';
import { useHasFeature } from '../../../hooks/useHasFeature';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { getProjectIdFromUrl } from '../../../utilities/url';
import ClearFiltersPanelHeader from '../../FilterPanel/ClearFilters/ClearFiltersPanelHeader';
import FilterIcon from '../../Icons/FilterIcon';
import InputsSettingsPanel from '../../Inputs/InputsSettingsPanel/InputsSettingsPanel';
import OrgsFilterLevelsSelect from '../../ProjectsList/ProjectsListFilter/ProjectsListFilterSelect/OrgsFilterLevelsSelect';
import ProjectsListFilterSelect from '../../ProjectsList/ProjectsListFilter/ProjectsListFilterSelect/ProjectsListFilterSelect';
import ProjectTypeFilter from '../../ProjectsList/ProjectsListFilter/ProjectTypeFilter';
import {
  ProjectFilterManager,
  ProjectFilterSetSettings,
  projectFilterStateHasFilters,
} from '../../ProjectsList/ProjectsListUtils';

type ProjectStatsFilterPanelProps = {
  filterManager: ProjectFilterManager;
  setSettings: ProjectFilterSetSettings;
};

const ProjectStatsFilterPanel: FC<ProjectStatsFilterPanelProps> = ({
  filterManager,
  setSettings,
}) => {
  const isGroupsFeature = useHasFeature(YC_GROUPS);
  const isProjectLeadEnabled = useHasFeature(YC_PROJ_LEAD);
  const sendAnalytics = useSendAnalytics();
  const hasFilters = projectFilterStateHasFilters(filterManager.filterState);
  const [show, setShow] = useState(false);

  return (
    <InputsSettingsPanel
      header={
        <ClearFiltersPanelHeader
          filterManager={filterManager}
          projectID={getProjectIdFromUrl()}
          setSettings={setSettings}
          variant={PROJECTS}
        />
      }
      icon={<FilterIcon isFilled={hasFilters} />}
      page={CompanyAdminView.PROJECT_STATS_LIST}
      setShow={(show) => {
        setShow(show);
        if (show) {
          sendAnalytics(leadEvent(LeadKey.COMPANY_ADMIN_STATS, LeadEvent.FilterClick));
        }
      }}
      show={show}
    >
      <ProjectsListFilterSelect
        clearAllText="clear"
        filterManager={filterManager}
        filterType={FILTER_PROJECT_STATUS}
        setSettings={setSettings}
      />
      <ProjectTypeFilter
        clearAllText="clear"
        filterManager={filterManager}
        filterType={FILTER_PROJECT_TYPE}
        setSettings={setSettings}
      />
      <ProjectsListFilterSelect
        clearAllText="clear"
        filterManager={filterManager}
        filterType={FILTER_PROJECT_LOCATION}
        setSettings={setSettings}
      />
      {isProjectLeadEnabled && (
        <ProjectsListFilterSelect
          clearAllText="clear"
          filterManager={filterManager}
          filterType={FILTER_PROJECT_LEAD}
          setSettings={setSettings}
        />
      )}
      {isGroupsFeature && (
        <OrgsFilterLevelsSelect
          clearAllText="clear"
          filterManager={filterManager}
          filterType={FILTER_PROJECT_ORGANIZATION}
          setSettings={setSettings}
        />
      )}
    </InputsSettingsPanel>
  );
};

export default ProjectStatsFilterPanel;
