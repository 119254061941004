import { FC } from 'react';

import { useReactiveVar } from '@apollo/client';

import { searchEventTypes } from '../../../../analytics/analyticsEventProperties';
import { itemSidebarHomeOpenVar } from '../../../../api/apollo/reactiveVars';
import { YC_GROUPS, YC_PROJ_LEAD } from '../../../../features';
import { ForecastingExploreItemsSortKey } from '../../../../generated/graphql';
import { useHasFeature } from '../../../../hooks/useHasFeature';
import { HOME_ITEM_SIDEBAR } from '../../../../tagConstants';
import { formatNumber } from '../../../../utilities/string';
import { CloseItemSidebar, OpenItemSidebar } from '../../../Icons/OpenSidebar';
import { Button, SortManager, Table } from '../../../scales';
import useSendSearchAnalytics from '../hooks/useSendSearchAnalytics';
import { SearchItem } from '../types';

import CostCell from './cells/CostCell';
import ItemCell from './cells/ItemCell';
import ItemStatusCell from './cells/ItemStatusCell';
import MilestoneDateCell from './cells/MilestoneDateCell';
import ProjectCell from './cells/ProjectCell';

type Props = {
  counts: { current: number; total: number };
  items: SearchItem[];
  loading: boolean;
  onFetchMore: () => void;
  onOpenItemSidebar: (id: UUID) => void;
  searchText: string;
  sortManager: SortManager;
};

const SearchItems: FC<Props> = (props) => {
  const isGroupsEnabled = useHasFeature(YC_GROUPS);
  const isProjectLeadEnabled = useHasFeature(YC_PROJ_LEAD);
  const sendSearchAnalytics = useSendSearchAnalytics();
  const itemIdHomeReactiveVar = useReactiveVar(itemSidebarHomeOpenVar);

  return (
    <Table
      columnWidths={[
        'minmax(300px, 10fr)',
        'max-content',
        'max-content',
        'max-content',
        'minmax(150px, 3fr)',
        'max-content',
        'max-content',
      ]}
      entries={props.items.map((item) => {
        const key = item.id;

        return [
          {
            key,
            component: (
              <ItemCell
                item={item}
                searchText={props.searchText}
                showOrgs={isGroupsEnabled}
                showProjectLead={isProjectLeadEnabled}
              />
            ),
          },
          { key, component: <ItemStatusCell status={item.status} /> },
          { key, component: <CostCell cost={item.cost} /> },
          { key, component: <CostCell cost={item.project.runningTotal} /> },
          { key, component: <ProjectCell item={item} /> },
          { key, component: <MilestoneDateCell item={item} /> },
          {
            key,
            component: (
              <Button
                data-cy={HOME_ITEM_SIDEBAR}
                disabled={!item.hasAccess}
                label={itemIdHomeReactiveVar === item.id ? 'Close' : 'Details'}
                onClick={() => {
                  props.onOpenItemSidebar(item.id);
                  sendSearchAnalytics(searchEventTypes.SEARCH_ITEM_DETAIL_CTA, {
                    id: item.id,
                    name: item.name,
                    state: itemIdHomeReactiveVar === item.id ? 'Close' : 'Open',
                  });
                }}
                startIcon={
                  itemIdHomeReactiveVar === item.id ? <CloseItemSidebar /> : <OpenItemSidebar />
                }
                type="secondary"
              />
            ),
          },
        ];
      })}
      headerContent={[
        {
          copy: `Items (${formatNumber(props.counts.current)} of ${formatNumber(
            props.counts.total
          )})`,
          key: 'items',
          headerSortKey: ForecastingExploreItemsSortKey.SCORE,
        },
        {
          copy: 'Status',
          key: 'status',
          headerSortKey: ForecastingExploreItemsSortKey.STATUS,
        },
        {
          copy: 'Cost impact',
          key: 'cost-impact',
          headerSortKey: ForecastingExploreItemsSortKey.COST_IMPACT,
        },
        {
          copy: 'Project total',
          key: 'project-total',
          tooltip: 'Running total',
        },
        {
          copy: 'Project',
          key: 'project',
          headerSortKey: ForecastingExploreItemsSortKey.PROJECT_NAME,
        },
        {
          copy: 'Milestone date',
          key: 'milestone-date',
          headerSortKey: ForecastingExploreItemsSortKey.MILESTONE_DATE,
        },
        { copy: '', key: 'expand', centered: true },
      ]}
      loading={props.loading}
      onNeedMoreData={props.onFetchMore}
      sortManager={props.sortManager}
    />
  );
};

export default SearchItems;
