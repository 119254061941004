import { useEffect, useState } from 'react';

import { CostFields, CostTimeSeries, InsightsMilestone } from './types';

export const getYDomain = (data: CostTimeSeries[]): [number, number] => {
  const budget = data.map((d) => d[CostFields.budget]);
  const estimate = data.map((d) => d[CostFields.estimate]);
  const runningTotal = data.map((d) => d[CostFields.runningTotal]);
  const pendingMax = data.map((d) => d[CostFields.pendingMax]);
  const pendingMin = data.map((d) => d[CostFields.pendingMin]);
  const common = [...budget, ...estimate, ...runningTotal, ...pendingMax, ...pendingMin];
  return [Math.min(...common) ?? 0, Math.max(...common) ?? 0];
};

export const getDaysTo = (date: Date, today: Date): number => {
  return Math.round((date.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
};

export const useGmps = (data: InsightsMilestone[], today: Date) => {
  const [activeGmp, setActiveGmp] = useState<UUID>();
  const [comingGmp, setComingGmp] = useState<UUID>();
  const [activeMilestone, setActiveMilestone] = useState<UUID>();

  useEffect(() => {
    setTimeout(() => {
      data.forEach((m) => {
        if (m.active && m.designPhase) {
          setActiveGmp(m.id);
        }
        if (!m.active && m.designPhase && m.date >= today) {
          setComingGmp(m.id);
        }
        if (m.active && !m.designPhase && m.date <= today) {
          setActiveMilestone(m.id);
        }
      });
    }, 0);
  }, [data, today]);

  return { activeGmp, activeMilestone, comingGmp };
};
