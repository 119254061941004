import { FC } from 'react';

import { DD_AUTODESK_PCO_INTEGRATION } from '../../../features';
import { PermissionResource } from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useHasFeature';
import useItemIntegrationObjectsCountQuery from '../../../hooks/useItemIntegrationObjectsCountQuery';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { useUserSourcesQuery } from '../../DocumentMagic/hooks';
import IntegrationsDataWrapper from '../../Items/ItemsCollapse/Integrations/IntegrationsDataWrapper';
import IntegrationsDataWrapperNew from '../../Items/ItemsCollapse/Integrations/IntegrationsDataWrapperNew';

import { ITEM_HISTORY_ZOOM, useHeightOnLoad } from './PrintItemDetailsUtils';

type Props = {
  item: ItemDataQueryItem;
  permissions: ReturnType<typeof usePermissions>;
  triggerOnComplete?: () => void;
};

const PrintItemDetailsIntegrations: FC<Props> = ({ item, permissions, triggerOnComplete }) => {
  const hasAutodeskPCOIntegrationFeature = useHasFeature(DD_AUTODESK_PCO_INTEGRATION);

  const itemIntegrationObjectsCount =
    useItemIntegrationObjectsCountQuery({ itemID: item.id }).data?.itemIntegrationObjectsCount ?? 0;

  const canViewItemIntegrations = permissions.canView(PermissionResource.ITEM_INTEGRATIONS, {
    trades: item?.categories || [],
  });
  const userSources = useUserSourcesQuery().data?.userSources;

  const { onLoadComplete, ref, height } = useHeightOnLoad(triggerOnComplete, 1 / ITEM_HISTORY_ZOOM);

  if (!canViewItemIntegrations) return null;

  return (
    <div
      ref={ref}
      className="mt-5"
      style={{
        height,
        transform: `scale(${ITEM_HISTORY_ZOOM})`,
        transformOrigin: 'top left',
      }}
    >
      {itemIntegrationObjectsCount > 0 && (
        <div className="float-left w-full type-heading3">Integrations</div>
      )}
      {hasAutodeskPCOIntegrationFeature ? (
        userSources && (
          <IntegrationsDataWrapperNew
            canCreateChangeEvent={false}
            canDeleteItemIntegration={false}
            isPrint
            itemInfo={item}
            userSources={userSources}
          />
        )
      ) : (
        <IntegrationsDataWrapper
          canCreateChangeEvent={false}
          canDeleteItemIntegration={false}
          isItemSidebar={false}
          isPrinting
          itemInfo={item}
          onLoadComplete={onLoadComplete}
          userSources={userSources}
        />
      )}
    </div>
  );
};
export default PrintItemDetailsIntegrations;
