import { YC_DEMO_MOCKS_INSIGHTS_V2 } from '../../../../features';
import { useHasFeature } from '../../../../hooks/useHasFeature';
import { TimelineData } from '../../../shared-widgets/TimelineChart/timeline/timeline.types';
import { useTimelineQuery } from '../../../Timeline/hooks/TimelineHook';
import { buildTree } from '../../../Timeline/Timeline';
import { ACTIVITIES_DEFAULT, getTimelineData } from '../../../Timeline/TimelineUtils';
import useMemoWrapper from '../../../useMemoWrapper';
import { timelineMocks } from '../../InsightsProject/insightsProject.mocks';

export function useTimeline(projectID: UUID) {
  const useDemoData = useHasFeature(YC_DEMO_MOCKS_INSIGHTS_V2);

  const timelineQueryResults = useTimelineQuery({
    // @ts-ignore TODO: this is fine, the error is in the useTimelineQuery param, which has the same type as query vars
    projectID: useDemoData ? undefined : projectID,
    types: ACTIVITIES_DEFAULT,
  });
  const data = useDemoData ? timelineMocks : timelineQueryResults.data;

  const activities = data?.timeline?.activities ?? [];
  const tree = useMemoWrapper(buildTree, activities);
  const timelineData: TimelineData[] = useMemoWrapper(getTimelineData, tree);
  return { timelineData, activities };
}
