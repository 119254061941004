import * as d3 from 'd3';
import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';

import useMemoWrapper from '../../useMemoWrapper';

type TimeDate = {
  date: Date;
};

type Value = {
  // The key string of your choice
  // Preferably API driven
  [key in string]: number;
};

// Default key fields:
const VALUE_KEY = 'value';

export default function TimelinePath<V extends Value>(props: {
  data: (V & TimeDate)[];
  field?: string;
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
  stroke?: string;
  strokeDasharray?: string;
  strokeWidth?: number;
}) {
  const line =
    useMemoWrapper(getLineCoords, props.data, props.x, props.y, props.field ?? VALUE_KEY) ??
    undefined;
  if (!props.data?.length) return null;
  return (
    <path
      className={props.stroke ?? 'stroke-type-primary'}
      d={line}
      data-cy="path-line"
      fill="none"
      strokeDasharray={props.strokeDasharray ?? 'none'}
      strokeWidth={props.strokeWidth ?? 1}
    />
  );
}

function getLineCoords(
  data: (TimeDate & Value)[],
  x: ScaleTime<number, number> | ScalePoint<Date>,
  y: ScaleLinear<number, number>,
  field: string
) {
  return d3
    .line<TimeDate & Value>()
    .x((d) => x(d.date) ?? 0)
    .y((d) => y(d[field]))(data);
}
