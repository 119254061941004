import { FC, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { Add } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  CompanyAdminView,
  LeadEvent,
  LeadKey,
  companyAdminAnalyticsEvent,
  leadEvent,
} from '../../../analytics/analyticsEventProperties';
import { JoinCompanyRoutes, JoinRoutes, ToastType } from '../../../api/gqlEnums';
import { UserStatus } from '../../../api/gqlEnumsBe';
import { REFETCH_COMPANY_STATS, refetchStatsCollaborators } from '../../../api/refetchSets';
import { ADD_NEW_EMPLOYEE, UNASSIGNED } from '../../../constants';
import { YC_GROUPS, YC_PROJ_LEAD } from '../../../features';
import { ProjectType } from '../../../generated/graphql';
import { useCollaboratorsMultipleProjectsQuery } from '../../../hooks/useCollaboratorsMultipleProjectsQuery';
import { useCompanyProjectTypesQuery } from '../../../hooks/useCompanyProjectTypesQuery';
import { useHasFeature } from '../../../hooks/useHasFeature';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCost } from '../../../utilities/currency';
import { generateSharedPath } from '../../../utilities/routes/links';
import { formatListWithCommas, pluralizeCountString } from '../../../utilities/string';
import { EmptyThumbnail } from '../../AssigneeSelect/AssigneeSelect';
import { useCreateCollaborators } from '../../Collaborators/hooks';
import { useRemoveProjectLead, useSetProjectLead } from '../../Collaborators/hooks/projectLead';
import UserAvatar from '../../Collaborators/UserAvatar';
import DialogsAddCompanyEmployee from '../../Dialogs/DialogsAddCompanyEmployee/DialogsAddCompanyEmployee';
import { computeProjectTypes } from '../../Dialogs/DialogsNewProject/DialogsNewProjectUtils';
import { useCompanyQuery } from '../../login/Signup/SignupPages/CreateCompanyHooks';
import { useSetProjectOrgs } from '../../Organizations/hooks/hooks';
import {
  Button,
  Checkbox,
  ScrollContainer,
  Select,
  SelectEntry,
  SortManager,
  Table,
} from '../../scales';
import SearchText from '../../Search/SearchText/SearchText';
import { CheckboxVariant } from '../../shared-widgets/CheckboxIndeterminateContainer';
import {
  useCompanyProjectRolesQuery,
  useCompanyRolesQuery,
  useUpdateCompanyProjectTypeMutation,
} from '../CompanyHooks';
import useOrganizationsQuery from '../CompanyTabOrganizations/hooks/useOrganizationsQuery';
import OrgSelector from '../CompanyTabOrganizations/OrgSelector/OrgSelector';
import CompanyTabStyles from '../CompanyTabStyles';
import {
  gridRowHeight,
  projectStatsGridWidth,
  projectStatsHeaderContent,
} from '../CompanyTabUtils';
import { useCompanyUsersQuery } from '../useCompanyUsersQuery';

import AddLeadToProjectDialog, { AddLeadtoProject } from './AddLeadToProjectDialog';
import BulkEditProjectStatsDialog from './BulkEditProjectStatsDialog';
import {
  ProjectCollaboratorsMap,
  ProjectUserDetails,
  SelectedProjectDetails,
} from './BulkEditProjectStatsUtils';

type CompanyTabProjectStatsTableProps = {
  classes: Classes<typeof CompanyTabStyles>;
  companyID: UUID;
  companyProjectStats: CompanyProjectStats[];
  sortManager: SortManager;
  searchTerm: string;
  loading: boolean;
};

const CompanyTabProjectStatsTable: FC<CompanyTabProjectStatsTableProps> = ({
  classes,
  companyID,
  companyProjectStats,
  sortManager,
  searchTerm,
  loading,
}) => {
  const sendAnalytics = useSendAnalytics();
  const [selectedProjects, setSelectedProjects] = useState<SelectedProjectDetails>(new Map());
  const [bulkEditDialogOpen, setBulkEditDialogOpen] = useState<boolean>(false);
  const [inviteLeadToProjDialogOpen, setInviteLeadToProjDialogOpen] =
    useState<AddLeadtoProject | null>(null);
  const [addEmployeeDialogProjID, setAddEmployeeDialogProjID] = useState<UUID | null>(null);

  const onBulkEditProjects = () => {
    setBulkEditDialogOpen(true);
  };

  const onClearProjectSelection = () => setSelectedProjects(new Map());
  const onToggleProjectSelection = (projectID: UUID) => {
    setSelectedProjects((prevState) => {
      const newState = new Map(prevState);
      if (newState.has(projectID)) {
        newState.delete(projectID);
      } else {
        const projectStats = companyProjectStats.find((stat) => stat.id === projectID);
        const lead = activeUsers.find((u) => u.user?.id === projectStats?.projectLead?.id);
        if (projectStats) {
          newState.set(projectID, {
            orgNodes: projectStats.orgNodes,
            projectType: projectStats.projectType,
            projectLead: lead
              ? { userID: lead.user?.id || '', userName: lead.user?.name || '' }
              : undefined,
          });
        }
      }
      return newState;
    });
  };
  const totalProjects = companyProjectStats.length;
  const totalSelectedProjects = selectedProjects.size;
  let selectAllHeaderVariant =
    totalProjects === totalSelectedProjects
      ? CheckboxVariant.Selected
      : CheckboxVariant.Indeterminate;
  if (totalSelectedProjects === 0) {
    selectAllHeaderVariant = CheckboxVariant.Empty;
  }

  const onToggleAll = () => {
    if (selectAllHeaderVariant === CheckboxVariant.Selected) {
      onClearProjectSelection();
    } else {
      const newState = new Map();
      companyProjectStats.forEach((stat) => {
        const lead = activeUsers.find((u) => u.user?.id === stat?.projectLead?.id);
        newState.set(stat.id, {
          orgNodes: stat.orgNodes,
          projectType: stat.projectType,
          projectLead: lead
            ? { userID: lead.user?.id || '', userName: lead.user?.name || '' }
            : undefined,
        });
      });
      setSelectedProjects(newState);
    }
  };
  const getHeaderCheckbox = () => {
    const isSelected = selectAllHeaderVariant === CheckboxVariant.Selected;
    const isIndeterminate = selectAllHeaderVariant === CheckboxVariant.Indeterminate;
    return [
      {
        component: (
          <div className="ml-1">
            <Checkbox
              data-cy="select-multiple-checkbox"
              isIndeterminate={!isSelected && isIndeterminate}
              isSelected={isIndeterminate || isSelected}
              onChange={() => onToggleAll()}
            >
              <></>
            </Checkbox>
          </div>
        ),
        copy: '',
        key: 'selection-checkbox',
      },
    ];
  };

  // Project Types
  const { data: typesData } = useCompanyProjectTypesQuery(false, companyID);
  const projectTypes = typesData?.companyProjectTypes || [];
  const setProjectType = useUpdateCompanyProjectTypeMutation(companyID);

  // Organizations
  const isGroupsFeature = useHasFeature(YC_GROUPS);
  const { data: organizationsQueryResult } = useOrganizationsQuery(companyID);
  const orgs = (organizationsQueryResult?.organizations ?? []).filter((org) => !org.isDraft);
  const [setProjectOrgs] = useSetProjectOrgs();

  // Project Leads
  const isLeadsFeature = useHasFeature(YC_PROJ_LEAD);
  const { data } = useCompanyUsersQuery({
    variables: { companyID },
    skip: !companyID,
  });
  const activeUsers =
    useMemo(
      () =>
        data?.companyUsers.filter(
          (companyUser) => companyUser?.user?.status !== UserStatus.DEACTIVATED
        ),
      [data?.companyUsers]
    ) || [];

  const companyProjectRoles =
    useCompanyProjectRolesQuery(companyID).data?.companyProjectRoles ?? [];
  const companyRoles = useCompanyRolesQuery(companyID).data?.companyRoles ?? [];
  const company = useCompanyQuery(companyID).data?.company ?? undefined;
  const [setProjectLead] = useSetProjectLead();
  const [removeProjectLead] = useRemoveProjectLead();
  const [createCollaborators] = useCreateCollaborators(REFETCH_COMPANY_STATS);
  const collaboratorsData = useCollaboratorsMultipleProjectsQuery(
    companyProjectStats.map((p) => p.id)
  );

  const allCollaborators = collaboratorsData.data?.loadCollaboratorsMultipleProjects;
  const allProjectCollaborators: ProjectCollaboratorsMap = useMemo(() => {
    if (!allCollaborators) return new Map();
    const projCollabMap: ProjectCollaboratorsMap = new Map();
    allCollaborators?.forEach((projectData) => {
      const { projectID, collaborators } = projectData;
      const userDetails: ProjectUserDetails[] = collaborators.map((c) => ({
        userID: c.user.id,
        userName: c.user.name,
        isProjectLead: c.isProjectLead,
      }));
      projCollabMap.set(projectID, userDetails);
    });
    return projCollabMap;
  }, [allCollaborators]);

  const onAddProjectCollaborator = async (projectLeadUserID: UUID, projectID: UUID) => {
    try {
      // Find the email of the project lead
      const projectLead = activeUsers.find((user) => user.user?.id === projectLeadUserID)?.user;
      const projectLeadEmail = projectLead?.email;
      // Add collaborator if email is found
      if (projectLeadEmail) {
        await createCollaborators(projectID, [
          {
            userEmail: projectLeadEmail,
            allTrades: true,
            roleName: 'Administrator',
            responsibility: '',
            trades: [],
          },
        ]);
        // Set the project lead
        await setProjectLead({
          variables: {
            projectID,
            userID: projectLeadUserID,
          },
          refetchQueries: REFETCH_COMPANY_STATS,
        });
      }
      // Close dialog
      setInviteLeadToProjDialogOpen(null);
    } catch (error) {
      console.error('Error adding project collaborator or setting project lead:', error);
    }
  };

  const onCreateNewEmployee = async (user: User, projectNames: string[]) => {
    if (!addEmployeeDialogProjID) {
      return;
    }
    try {
      const response = await setProjectLead({
        variables: {
          projectID: addEmployeeDialogProjID,
          userID: user.id,
        },
        refetchQueries: refetchStatsCollaborators(companyProjectStats.map((p) => p.id)),
      });
      sendAnalytics(
        leadEvent(LeadKey.COMPANY_ADMIN_STATS, LeadEvent.UpdateProjectLead, {
          userID: user.id,
          userType: 'new',
        })
      );

      if (response.data?.setProjectLead.updated) {
        setToast(
          {
            message: `${user.name} was added to ${
              company?.name
            } company and invited to ${formatListWithCommas(projectNames)}`,
          },
          ToastType.SUCCESS
        );
      }
    } catch (error) {
      console.error('Error setting project lead:', error);
    }
  };

  const entries = useMemo(
    () =>
      companyProjectStats.map((companyProject) => {
        if (companyProject) {
          const formatCostSettings = {
            showCents: false,
            settingsOverride: {
              // TODO: add the project's currency to the query
              // CURRENCY: companyProject.currency,
              CURRENCY: 'USD', // temporary - always USD until above is updated
              ROUNDING_PRECISION: 3,
            },
          };
          const companyPath = generateSharedPath(JoinRoutes.PROJECT, {
            projectId: companyProject.id,
          });

          const userPath = (companyID: UUID) => {
            if (companyID === companyProject.createdBy?.company?.id) {
              return generateSharedPath(JoinCompanyRoutes.COMPANY_MEMBERS_PROFILE, {
                userId: companyProject.createdBy?.user?.id,
              });
            }
            return generateSharedPath(JoinCompanyRoutes.COMPANY_COLLABORATORS_PROFILE, {
              userId: companyProject.createdBy?.user?.id,
            });
          };

          const projectName = (
            <Link
              className={`${classes.linkTitle} ${classes.textOverflow}`}
              onClick={() => {
                sendAnalytics(
                  companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_STATS_PROJECT_LINK, {
                    view: CompanyAdminView.PROJECT_STATS_LIST,
                  })
                );
              }}
              to={companyPath}
            >
              <SearchText searchTerm={searchTerm} text={companyProject.name} />
            </Link>
          );
          const types: ProjectType[] = computeProjectTypes(
            projectTypes,
            companyProject.projectType
          );

          const type = companyProject.type ? (
            <div className={classes.text}>
              <Select
                aria-label="project type"
                entries={types
                  .filter((t) => !t.hidden)
                  .map((t) => ({
                    id: t.id,
                    label: t.name,
                    parentID: t.parentID,
                  }))}
                isSearchable
                label=""
                onChange={(id) => {
                  if (!id) return;
                  setProjectType(companyProject.id, id);
                }}
                value={companyProject.projectType.id}
              />
            </div>
          ) : undefined;

          const collaboratorsCurrentProject = allProjectCollaborators.get(companyProject.id);
          let collaboratorSelectEntries: SelectEntry[] = [];
          if (collaboratorsCurrentProject) {
            collaboratorSelectEntries = computeProjectCollaborators(
              collaboratorsCurrentProject,
              data?.companyUsers || [],
              companyProject.projectLead?.id
            );
          }
          const projLead = (
            <div className={classes.text}>
              <Select
                entries={collaboratorSelectEntries}
                isSearchable
                onChange={async (projectLeadID) => {
                  if (projectLeadID === ADD_NEW_EMPLOYEE) {
                    setAddEmployeeDialogProjID(companyProject.id);
                    sendAnalytics(leadEvent(LeadKey.COMPANY_ADMIN_STATS, LeadEvent.NEW_MEMBER));
                  } else if (projectLeadID) {
                    const projectCollaborators = allProjectCollaborators.get(companyProject.id);
                    const projectCollaborator = projectCollaborators?.find(
                      (c) => c.userID === projectLeadID
                    );
                    const companyUser = activeUsers.find(
                      (user) => user.user?.id === projectLeadID
                    )?.user;
                    // If the collaborator is not part of the project:
                    if (!projectCollaborator && companyUser) {
                      setInviteLeadToProjDialogOpen({
                        user: companyUser,
                        projectID: companyProject.id,
                      });
                    } else {
                      if (!projectLeadID) return;
                      if (projectLeadID !== UNASSIGNED) {
                        await setProjectLead({
                          variables: {
                            projectID: companyProject.id,
                            userID: projectLeadID,
                          },
                          refetchQueries: REFETCH_COMPANY_STATS,
                        });
                        sendAnalytics(
                          leadEvent(LeadKey.SETTINGS_UPDATE, LeadEvent.ProjectLead, {
                            userID: projectLeadID,
                            userType: 'employee',
                          })
                        );
                        return;
                      }
                      // For unassigned project lead:
                      const oldProjLead = projectCollaborators?.find((c) => c.isProjectLead);
                      if (oldProjLead?.userID) {
                        await removeProjectLead({
                          variables: {
                            projectID: companyProject.id,
                            userID: oldProjLead.userID,
                          },
                          refetchQueries: REFETCH_COMPANY_STATS,
                        });
                      }
                    }
                  }
                }}
                value={companyProject.projectLead?.id ?? UNASSIGNED}
              />
            </div>
          );

          const organizations = orgs.map((org) => {
            const selectedNodesCurrentOrg = org.nodes.filter((n) =>
              companyProject.orgNodes.some((cn) => cn.id === n.id)
            );
            const selectedNodesFromOtherOrgs = companyProject.orgNodes.filter(
              (cn) => !selectedNodesCurrentOrg.some((n) => n.id === cn.id)
            );
            return {
              key: companyProject.id,
              component: (
                <OrgSelector
                  isMultiSelect
                  onChange={(orgNodeIDs: string[]) => {
                    setProjectOrgs({
                      variables: {
                        input: {
                          projectID: companyProject.id,
                          nodeIDs: [...orgNodeIDs, ...selectedNodesFromOtherOrgs.map((n) => n.id)],
                        },
                      },
                      refetchQueries: REFETCH_COMPANY_STATS,
                    });
                    sendAnalytics(
                      companyAdminAnalyticsEvent(
                        CompanyAdminEventType.PROJECT_STATS_UPDATE_ORGANIZATIONS,
                        { orgNodeIDs }
                      )
                    );
                  }}
                  orgNodes={selectedNodesCurrentOrg}
                  orgs={[org]}
                />
              ),
            };
          });

          const location = companyProject.location ? (
            <div className={classes.text}>
              <SearchText searchTerm={searchTerm} text={companyProject.location} />
            </div>
          ) : undefined;

          const createdAt = companyProject.createdAt ? (
            <div className={classes.text}>
              {new Date(companyProject.createdAt).toLocaleDateString()}
            </div>
          ) : undefined;
          const estimate = (
            <div className="type-number">
              {formatCost(companyProject.estimate, formatCostSettings)}
            </div>
          );
          const runningTotal = (
            <div className="type-number">
              {formatCost(companyProject.runningTotal, formatCostSettings)}
            </div>
          );
          const budget = (
            <div className="type-number">
              {formatCost(companyProject.budget, formatCostSettings)}
            </div>
          );
          const createdBy = companyProject.createdBy?.user ? (
            <Link
              className={`${classes.linkTitle} ${classes.textOverflow}`}
              onClick={() => {
                sendAnalytics(
                  companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_STATS_CREATOR_LINK, {
                    view: CompanyAdminView.PROJECT_STATS_LIST,
                  })
                );
              }}
              to={userPath(companyID)}
            >
              <SearchText searchTerm={searchTerm} text={companyProject.createdBy?.user.name} />
            </Link>
          ) : undefined;

          const isSelected = !!selectedProjects.get(companyProject.id);
          const select = (
            <div className="ml-1">
              <Checkbox
                isSelected={isSelected}
                onChange={() => onToggleProjectSelection(companyProject.id)}
              >
                <></>
              </Checkbox>
            </div>
          );
          const selectCol = { component: select, key: companyProject.id };
          return [
            ...(isGroupsFeature ? [selectCol] : []),
            { component: projectName, key: companyProject.name },
            { component: type, key: companyProject.projectType?.name ?? companyProject.type },
            ...(isLeadsFeature ? [{ component: projLead, key: companyProject.name }] : []),
            ...(isGroupsFeature ? organizations : []),
            { component: location, key: companyProject.location },
            { component: createdAt, key: companyProject.createdAt },
            { text: companyProject.status ?? undefined, key: companyProject.status },
            { component: estimate, key: companyProject.estimate },
            { component: runningTotal, key: companyProject.runningTotal },
            { component: budget, key: companyProject.budget },
            { component: createdBy, key: companyProject.createdBy?.user?.name },
            {
              text: companyProject.milestoneCount,
              key: companyProject.milestoneCount,
              isNumber: true,
            },
            {
              text: companyProject.itemCount || '0',
              key: companyProject.itemCount,
              isNumber: true,
            },
            { text: companyProject.teamCount, key: companyProject.teamCount, isNumber: true },
          ];
        }
        return [];
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
    [
      companyProjectStats,
      classes,
      sendAnalytics,
      companyID,
      searchTerm,
      isGroupsFeature,
      orgs,
      allProjectCollaborators,
    ]
  );

  const selectAllHeader = isGroupsFeature ? getHeaderCheckbox() : [];

  return (
    <div className="flex grow flex-col justify-between overflow-auto">
      <ScrollContainer direction="vertical">
        <div className="flex">
          <Table
            columnWidths={projectStatsGridWidth(orgs.length, isGroupsFeature, isLeadsFeature)}
            entries={entries}
            headerContent={[
              ...selectAllHeader,
              ...projectStatsHeaderContent(orgs, isGroupsFeature, isLeadsFeature),
            ]}
            loading={loading}
            rowHeight={gridRowHeight}
            sortManager={sortManager}
          />
        </div>
      </ScrollContainer>
      {selectedProjects.size ? (
        <div className="flex w-full flex-row items-center bg-background-primary py-4 pr-4 shadow-upwards type-heading2">
          <div style={{ marginLeft: 'auto' }}>
            {pluralizeCountString('project', selectedProjects.size)} selected
          </div>
          <Button label="Clear" onClick={onClearProjectSelection} type="tertiary" />
          <Button label="Edit selected projects" onClick={onBulkEditProjects} type="primary" />
        </div>
      ) : null}
      <BulkEditProjectStatsDialog
        collaboratorsByProject={allProjectCollaborators}
        companyID={companyID}
        companyProjectRoles={companyProjectRoles}
        isOpen={bulkEditDialogOpen}
        onClose={() => {
          setBulkEditDialogOpen(false);
          onClearProjectSelection();
        }}
        orgs={orgs}
        projectTypes={projectTypes}
        selectedProjectDetails={selectedProjects}
      />
      <AddLeadToProjectDialog
        isOpen={inviteLeadToProjDialogOpen !== null}
        leadAddDetails={inviteLeadToProjDialogOpen}
        onAddProjectCollaborator={onAddProjectCollaborator}
        onClose={() => {
          setInviteLeadToProjDialogOpen(null);
        }}
      />
      {companyRoles && company && addEmployeeDialogProjID && (
        <DialogsAddCompanyEmployee
          company={company}
          companyProjectRoles={companyProjectRoles}
          companyRoles={companyRoles}
          companyUsers={activeUsers}
          onCreateEmployee={(user, projectNames) => onCreateNewEmployee(user, projectNames)}
          open={addEmployeeDialogProjID !== null}
          selectedProjectID={addEmployeeDialogProjID}
          setDialogOpen={(open: boolean) => {
            if (!open) setAddEmployeeDialogProjID(null);
          }}
        />
      )}
    </div>
  );
};

export default withStyles(CompanyTabStyles)(CompanyTabProjectStatsTable);

const computeProjectCollaborators = (
  projectCollaborators: ProjectUserDetails[],
  companyUsers: CompanyUser[],
  currentProjectleadID: string | undefined
): SelectEntry[] => {
  const projectTeam: SelectEntry = {
    id: '1',
    isSection: true,
    label: 'Project Team',
    entries: [],
  };
  const otherEmployees: SelectEntry = {
    id: '2',
    isSection: true,
    label: 'Other Employees',
    entries: [],
  };
  // Loop through all company users and seperate out users from current project and other users
  companyUsers.forEach((cu) => {
    const collaborator = projectCollaborators.find((c) => c.userID === cu.user?.id);
    const deactivatedUser = cu.user?.status === UserStatus.DEACTIVATED;
    const currentLeadDeactivated = deactivatedUser && cu.user?.id === currentProjectleadID;
    const userEntry = {
      id: cu.user?.id || '',
      label: `${cu.user?.name || ''}${deactivatedUser ? ' (Deactivated)' : ''}`,
      startAdornment: <UserAvatar assignee={cu.user} />,
    };

    if (collaborator) {
      if (currentLeadDeactivated) {
        // Only display a deactivated user if they are a currently assigned as a project lead
        projectTeam.entries.push({ ...userEntry, disabled: true });
      } else if (!deactivatedUser) {
        projectTeam.entries.push(userEntry);
      }
    } else if (!deactivatedUser) {
      otherEmployees.entries.push(userEntry);
    }
  });

  // Filter out sections with no entries
  const allEntries: SelectEntry[] = [projectTeam, otherEmployees].filter(
    (section) => section.entries.length > 0
  );
  // Add Add New Employee section at start of the list
  const addEmployee: SelectEntry = {
    id: ADD_NEW_EMPLOYEE,
    label: ADD_NEW_EMPLOYEE,
    startAdornment: <Add />,
    isSection: false,
  };
  const unassignedEntry: SelectEntry = {
    id: UNASSIGNED,
    label: UNASSIGNED,
    startAdornment: <EmptyThumbnail />,
    isSection: false,
  };
  allEntries.unshift(addEmployee, unassignedEntry);
  return allEntries;
};
