import { extent } from 'd3-array';

import { EventType, Status, StatusEventsQuery } from '../../generated/graphql';
import { isNonNullable } from '../../utilities/types';
import {
  ItemDueState,
  TimelineItemData,
} from '../shared-widgets/TimelineChart/timeline/timeline.types';

export type StatusEvent = StatusEventsQuery['costTrendlineEvents']['events'][number];

export const getDueDateState = (item: ItemsTimelineItem, today: string) => {
  const dueDate = new Date(item.dueDate || '0');
  const todayDate = new Date(today);
  todayDate.setDate(todayDate.getDate() - 1);
  let status = ItemDueState.Upcoming;
  if (dueDate < todayDate && item.status === Status.PENDING) {
    status = ItemDueState.PastDue;
  } else if (
    item.status === Status.ACCEPTED ||
    item.status === Status.REJECTED ||
    item.status === Status.INCORPORATED ||
    item.status === Status.NOTAPPLICABLE
  ) {
    status = ItemDueState.Decided;
  }
  return status;
};

export const getDueDateStateNoDue = (
  item: ItemsTimelineItem,
  event: StatusEvent | undefined,
  today: string
) => {
  const todayDate = new Date(today);
  todayDate.setDate(todayDate.getDate() - 1);
  let status;
  if (event) {
    const { oldStatus, newStatus } = event.eventContent;
    // Past Bars
    if (
      // pending to anything
      oldStatus === Status.PENDING ||
      // accepted to rejected
      (oldStatus === Status.ACCEPTED && newStatus === Status.REJECTED) ||
      // rejected to accepted
      (oldStatus === Status.REJECTED && newStatus === Status.ACCEPTED)
    ) {
      status = ItemDueState.Decided;
    }

    // accepted to incorporated
    if (oldStatus === Status.ACCEPTED && newStatus === Status.INCORPORATED) {
      // no count
      status = null;
    }
  } else if (status === undefined) {
    status = getDueDateState(item, today);
  }
  return status;
};

export const computeItemDecidedDate = (
  list: ItemsTimelineItem[],
  today: string,
  eventsData: StatusEventsQuery | undefined
): TimelineItemData[] => {
  // Filter by CHANGE_STATUS event type
  const statusChangeEvents = (eventsData?.costTrendlineEvents?.events || []).filter(
    ({ eventType }) => eventType === EventType.CHANGE_STATUS
  );
  return list
    .map((item: ItemsTimelineItem) => {
      // We are interested in the most recent event
      const event = statusChangeEvents.reverse().find((event) => event.item?.id);
      const dueState = getDueDateStateNoDue(item, event, today);
      // if there is no state
      if (!dueState) return null;
      // if there is no date
      if (!(event?.timestamp || item.dueDate)) return null;
      return {
        id: item.id,
        name: item.name,
        dueDate: event?.timestamp || item.dueDate || '0',
        dueState,
      };
    })
    .filter(isNonNullable);
};

type TimeDate = {
  date: Date;
};

/** Moves an array of time series to today's date */
export function fixMocksToday<T extends TimeDate>(data: T[], delta: number) {
  return data.map((d) => ({ ...d, date: new Date(d.date.getTime() + delta) }));
}

export function getRange<T extends TimeDate>(data: T[]): [Date, Date] {
  const set = data.map((d) => d.date);
  const dateRange = extent(set);
  return dateRange as [Date, Date];
}
