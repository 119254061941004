import { ContingenciesFields, ContingenciesTimeSeries } from './types';

type AreaData = { x: Date; y0: number; y: number };
type Data = { date: Date; value: number };

export const insertAreaAfter = (input: AreaData[]) => {
  const output = input.reduce((arr: AreaData[], item, i) => {
    arr.push(item);
    const itemNext = { ...item };
    const next = input[i + 1];
    if (next) {
      itemNext.x = next.x;
      arr.push(itemNext);
    }
    return arr;
  }, []);
  return output;
};

// Top line remaining. Bottom line remaining - pending
export const getAreaData = (data: ContingenciesTimeSeries[], cOrA: 'contingency' | 'allowances') =>
  insertAreaAfter(
    data.map((d) => ({
      x: d.date,
      y0: d[cOrA][ContingenciesFields.remaining] - d[cOrA][ContingenciesFields.pending],
      y: d[cOrA][ContingenciesFields.remaining],
    }))
  );

export const insertAfter = (input: Data[]) => {
  const output = input.reduce((arr: Data[], item, i) => {
    arr.push(item);
    const itemNext = { ...item };
    const next = input[i + 1];
    if (next) {
      itemNext.date = next.date;
      arr.push(itemNext);
    }
    return arr;
  }, []);
  output.push(input[input.length - 1]);
  return output;
};

export const getFieldData = (
  data: ContingenciesTimeSeries[],
  field: ContingenciesFields,
  cOrA: 'contingency' | 'allowances'
) =>
  insertAfter(
    data.map((d) => ({
      date: d.date,
      value: d[cOrA][field],
    }))
  );

export const getYDomain = (
  data: ContingenciesTimeSeries[],
  hasAllowance: boolean,
  hasContingency: boolean
): [number, number] => {
  // Helper function to extract values
  const extractFields = (type: 'contingency' | 'allowances') => {
    return data
      .map((d) => [
        d[type]?.[ContingenciesFields.remaining],
        d[type]?.[ContingenciesFields.remaining] - d[type]?.[ContingenciesFields.pending],
        d[type]?.[ContingenciesFields.total],
      ])
      .flat();
  };
  let common: number[] = [];
  if (hasContingency) {
    common = [...common, ...extractFields('contingency')];
  }
  if (hasAllowance) {
    common = [...common, ...extractFields('allowances')];
  }
  return [Math.min(...common) ?? 0, Math.max(...common) ?? 0];
};
