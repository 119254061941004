import { useState } from 'react';

import {
  COMPANY_PROJECT_DELIVERY_METHOD,
  COMPANY_PROJECT_DESIGN_PHASE,
  COMPANY_PROJECT_LEAD,
  COMPANY_PROJECT_STATS_ORGANIZATION,
  FILTER_DELIVERY_METHOD,
  FILTER_DESIGN_PHASE,
  FILTER_PROJECT_COMPANIES,
  FILTER_PROJECT_COSTS,
  FILTER_PROJECT_LEAD,
  FILTER_PROJECT_LOCATION,
  FILTER_PROJECT_ORGANIZATION,
  FILTER_PROJECT_SIZE,
  FILTER_PROJECT_STATUS,
  FILTER_PROJECT_TYPE,
  NULL_ID,
  PROJECT_COMPANIES,
  PROJECT_LOCATION,
  PROJECT_SEARCH_HEADER,
  PROJECT_STATUS,
  PROJECT_TYPE,
  SEARCH_PROJECT,
} from '../../constants';
import { OrgNode, ProjectType } from '../../generated/graphql';
import { pluralizeCountString } from '../../utilities/string';
import { isNonNullable } from '../../utilities/types';
import { idsToNames } from '../../utilities/utilities';
import { MAGNITUDE_LIMIT } from '../Inputs/QuantityTextField/QuantityTextField';

export const calculateNumberOfFiltersToShow = (
  selected: string[],
  allowedSpace = 48,
  chipOverHead = 2
) => {
  let numberToShow = 0;
  let spaceUsed = 0;
  selected.forEach((s) => {
    spaceUsed += chipOverHead + Math.min(s.length, 20);
    if (spaceUsed > allowedSpace) return;
    numberToShow += 1;
  });
  return numberToShow;
};

const PROJECT_FILTER_TYPES = [
  FILTER_PROJECT_COMPANIES,
  FILTER_PROJECT_LOCATION,
  FILTER_PROJECT_STATUS,
  FILTER_PROJECT_TYPE,
  FILTER_PROJECT_LEAD,
  FILTER_PROJECT_ORGANIZATION,
  FILTER_PROJECT_COSTS,
  FILTER_PROJECT_SIZE,
  FILTER_DESIGN_PHASE,
  FILTER_DELIVERY_METHOD,
] as const;

const RANGE_MIN_VALUE = -1;
const RANGE_MAX_VALUE = MAGNITUDE_LIMIT;
const RANGE_MIN_CONST = 'RANGE_MIN';
const RANGE_MAX_CONST = 'RANGE_MAX';

type ProjectFilterType = (typeof PROJECT_FILTER_TYPES)[number];

export type ProjectFilter = {
  value:
    | string
    | {
        max: number | null;
        min: number | null;
      };
  values?: string[];
  type: string;
};

type ProjectFilterOptions = {
  companies: string[];
  locations: string[];
  statuses: string[];
  types: string[] | ProjectType[];
  organizationNodes: OrgNode[];
  projectLeads: { id: UUID; name: string }[];
  estimateCostRange?: {
    max: number | null;
    min: number | null;
  };
  gsfRange?: {
    max: number | null;
    min: number | null;
  };
  designPhases?: string[];
  deliveryMethods?: string[];
};

export type ProjectFilterState = {
  companies: string[];
  locations: string[];
  statuses: string[];
  types: string[];
  orgNodeIDs: UUID[];
  projectLeadIDs: UUID[];
  estimateCostRange: {
    max: number | null;
    min: number | null;
  };
  gsfRange: {
    max: number | null;
    min: number | null;
  };
  designPhases: string[];
  deliveryMethods: string[];
};

export type ProjectFilterSetSettings = (
  settings: Record<
    string,
    | string[]
    | {
        max: number | null;
        min: number | null;
      }
  >
) => void;

export type ProjectFilterManager = {
  clearFilters: (setSettings: ProjectFilterSetSettings) => void;
  clearFilterType: (type: string, setSettings: ProjectFilterSetSettings) => void;
  filterOptions: ProjectFilterOptions | undefined;
  filterState: ProjectFilterState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setFilter: (input: ProjectFilter, setSettings: ProjectFilterSetSettings, page?: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setFilters: (input: ProjectFilter, setSettings: ProjectFilterSetSettings, page?: any) => void;
};

export type ProjectSearchManager = {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  clearSearch: () => void;
};

const getEmptyFilterValue = (filterType: string) => {
  switch (filterType) {
    case FILTER_PROJECT_TYPE:
      return { types: [] };
    case FILTER_PROJECT_ORGANIZATION:
      return { orgNodeIDs: [] };
    case FILTER_PROJECT_LEAD:
      return { projectLeadIDs: [] };
    case FILTER_PROJECT_LOCATION:
      return { locations: [] };
    case FILTER_PROJECT_COMPANIES:
      return { companies: [] };
    case FILTER_PROJECT_STATUS:
      return { statuses: [] };
    case FILTER_PROJECT_COSTS:
      return {
        estimateCostRange: {
          max: null,
          min: null,
        },
      };
    case FILTER_PROJECT_SIZE:
      return {
        gsfRange: {
          max: null,
          min: null,
        },
      };
    case FILTER_DESIGN_PHASE:
      return { designPhases: [] };
    case FILTER_DELIVERY_METHOD:
      return { deliveryMethods: [] };
    default:
      return {};
  }
};

const getEmptyFilter = (filterState: ProjectFilterState, filterType: string) => ({
  ...filterState,
  ...getEmptyFilterValue(filterType),
});

const getEmptyFilterState = (): ProjectFilterState => ({
  types: [],
  locations: [],
  companies: [],
  statuses: [],
  orgNodeIDs: [],
  projectLeadIDs: [],
  estimateCostRange: {
    max: null,
    min: null,
  },
  gsfRange: {
    max: null,
    min: null,
  },
  designPhases: [],
  deliveryMethods: [],
});

export const projectFilterStateNumFilters = (
  filters: ProjectFilterState | null,
  searchTerm: string
) =>
  (filters?.types?.length || 0) +
  (filters?.orgNodeIDs?.length || 0) +
  (filters?.companies?.length || 0) +
  (filters?.locations?.length || 0) +
  (filters?.statuses?.length || 0) +
  (filters?.projectLeadIDs?.length || 0) +
  (filters?.designPhases?.length || 0) +
  (filters?.deliveryMethods?.length || 0) +
  (filters?.gsfRange?.min != null || filters?.gsfRange?.max != null ? 1 : 0) +
  (filters?.estimateCostRange?.min != null || filters?.estimateCostRange?.max != null ? 1 : 0) +
  (searchTerm !== '' ? 1 : 0);

export const projectFilterStateHasFilters = (filters: ProjectFilterState, searchTerm = '') =>
  !!filters.types.length ||
  !!filters.orgNodeIDs?.length ||
  !!filters.companies.length ||
  !!filters.locations.length ||
  !!filters.statuses.length ||
  !!filters.projectLeadIDs.length ||
  filters.gsfRange?.min !== null ||
  filters.gsfRange?.max !== null ||
  filters.estimateCostRange?.min !== null ||
  filters.estimateCostRange?.max !== null ||
  searchTerm !== '';

export const getSelectedForFilterType = (
  filterManager: ProjectFilterManager,
  filterType: string
): [string[], string[] | undefined] => {
  switch (filterType) {
    case FILTER_PROJECT_TYPE:
      return [filterManager.filterState.types, undefined];
    case FILTER_PROJECT_ORGANIZATION: {
      const options = filterManager.filterOptions?.organizationNodes ?? [];
      const names = filterManager.filterState.orgNodeIDs
        .map((id) => options.find((option) => option.id === id)?.name)
        .filter(isNonNullable);
      return [filterManager.filterState.orgNodeIDs, names];
    }
    case FILTER_PROJECT_LOCATION:
      return [filterManager.filterState.locations, undefined];
    case FILTER_PROJECT_STATUS:
      return [filterManager.filterState.statuses, undefined];
    case FILTER_PROJECT_COMPANIES:
      return [filterManager.filterState.companies, undefined];
    case FILTER_PROJECT_LEAD: {
      const options = [
        { id: NULL_ID, name: 'Unassigned' },
        ...(filterManager.filterOptions?.projectLeads ?? []),
      ];
      const names = filterManager.filterState.projectLeadIDs
        .map((id) => options.find((option) => option.id === id)?.name)
        .filter(isNonNullable);
      return [filterManager.filterState.projectLeadIDs, names];
    }
    case FILTER_DESIGN_PHASE:
      return [filterManager.filterState.designPhases, undefined];
    case FILTER_DELIVERY_METHOD:
      return [filterManager.filterState.deliveryMethods, undefined];
    default:
      return [[], undefined];
  }
};

export const getOptionsForFilterType = (
  filterManager: ProjectFilterManager,
  filterType: string
) => {
  const {
    filterOptions = {
      locations: [],
      statuses: [],
      companies: [],
      types: [],
      organizationNodes: [],
      projectLeads: [],
      estimateCostRange: {
        max: null,
        min: null,
      },
      gsfRange: {
        max: null,
        min: null,
      },
      designPhases: [],
      deliveryMethods: [],
    },
  } = filterManager;
  switch (filterType) {
    case FILTER_PROJECT_TYPE:
      return filterOptions.types;
    case FILTER_PROJECT_ORGANIZATION:
      return filterOptions.organizationNodes.map(({ id }) => id);
    case FILTER_PROJECT_LOCATION:
      return filterOptions.locations;
    case FILTER_PROJECT_STATUS:
      return filterOptions.statuses;
    case FILTER_PROJECT_COMPANIES:
      return filterOptions.companies;
    case FILTER_PROJECT_LEAD:
      return [NULL_ID, ...filterOptions.projectLeads.map(({ id }) => id)];
    case FILTER_PROJECT_COSTS:
      return filterOptions.estimateCostRange;
    case FILTER_PROJECT_SIZE:
      return filterOptions.gsfRange;
    case FILTER_DESIGN_PHASE:
      return filterOptions.designPhases;
    case FILTER_DELIVERY_METHOD:
      return filterOptions.deliveryMethods;
    default:
      return [];
  }
};

export const getLabelsForFilterType = (filterManager: ProjectFilterManager, filterType: string) => {
  switch (filterType) {
    case FILTER_PROJECT_LEAD:
      return [
        'Unassigned',
        ...(filterManager.filterOptions?.projectLeads.map(({ name }) => name) ?? []),
      ];
    default:
      return undefined;
  }
};

export const getTitleForFilterType = (filterType: string) => {
  switch (filterType) {
    case FILTER_PROJECT_TYPE:
      return PROJECT_TYPE;
    case FILTER_PROJECT_LOCATION:
      return PROJECT_LOCATION;
    case FILTER_PROJECT_COMPANIES:
      return PROJECT_COMPANIES;
    case FILTER_PROJECT_STATUS:
      return PROJECT_STATUS;
    case SEARCH_PROJECT:
      return PROJECT_SEARCH_HEADER;
    case FILTER_PROJECT_ORGANIZATION:
      return COMPANY_PROJECT_STATS_ORGANIZATION;
    case FILTER_PROJECT_LEAD:
      return COMPANY_PROJECT_LEAD;
    case FILTER_DESIGN_PHASE:
      return COMPANY_PROJECT_DESIGN_PHASE;
    case FILTER_DELIVERY_METHOD:
      return COMPANY_PROJECT_DELIVERY_METHOD;
    default:
      return '';
  }
};

// FILTER FUNCTIONALITY
const toggleString = (arr: string[], input: string) => {
  const hasFilter = arr.some((str) => str.toLowerCase() === input.toLowerCase());
  if (hasFilter) return arr.filter((str) => str.toLowerCase() !== input.toLowerCase());
  return [...arr, input];
};

const toggleFilter = (
  filterState: ProjectFilterState,
  input: ProjectFilter
): ProjectFilterState => {
  switch (input.type) {
    case FILTER_PROJECT_TYPE:
      return {
        ...filterState,
        types: toggleString(filterState.types, input.value as string),
      };
    case FILTER_PROJECT_LEAD:
      return {
        ...filterState,
        projectLeadIDs: toggleString(filterState.projectLeadIDs, input.value as string),
      };
    case FILTER_PROJECT_ORGANIZATION:
      return {
        ...filterState,
        orgNodeIDs: toggleString(filterState.orgNodeIDs, input.value as string),
      };
    case FILTER_PROJECT_LOCATION:
      return {
        ...filterState,
        locations: toggleString(filterState.locations, input.value as string),
      };
    case FILTER_PROJECT_STATUS:
      return {
        ...filterState,
        statuses: toggleString(filterState.statuses, input.value as string),
      };
    case FILTER_PROJECT_COMPANIES:
      return {
        ...filterState,
        companies: toggleString(filterState.companies, input.value as string),
      };
    case FILTER_PROJECT_COSTS: {
      const costRange = input.value as { max: number | null; min: number | null };
      return {
        ...filterState,
        estimateCostRange: { max: costRange.max, min: costRange.min },
      };
    }
    case FILTER_PROJECT_SIZE: {
      const sizeRange = input.value as { max: number | null; min: number | null };
      return {
        ...filterState,
        gsfRange: { max: sizeRange.max, min: sizeRange.min },
      };
    }
    case FILTER_DESIGN_PHASE:
      return {
        ...filterState,
        designPhases: toggleString(filterState.designPhases, input.value as string),
      };
    case FILTER_DELIVERY_METHOD:
      return {
        ...filterState,
        deliveryMethods: toggleString(filterState.deliveryMethods, input.value as string),
      };
    default:
      return filterState;
  }
};

const setStrings = (input: string[]) => [...input];

const applyFilter = (filterState: ProjectFilterState, input: ProjectFilter): ProjectFilterState => {
  switch (input.type) {
    case FILTER_PROJECT_TYPE:
      return {
        ...filterState,
        types: setStrings(input.values ?? []),
      };
    case FILTER_PROJECT_ORGANIZATION:
      return {
        ...filterState,
        orgNodeIDs: setStrings(input.values ?? []),
      };
    case FILTER_PROJECT_LEAD:
      return {
        ...filterState,
        projectLeadIDs: setStrings(input.values ?? []),
      };
    case FILTER_PROJECT_COSTS: {
      const costRange = input.value as { max: number | null; min: number | null };
      return {
        ...filterState,
        estimateCostRange: { max: costRange.max, min: costRange.min },
      };
    }
    case FILTER_PROJECT_SIZE: {
      const sizeRange = input.value as { max: number | null; min: number | null };
      return {
        ...filterState,
        gsfRange: { max: sizeRange.max, min: sizeRange.min },
      };
    }
    default:
      return filterState;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const getEmptyProjectRangeFilterKeys = (settings: any) => {
  const keys = Object.keys(settings);
  return (keys || []).filter((key) => {
    const val = settings[key];
    if (val.length === 2)
      return (
        (val[0] === RANGE_MIN_VALUE || val[0] === RANGE_MIN_CONST) &&
        (val[1] === RANGE_MAX_VALUE || val[1] === RANGE_MAX_CONST)
      );
    return false;
  });
};

// FILTER SUMMARY

type FilterCollapseState = Record<ProjectFilterType, boolean>;

export const generateFilterTooltipCopy = (
  filterState: ProjectFilterState,
  projectTypes: ProjectType[]
): string[] => {
  const { types: typesOuter, locations, companies, statuses } = filterState;
  const types = idsToNames([...typesOuter], projectTypes || []);
  const copy = [...statuses, ...types, ...locations, ...companies];
  return copy;
};

const mapTypeToSelected = (
  filterState: ProjectFilterState
): {
  type: ProjectFilterType;
  selected: string[];
}[] => {
  const {
    types,
    locations,
    companies,
    statuses,
    orgNodeIDs,
    projectLeadIDs,
    designPhases,
    deliveryMethods,
  } = filterState;
  return [
    { type: FILTER_PROJECT_TYPE, selected: types },
    { type: FILTER_PROJECT_LOCATION, selected: locations },
    { type: FILTER_PROJECT_COMPANIES, selected: companies },
    { type: FILTER_PROJECT_STATUS, selected: statuses },
    { type: FILTER_PROJECT_LEAD, selected: projectLeadIDs },
    { type: FILTER_PROJECT_ORGANIZATION, selected: orgNodeIDs },
    { type: FILTER_DESIGN_PHASE, selected: designPhases },
    { type: FILTER_DELIVERY_METHOD, selected: deliveryMethods },
  ];
};

const getCollapsedString = (filterState: ProjectFilterState, filterType: ProjectFilterType) => {
  const { types, locations, companies, statuses, projectLeadIDs, orgNodeIDs } = filterState;
  switch (filterType) {
    case FILTER_PROJECT_TYPE:
      return pluralizeCountString('Project type', types.length);
    case FILTER_PROJECT_LOCATION:
      return pluralizeCountString('Location', locations.length);
    case FILTER_PROJECT_COMPANIES:
      return pluralizeCountString('Company filter', companies.length);
    case FILTER_PROJECT_STATUS:
      return pluralizeCountString('Status', statuses.length);
    case FILTER_PROJECT_LEAD:
      return pluralizeCountString('Project lead', projectLeadIDs.length);
    case FILTER_PROJECT_ORGANIZATION:
      return pluralizeCountString('Organization', orgNodeIDs.length);
    default:
      return '';
  }
};

const formatFilterLabel = (text: string, includeLabel: boolean) =>
  includeLabel ? `${text}: ` : '';

const getSummaryStringForFilterType = (
  filterManager: ProjectFilterManager,
  filterType: ProjectFilterType,
  includeLabels: boolean
) => {
  const [selected, selectedLabels] = getSelectedForFilterType(filterManager, filterType);
  switch (filterType) {
    case FILTER_PROJECT_TYPE: {
      const label = formatFilterLabel(PROJECT_TYPE, includeLabels);
      return selected.length > 0 ? label + selected.join(', ') : '';
    }
    case FILTER_PROJECT_LOCATION: {
      const label = formatFilterLabel(PROJECT_LOCATION, includeLabels);
      return selected.length > 0 ? label + selected.join(', ') : '';
    }
    case FILTER_PROJECT_STATUS: {
      const label = formatFilterLabel(PROJECT_STATUS, includeLabels);
      return selected.length > 0 ? label + selected.join(', ') : '';
    }
    case FILTER_PROJECT_COMPANIES:
      return selected.length > 0 ? selected.join(', ') : '';
    case FILTER_PROJECT_LEAD: {
      const label = formatFilterLabel(COMPANY_PROJECT_LEAD, includeLabels);
      return selectedLabels && selectedLabels?.length > 0 ? label + selectedLabels.join(', ') : '';
    }
    case FILTER_PROJECT_ORGANIZATION: {
      const label = formatFilterLabel(COMPANY_PROJECT_STATS_ORGANIZATION, includeLabels);
      return selectedLabels && selectedLabels.length > 0 ? label + selectedLabels.join(', ') : '';
    }
    case FILTER_DESIGN_PHASE: {
      const label = formatFilterLabel(COMPANY_PROJECT_DESIGN_PHASE, includeLabels);
      return selected?.length > 0 ? label + selected.join(', ') : '';
    }
    case FILTER_DELIVERY_METHOD: {
      const label = formatFilterLabel(COMPANY_PROJECT_DELIVERY_METHOD, includeLabels);
      return selected?.length > 0 ? label + selected.join(', ') : '';
    }
    default:
      return '';
  }
};

const filterIsCollapsed = (
  filterCollapseState: FilterCollapseState,
  filterType: ProjectFilterType
) => {
  return filterCollapseState[filterType];
};

const allFiltersCollapsed = (filterCollapseState: FilterCollapseState) => {
  const {
    FILTER_PROJECT_TYPE: typ,
    FILTER_PROJECT_LOCATION: location,
    FILTER_PROJECT_COMPANIES: companies,
    FILTER_PROJECT_LEAD: lead,
    FILTER_PROJECT_ORGANIZATION: org,
  } = filterCollapseState;
  return typ && location && companies && lead && org;
};

const setFilterCollapsed = (
  filterCollapseState: FilterCollapseState,
  filterType: ProjectFilterType
) => {
  const newCollapseState = filterCollapseState;
  newCollapseState[filterType] = true;
  return newCollapseState;
};

const getCurrentFilterSummaryString = (
  type: ProjectFilterType,
  filterManager: ProjectFilterManager,
  filterCollapseState: FilterCollapseState,
  includeLabels: boolean
) =>
  filterIsCollapsed(filterCollapseState, type)
    ? getCollapsedString(filterManager.filterState, type)
    : getSummaryStringForFilterType(filterManager, type, includeLabels);

const getFilterSummaryTotalLength = (
  filterManager: ProjectFilterManager,
  filterCollapseState: FilterCollapseState,
  includeLabels: boolean
) =>
  PROJECT_FILTER_TYPES.reduce(
    (previousValue, filterType) =>
      previousValue +
      getCurrentFilterSummaryString(filterType, filterManager, filterCollapseState, includeLabels)
        .length,
    0
  );

const setLongestFilterCollapsed = (
  listFilters: {
    type: ProjectFilterType;
    selected: string[];
  }[],
  filterCollapseState: FilterCollapseState
) => {
  const longestList = listFilters.reduce(
    (previousValue: (typeof listFilters)[number] | undefined, filter) =>
      !filterIsCollapsed(filterCollapseState, filter.type) &&
      filter.selected.length > (previousValue?.selected?.length ?? 0)
        ? filter
        : previousValue,
    undefined
  );
  if (!longestList) return filterCollapseState;
  return setFilterCollapsed(filterCollapseState, longestList.type);
};

const getFilterStrings = (
  filterManager: ProjectFilterManager,
  filterCollapseState: FilterCollapseState,
  isVerbose: boolean
) => {
  const [
    companiesString,
    locationString,
    statusString,
    typeString,
    leadString,
    orgString,
    projectCostsString,
    projectSizeString,
    designPhasesString,
    deliveryMethodsString,
  ] = PROJECT_FILTER_TYPES.map((filterType) =>
    getCurrentFilterSummaryString(filterType, filterManager, filterCollapseState, isVerbose)
  );

  const filterStrings = [];
  if (deliveryMethodsString !== '') filterStrings.push(deliveryMethodsString);
  if (designPhasesString !== '') filterStrings.push(designPhasesString);
  if (projectCostsString !== '') filterStrings.push(projectCostsString);
  if (projectSizeString !== '') filterStrings.push(projectSizeString);
  if (companiesString !== '') filterStrings.push(companiesString);
  if (locationString !== '') filterStrings.push(locationString);
  if (statusString !== '') filterStrings.push(statusString);
  if (typeString !== '') filterStrings.push(typeString);
  if (leadString !== '') filterStrings.push(leadString);
  if (orgString !== '') filterStrings.push(orgString);
  return filterStrings;
};

export const getFilterSummaryString = (
  filterManager: ProjectFilterManager,
  width: number,
  isVerbose: boolean
) => {
  const widthLimit = 1200;
  const charLimit = 70;

  const numFilters = projectFilterStateNumFilters(filterManager.filterState, '');
  if (width < widthLimit && numFilters > 0) return pluralizeCountString('filter', numFilters);

  const listFilters = mapTypeToSelected(filterManager.filterState);

  let filterCollapseState: FilterCollapseState = {
    FILTER_PROJECT_COMPANIES: false,
    FILTER_PROJECT_LOCATION: false,
    FILTER_PROJECT_STATUS: false,
    FILTER_PROJECT_TYPE: false,
    FILTER_PROJECT_LEAD: false,
    FILTER_PROJECT_ORGANIZATION: false,
    FILTER_PROJECT_COSTS: false,
    FILTER_PROJECT_SIZE: false,
    FILTER_DESIGN_PHASE: false,
    FILTER_DELIVERY_METHOD: false,
  };

  let totalLength = getFilterSummaryTotalLength(filterManager, filterCollapseState, isVerbose);
  while (totalLength > charLimit) {
    const newFilterCollapseState = setLongestFilterCollapsed(listFilters, filterCollapseState);
    filterCollapseState = newFilterCollapseState;
    totalLength = getFilterSummaryTotalLength(filterManager, filterCollapseState, isVerbose);
    if (allFiltersCollapsed(filterCollapseState)) break;
  }

  return getFilterStrings(filterManager, filterCollapseState, isVerbose);
};

export const useFilterProjects = (
  settings: ProjectFilterState,
  onSetFilter?: (input: ProjectFilter) => void,
  onClearFilters?: () => void,
  onClearFilterType?: () => void
): ProjectFilterManager => {
  // FILTER HOOKS
  const [filterState, setFilterState] = useState<ProjectFilterState>(settings);
  const setFilter = (input: ProjectFilter, setSettings: ProjectFilterSetSettings) => {
    const newFilter = toggleFilter(filterState, input);
    setFilterState(newFilter);
    setSettings(newFilter);
    onSetFilter?.(input);
  };
  const setFilters = (input: ProjectFilter, setSettings: ProjectFilterSetSettings) => {
    const newFilter = applyFilter(filterState, input);
    setFilterState(newFilter);
    setSettings(newFilter);
    onSetFilter?.(input);
  };
  const clearFilters = (setSettings: ProjectFilterSetSettings) => {
    const emptyFilterState = getEmptyFilterState();
    setFilterState(emptyFilterState);
    setSettings(emptyFilterState);
    onClearFilters?.();
  };
  const clearFilterType = (filterType: string, setSettings: ProjectFilterSetSettings) => {
    setFilterState(getEmptyFilter(filterState, filterType));
    setSettings(getEmptyFilter(filterState, filterType));
    onClearFilterType?.();
  };

  return {
    clearFilters,
    clearFilterType,
    filterState,
    filterOptions: undefined,
    setFilter,
    setFilters,
  };
};
