import { FC, useState } from 'react';

import { NS_PROGRAM_REPORTING } from '../../../features';
import { useHasFeature } from '../../../hooks/useHasFeature';
import ProjectSelection from '../../ProjectsList/ProjectSelection/ProjectSelection';
import { DialogFlow } from '../../scales';

type DialogsNewProjectProps = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
};

const DialogsProgram: FC<DialogsNewProjectProps> = ({ isOpen, setIsOpen }) => {
  const hasProgramReportingFeature = useHasFeature(NS_PROGRAM_REPORTING);

  const [selectedProjectIDs, setSelectedProjectIDs] = useState<UUID[]>([]);

  if (!hasProgramReportingFeature) return null;

  const projectSelection = (
    <ProjectSelection
      disableIfNotAdmin
      includeCompanyProjects={false}
      onChange={setSelectedProjectIDs}
      selectedProjectIDs={selectedProjectIDs}
      selectionMode="multiple"
    />
  );

  const steps = [{ title: 'New Program', renderContent: () => projectSelection }];

  const projectDialogFlow = (
    <DialogFlow
      isFullHeight
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onComplete={() => setIsOpen(false)}
      size="lg"
      steps={steps}
      title="Create Program"
    />
  );

  return <div>{projectDialogFlow}</div>;
};

export default DialogsProgram;
