import { useMemo, useState } from 'react';

import { Info } from '@material-ui/icons';

import { REFETCH_COMPANY_STATS } from '../../../api/refetchSets';
import { UNASSIGNED } from '../../../constants';
import { YC_PROJ_LEAD } from '../../../features';
import { CollaboratorInput, Org, ProjectType } from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { pluralizeCountString, pluralizeString } from '../../../utilities/string';
import { useCreateCollaborators } from '../../Collaborators/hooks';
import { useRemoveProjectsLead, useSetProjectsLead } from '../../Collaborators/hooks/projectLead';
import { useSetBulkProjectOrgs } from '../../Organizations/hooks/hooks';
import { Button, Callout, Dialog, DialogContent, Select, Tooltip } from '../../scales';
import useMemoWrapper from '../../useMemoWrapper';
import { useUpdateBulkCompanyProjectTypeMutation } from '../CompanyHooks';
import OrgSelector from '../CompanyTabOrganizations/OrgSelector/OrgSelector';

import {
  ProjectCollaboratorsMap,
  SelectedProjectDetails,
  findUniqueOrgs,
  findUniqueProjectTypes,
  getCommonProjectLeadID,
} from './BulkEditProjectStatsUtils';
import { useLeadsEntries } from './Hooks/useLeadsEntries';

export type Props = {
  collaboratorsByProject: ProjectCollaboratorsMap;
  companyID: string;
  companyProjectRoles: CompanyProjectRoles[];
  isOpen: boolean;
  onClose: () => void;
  orgs: Org[];
  projectTypes: ProjectType[];
  selectedProjectDetails: SelectedProjectDetails;
};

export default function BulkEditProjectStatsDialog(props: Props) {
  const projectIDs = Array.from(props.selectedProjectDetails.keys());
  // Project Types
  const [newProjectTypeID, setNewProjectTypeID] = useState<string | null>(null);
  const setBulkProjectType = useUpdateBulkCompanyProjectTypeMutation(props.companyID);
  const uniqueInitialProjectTypes = useMemoWrapper(
    findUniqueProjectTypes,
    props.selectedProjectDetails
  );

  const singleUniqueInitialProjectTypeID =
    uniqueInitialProjectTypes && uniqueInitialProjectTypes.length === 1
      ? uniqueInitialProjectTypes[0].id
      : ''; // If project types are not similar then dont display anything in the selector.
  const projectTypeValueToDisplay = newProjectTypeID || singleUniqueInitialProjectTypeID;

  // Orgs
  const [selectedOrgNodeIDs, setSelectedOrgNodeIDs] = useState<string[] | null>(null);
  const setBulkProjectOrgs = useSetBulkProjectOrgs();
  const intialUniqueOrgNodes = useMemoWrapper(
    findUniqueOrgs,
    props.selectedProjectDetails,
    props.orgs
  );

  // Leads
  const isLeadsFeature = useHasFeature(YC_PROJ_LEAD);
  const projectLeadInitial = useMemoWrapper(getCommonProjectLeadID, props.selectedProjectDetails);
  const [setProjectsLead] = useSetProjectsLead();
  const [removeProjectsLead] = useRemoveProjectsLead();
  const [createCollaborators] = useCreateCollaborators(REFETCH_COMPANY_STATS);
  const [selectedProjectLead, setSelectedProjectLead] = useState<string | undefined>(
    projectLeadInitial
  );
  const [projectIDsForNewCollaborators, setProjectIDsForNewCollaborators] = useState<string[]>([]);
  useMemo(() => {
    setSelectedProjectLead(projectLeadInitial);
    return projectLeadInitial;
  }, [projectLeadInitial]);

  const projectNames = projectIDsForNewCollaborators
    .map((pID) => props.companyProjectRoles.find((pr) => pr.id === pID)?.name)
    .filter((projectName) => projectName !== undefined);

  const toolTipContent = (
    <div className="type-body3">
      <div>{`The user will be added to the following ${pluralizeString(
        'project',
        projectNames.length
      )}:`}</div>
      <div className="pl-2">
        <ul>
          {projectNames.map((projectName) => (
            <li key={projectName}>
              <div>- {projectName}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
  const projectCountText = (
    <Tooltip content={toolTipContent}>
      <div className="inline type-body3">
        {`${projectIDsForNewCollaborators.length} of ${projectIDs.length}`}
      </div>
    </Tooltip>
  );

  const { leadsEntries, companyUsers } = useLeadsEntries(props.companyID);
  const selectedLeadName = leadsEntries.find((l) => l.id === selectedProjectLead)?.label;

  const onSave = async () => {
    // Update Project Types
    if (newProjectTypeID) {
      setBulkProjectType(projectIDs, newProjectTypeID);
      setNewProjectTypeID(null);
    }

    // Update Project Orgs
    if (selectedOrgNodeIDs) {
      setBulkProjectOrgs(projectIDs, selectedOrgNodeIDs);
      setSelectedOrgNodeIDs(null);
    }

    // Project Leads
    if (projectLeadInitial !== selectedProjectLead && selectedProjectLead) {
      if (selectedProjectLead === UNASSIGNED) {
        // For Unnasigning leads
        await removeProjectsLead({
          variables: {
            projectIDs,
          },
          refetchQueries: REFETCH_COMPANY_STATS,
        });
      } else {
        const createCollaboratorMap = new Map<string, CollaboratorInput[]>(); // ProjectID: CollaboratorInput[]

        projectIDs.forEach((pId) => {
          const allCollaboratorsCurrentProject = props.collaboratorsByProject.get(pId);
          const collaborator = allCollaboratorsCurrentProject?.find(
            (c) => c.userID === selectedProjectLead
          );
          if (!collaborator) {
            const collaboratorToAdd = companyUsers.find(
              (cu) => cu?.user?.id === selectedProjectLead
            );
            if (collaboratorToAdd && collaboratorToAdd.user?.email) {
              const data = {
                userEmail: collaboratorToAdd.user?.email,
                allTrades: true,
                roleName: 'Administrator',
                responsibility: '',
                trades: [],
              };
              if (createCollaboratorMap.has(pId)) {
                createCollaboratorMap.get(pId)!.push(data);
              } else {
                createCollaboratorMap.set(pId, [data]);
              }
            }
          }
        });

        // If the map has entries, push them into the query to create the collaborators
        if (createCollaboratorMap.size > 0) {
          const entriesArray = Array.from(createCollaboratorMap.entries());
          await Promise.all(
            entriesArray.map(async ([projectID, collaborators]) => {
              await createCollaborators(projectID, collaborators, undefined, true);
            })
          );
        }

        // Finally set the project lead for all projects
        await setProjectsLead({
          variables: {
            projectIDs,
            userID: selectedProjectLead,
          },
          refetchQueries: REFETCH_COMPANY_STATS,
        });
      }
    }

    setSelectedProjectLead(undefined);
    setProjectIDsForNewCollaborators([]);
    props.onClose?.();
  };

  const onClose = () => {
    setSelectedOrgNodeIDs(null);
    setSelectedProjectLead(undefined);
    setNewProjectTypeID(null);
    setProjectIDsForNewCollaborators([]);
    props.onClose?.();
  };

  return (
    <Dialog
      footerRight={<Button label="Save Changes" onClick={onSave} type="primary" />}
      isOpen={props.isOpen}
      onClose={onClose}
      title={`Editing ${pluralizeCountString('project', projectIDs.length)}`}
    >
      <DialogContent>
        <div className="flex flex-col gap-3">
          <Select
            aria-label="project type"
            entries={props.projectTypes
              .filter((t) => !t.hidden)
              .map((t) => ({
                id: t.id,
                label: t.name,
                parentID: t.parentID,
              }))}
            isSearchable
            label="Project Type"
            onChange={(id) => {
              if (!id) return;
              setNewProjectTypeID(id);
            }}
            value={projectTypeValueToDisplay}
          />
          {isLeadsFeature && (
            <div className="flex flex-col gap-2">
              <Select
                aria-label="project lead"
                entries={leadsEntries}
                isSearchable
                label="Project Lead"
                onChange={(id) => {
                  if (!id) return;
                  setSelectedProjectLead(id);
                  const pIDsForNewCollaborators: string[] = [];
                  // Determine if user is attempting add a lead that is not a collab on a project
                  projectIDs.forEach((pId) => {
                    const allCollaboratorsCurrentProject = props.collaboratorsByProject.get(pId);
                    const collaborator = allCollaboratorsCurrentProject?.find(
                      (c) => c.userID === id
                    );
                    if (!collaborator && id !== UNASSIGNED) {
                      pIDsForNewCollaborators.push(pId);
                    }
                  });
                  setProjectIDsForNewCollaborators(pIDsForNewCollaborators);
                }}
                value={selectedProjectLead ?? undefined}
              />
              {projectIDsForNewCollaborators?.length > 0 && (
                <Callout fullWidth>
                  <div className="flex items-center gap-2">
                    <Info className="mb-0.5" fontSize="inherit" />
                    <div className="inline type-body3">
                      {projectIDs.length > 1 ? (
                        <>
                          This will add {selectedLeadName} to {projectCountText} projects. Assigning
                          them as project lead will also invite them as an administrator to these
                          projects.
                        </>
                      ) : (
                        <>
                          This will add {selectedLeadName} to the selected project. Assigning them
                          as project lead will also invite them as an administrator to this project.
                        </>
                      )}
                    </div>
                  </div>
                </Callout>
              )}
            </div>
          )}
          {props.orgs.length > 0 && (
            <>
              <OrgSelector
                isHiddenForNoOrgs
                label="Organizations"
                onChange={(orgNodeIDs: string[]) => {
                  setSelectedOrgNodeIDs(orgNodeIDs);
                }}
                orgNodes={intialUniqueOrgNodes}
                orgs={props.orgs}
              />
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
