import {
  JoinCompanyOrganizationsRoutes,
  JoinCompanyRoutes,
  JoinCompanyStandardsRoutes,
  JoinProjectRoutes,
  JoinRoutes,
} from '../api/gqlEnums';
import {
  BREAKDOWNS,
  COMPANY_CATEGORIZATIONS_PATH,
  COMPANY_COLLABORATORS_PATH,
  COMPANY_INSIGHTS_ALERTS_PATH,
  COMPANY_MEMBERS_PATH,
  COMPANY_ORGANIZATIONS_PATH,
  COMPANY_PROJECT_STATS_PATH,
  COMPANY_PROJECT_TEMPLATES_PATH,
  COMPANY_PROJECT_TYPES_PATH,
  COMPANY_SETTINGS_PATH,
  COMPANY_STANDARDS_PATH,
  COMPANY_TAB_PATH,
  CONTINGENCY_ACTIVE_MILESTONE_REPORT,
  CONTINGENCY_ALLOWANCE_REPORT,
  CONTINGENCY_ALL_MILESTONE_REPORT,
  COSTS,
  DETAILS,
  IMPORT,
  ITEMS,
  MILESTONES,
  PROJECT_COST_BREAKDOWN_REPORT,
  PROJECT_DASHBOARD_PATH,
  REPORTS,
  REPORT_DISTRIBUTION_FILE,
  REPORT_DISTRIBUTION_UNSUBSCRIBE,
  REPORT_ROUTER,
  SCENARIOS,
  SETTINGS,
  TIMELINE,
  TRENDING_COST_REPORT_PATH,
  VARIANCE,
} from '../constants';
import { FORECASTED_COST_REPORT, YC_GROUPS } from '../features';
import { PermissionResource } from '../generated/graphql';

/**
 * ================== 🚀🚀🚀 NEW STUFF! 🚀🚀🚀 ==================
 * You should probably consider doing any significant new work in
 * `routes/index.tsx` instead of in here. This file is our legacy way of adding
 * routes and anything added here is gonna need to be ported over. If you have
 * any questions, ask Mark or in #ask-engineering.
 * ================== 🚀🚀🚀 NEW STUFF! 🚀🚀🚀 ==================
 * */

/**
 * ================== 💀💀💀 LEGACY LEGACY LEGACY 💀💀💀 ==================
 * This is where we declare routing for the application. To add a route:
 * 1. Extend the appropriate enum based on where you want the route (top-level vs /:projectId/...
 *    vs /forecasting/... vs ...). This enables type safety.
 * 2. Add an entry into one of the **Routes objects in this file to define the `path`.
 * 3. Ensure that the appropriate route -> component map has an entry defining the appropriate
 *    `element` to render.
 *    Eg, routeToAppComponent, routeToProjectComponent, routeToForecastingComponent, etc...
 *
 * Now you can generate links to your route via generateSharedPath and the enum you defined in
 * the first step, eg generateSharedPath(RouteKeys.PROJECT_ITEMS], {projectId : 'PROJECT_ID'})
 *
 * TODO: It'd be great to move to using object-based routes with createBrowserRouter [1] but one of
 * the things standing in our way is the fact that steps 2 and 3 above are separate.
 * They're separate because we're trying to avoid cyclical dependency issues. The issue is that some
 * components used to define the `element` in step 3 import and use generateSharedPath(). That
 * function pulls the path from the objects in this file. As a result, if we were setting `element`
 * on the route objects in this file, we'd have a cyclical dependency because this file would then
 * need to import the components in step A. Ie,
 * JoinRoutes.tsx -> SomeRouteComponent.tsx -> generateSharedPath() [links.ts] -> JoinRoutes.tsx
 *
 * Even if we move generateSharedPath() into this file, the issue still exists:
 *   JoinRoutes.tsx -> SomeRouteComponent.tsx -> JoinRoutes.tsx
 *
 * A larger rethinking of how we generate paths in the app would be necessary to move the `element`
 * and `path` into the same object.
 * ================== 💀💀💀 LEGACY LEGACY LEGACY 💀💀💀 ==================
 */

const CompanyOrganizationsRoutes: {
  [key in JoinCompanyOrganizationsRoutes]: SharedRoute;
} = {
  [JoinCompanyOrganizationsRoutes.COMPANY_ORGANIZATION]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_ORGANIZATIONS_PATH}/:organizationId`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
  },
};

const CompanyStandardsRoutes: {
  [key in JoinCompanyStandardsRoutes]: SharedRoute;
} = {
  [JoinCompanyStandardsRoutes.COMPANY_CATEGORIZATIONS]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_STANDARDS_PATH}/${COMPANY_CATEGORIZATIONS_PATH}`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
  },
  [JoinCompanyStandardsRoutes.COMPANY_PROJECT_TEMPLATES]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_STANDARDS_PATH}/${COMPANY_PROJECT_TEMPLATES_PATH}`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
  },
  [JoinCompanyStandardsRoutes.COMPANY_PROJECT_TYPES]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_STANDARDS_PATH}/${COMPANY_PROJECT_TYPES_PATH}`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
  },
};

const CompanyRoutes: { [key in JoinCompanyRoutes]: SharedRoute } = {
  [JoinCompanyRoutes.COMPANY_MEMBERS]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_MEMBERS_PATH}`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
  },
  [JoinCompanyRoutes.COMPANY_COLLABORATORS]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_COLLABORATORS_PATH}`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
  },
  [JoinCompanyRoutes.COMPANY_INSIGHTS_ALERTS]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_INSIGHTS_ALERTS_PATH}`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
  },
  [JoinCompanyRoutes.COMPANY_SETTINGS]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_SETTINGS_PATH}`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
  },
  [JoinCompanyRoutes.COMPANY_MEMBERS_PROFILE]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_MEMBERS_PATH}/:userId`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
  },
  [JoinCompanyRoutes.COMPANY_COLLABORATORS_PROFILE]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_COLLABORATORS_PATH}/:userId`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
  },
  [JoinCompanyRoutes.COMPANY_PROJECT_STATS]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_PROJECT_STATS_PATH}`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
  },
  [JoinCompanyRoutes.COMPANY_ORGANIZATIONS]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_ORGANIZATIONS_PATH}`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
    featureFlag: YC_GROUPS,
    requireFeatureAndPermission: true,
    routes: CompanyOrganizationsRoutes,
  },
  [JoinCompanyRoutes.COMPANY_STANDARDS]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_STANDARDS_PATH}`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
    routes: CompanyStandardsRoutes,
  },
};

const PROJECT_ID_PATH = ':projectId';

const ProjectRoutes: { [key in JoinProjectRoutes]: SharedRoute } = {
  [JoinProjectRoutes.BREAKDOWNS]: {
    path: `/${PROJECT_ID_PATH}/print/${PROJECT_DASHBOARD_PATH}/${BREAKDOWNS}`,
    permissionResource: PermissionResource.DISPLAY_COSTS,
  },
  [JoinProjectRoutes.CONTINGENCY_ALL_MILESTONES_REPORT]: {
    path: `/${PROJECT_ID_PATH}/${CONTINGENCY_ALL_MILESTONE_REPORT}/`,
  },
  [JoinProjectRoutes.PROJECT_COST_BREAKDOWN_REPORT]: {
    path: `/${PROJECT_ID_PATH}/${MILESTONES}/:milestoneId/${PROJECT_COST_BREAKDOWN_REPORT}/`,
  },
  [JoinProjectRoutes.CONTINGENCY_ALLOWANCE_REPORT]: {
    path: `/${PROJECT_ID_PATH}/${CONTINGENCY_ALLOWANCE_REPORT}/`,
  },
  [JoinProjectRoutes.EXPORT_ACTIVE_MILESTONE_CONTINGENCY]: {
    path: `/${PROJECT_ID_PATH}/export/${CONTINGENCY_ACTIVE_MILESTONE_REPORT}/:reportID`,
    permissionResource: PermissionResource.DISPLAY_COSTS,
  },
  [JoinProjectRoutes.EXPORT_ALL_MILESTONES_CONTINGENCY]: {
    path: `/${PROJECT_ID_PATH}/export/${CONTINGENCY_ALL_MILESTONE_REPORT}/:reportID`,
    permissionResource: PermissionResource.DISPLAY_COSTS,
  },
  [JoinProjectRoutes.EXPORT_ITEMS_LIST]: {
    path: `/${PROJECT_ID_PATH}/export/${ITEMS}`,
  },
  [JoinProjectRoutes.EXPORT_MILESTONE_BUDGET]: {
    path: `/${PROJECT_ID_PATH}/export/${MILESTONES}/:milestoneId/budget/`,
    permissionResource: PermissionResource.DISPLAY_COSTS,
  },
  [JoinProjectRoutes.EXPORT_MILESTONE_ESTIMATE]: {
    path: `/${PROJECT_ID_PATH}/export/${MILESTONES}/:milestoneId/estimate/`,
    permissionResource: PermissionResource.DISPLAY_COSTS,
  },
  [JoinProjectRoutes.EXPORT_MSR]: {
    path: `/${PROJECT_ID_PATH}/export/${MILESTONES}/:milestoneId/${COSTS}/`,
    permissionResource: PermissionResource.DISPLAY_COSTS,
  },
  [JoinProjectRoutes.EXPORT_PROJECT_COST_BREAKDOWN_REPORT]: {
    path: `/${PROJECT_ID_PATH}/export/${MILESTONES}/:milestoneId/${PROJECT_COST_BREAKDOWN_REPORT}/`,
    permissionResource: PermissionResource.DISPLAY_COSTS,
  },
  [JoinProjectRoutes.EXPORT_VARIANCE]: {
    path: `/${PROJECT_ID_PATH}/export/variance/:reportID`,
    permissionResource: PermissionResource.DISPLAY_COSTS,
  },
  [JoinProjectRoutes.IMPORT_ESTIMATE]: {
    path: `/${PROJECT_ID_PATH}/${MILESTONES}/:milestoneId/${IMPORT}/:estimateId`,
    permissionResource: PermissionResource.DISPLAY_COSTS,
  },
  [JoinProjectRoutes.REPORT_DISTRIBUTION_FILE]: {
    path: `/${PROJECT_ID_PATH}/${REPORT_DISTRIBUTION_FILE}/:assetId`,
  },
  [JoinProjectRoutes.REPORT_DISTRIBUTION_UNSUBSCRIBE]: {
    path: `/${PROJECT_ID_PATH}/${REPORT_DISTRIBUTION_UNSUBSCRIBE}/:reportDistributionId`,
  },
  [JoinProjectRoutes.MILESTONE_DETAILS]: {
    path: `/${PROJECT_ID_PATH}/${MILESTONES}/:milestoneId`,
  },
  [JoinProjectRoutes.MILESTONES]: {
    path: `/${PROJECT_ID_PATH}/${MILESTONES}/`,
  },
  [JoinProjectRoutes.MSR]: {
    path: `/${PROJECT_ID_PATH}/${MILESTONES}/:milestoneId/${COSTS}/`,
  },
  [JoinProjectRoutes.PRINT_CONTINGENCY_ALL_MILESTONES_REPORT]: {
    path: `/${PROJECT_ID_PATH}/print/${CONTINGENCY_ALL_MILESTONE_REPORT}/`,
  },
  [JoinProjectRoutes.PRINT_CONTINGENCY_ALLOWANCE_REPORT]: {
    path: `/${PROJECT_ID_PATH}/print/${CONTINGENCY_ALLOWANCE_REPORT}/`,
    permissionResource: PermissionResource.DISPLAY_COSTS,
  },
  [JoinProjectRoutes.PRINT_ITEM_DETAILS]: {
    path: `/${PROJECT_ID_PATH}/print/${ITEMS}/:itemId`,
  },
  [JoinProjectRoutes.PRINT_ITEMS_LIST_DETAILS]: {
    path: `/${PROJECT_ID_PATH}/print/${DETAILS}`,
  },
  [JoinProjectRoutes.PRINT_ITEMS_LIST]: {
    path: `/${PROJECT_ID_PATH}/print/${ITEMS}`,
  },
  [JoinProjectRoutes.PRINT_MSR_SUMMARY]: {
    path: `/${PROJECT_ID_PATH}/print/${MILESTONES}/:milestoneId/${COSTS}/summary`,
    permissionResource: PermissionResource.DISPLAY_COSTS,
  },
  [JoinProjectRoutes.PRINT_MSR]: {
    path: `/${PROJECT_ID_PATH}/print/${MILESTONES}/:milestoneId/${COSTS}/`,
    permissionResource: PermissionResource.DISPLAY_COSTS,
  },
  [JoinProjectRoutes.PRINT_PROJECT_DASHBOARD_SUMMARY]: {
    path: `/${PROJECT_ID_PATH}/print/${PROJECT_DASHBOARD_PATH}/summary`,
    permissionResource: PermissionResource.DISPLAY_COSTS,
  },
  [JoinProjectRoutes.PRINT_PROJECT_DASHBOARD]: {
    path: `/${PROJECT_ID_PATH}/print/${PROJECT_DASHBOARD_PATH}/`,
    permissionResource: PermissionResource.DISPLAY_COSTS,
  },
  [JoinProjectRoutes.PRINT_PROJECT_COST_BREAKDOWN]: {
    path: `/${PROJECT_ID_PATH}/print/${MILESTONES}/:milestoneId/${PROJECT_COST_BREAKDOWN_REPORT}/`,
  },
  [JoinProjectRoutes.PRINT_REPORT_ROUTER]: {
    path: `/${PROJECT_ID_PATH}/print/${REPORT_ROUTER}/:reportID`,
  },
  [JoinProjectRoutes.PRINT_SCENARIOS]: {
    path: `/${PROJECT_ID_PATH}/print/${SCENARIOS}/`,
    permissionResource: PermissionResource.DISPLAY_COSTS,
  },
  [JoinProjectRoutes.PRINT_VARIANCE]: {
    path: `/${PROJECT_ID_PATH}/print/${MILESTONES}/${VARIANCE}/:reportID?`,
    permissionResource: PermissionResource.DISPLAY_COSTS,
  },
  [JoinProjectRoutes.PROJECT_DASHBOARD]: {
    path: `/${PROJECT_ID_PATH}/${PROJECT_DASHBOARD_PATH}/`,
    permissionResource: PermissionResource.DISPLAY_COSTS,
  },
  [JoinProjectRoutes.REPORT_ROUTER]: {
    path: `/${PROJECT_ID_PATH}/${REPORT_ROUTER}/:reportID`,
  },
  [JoinProjectRoutes.REPORTS]: {
    path: `/${PROJECT_ID_PATH}/${REPORTS}/`,
  },
  [JoinProjectRoutes.SCENARIOS]: {
    path: `/${PROJECT_ID_PATH}/${SCENARIOS}/`,
    permissionResource: PermissionResource.DISPLAY_COSTS,
  },
  [JoinProjectRoutes.SETTINGS]: {
    path: `/${PROJECT_ID_PATH}/${SETTINGS}`,
  },
  [JoinProjectRoutes.SIGNUP_CONFIRM_COMPANY]: {
    path: `/${PROJECT_ID_PATH}/sign-up/company/confirm/`,
  },
  [JoinProjectRoutes.SIGNUP_CREATE_COMPANY]: {
    path: `/${PROJECT_ID_PATH}/sign-up/company/create`,
  },
  [JoinProjectRoutes.SIGNUP_SETUP_USER]: {
    path: `/${PROJECT_ID_PATH}/sign-up/user/setup/`,
  },
  [JoinProjectRoutes.TIMELINE]: {
    path: `/${PROJECT_ID_PATH}/${TIMELINE}/`,
  },
  [JoinProjectRoutes.TRENDING_COST_REPORT]: {
    path: `/${PROJECT_ID_PATH}/${MILESTONES}/:milestoneId/${TRENDING_COST_REPORT_PATH}/`,
    featureFlag: FORECASTED_COST_REPORT,
  },
  [JoinProjectRoutes.VARIANCE]: {
    path: `/${PROJECT_ID_PATH}/${MILESTONES}/${VARIANCE}/`,
    permissionResource: PermissionResource.DISPLAY_COSTS,
  },
};

export const RootRoutes: { [key in JoinRoutes]: SharedRoute } = {
  [JoinRoutes.PROJECT]: {
    path: `/${PROJECT_ID_PATH}`,
    routes: ProjectRoutes,
  },
  [JoinRoutes.COMPANY_TAB]: {
    path: `/${COMPANY_TAB_PATH}`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
    routes: CompanyRoutes,
  },
};

export const AllRoutes = {
  ...CompanyRoutes,
  ...CompanyOrganizationsRoutes,
  ...CompanyStandardsRoutes,
  ...ProjectRoutes,
  ...RootRoutes,
};

export type GenericJoinRoutes = {
  [key in string]: SharedRoute;
};

export type SharedRoute = {
  path: string;
  featureFlag?: string;
  moduleEntitlementFlag?: string;
  permissionResource?: PermissionResource;
  requireFeatureAndPermission?: boolean;
  routes?: GenericJoinRoutes;
};
