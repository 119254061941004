// import { useParams } from 'react-router-dom';

import { useState } from 'react';

import ProgramDashboardHeader from './ProgramDashboardHeader';
import ProgramDashboardProjectSelection from './ProgramDashboardProjectSelection';

const projects: SearchProject[] = [
  {
    id: '1',
    name: 'Project 1',
    location: 'Location 1',
    type: 'Type 1',
    status: 'Status 1',
    milestoneName: 'Milestone 1',
    updatedBy: 'Updated By 1',
    thumbnail: 'Thumbnail 1',
  },
  {
    id: '2',
    name: 'Project 2',
    location: 'Location 2',
    type: 'Type 2',
    status: 'Status 2',
    milestoneName: 'Milestone 2',
    updatedBy: 'Updated By 2',
    thumbnail: 'Thumbnail 2',
  },
];

export default function ProgramDashboard() {
  // const { programID } = useParams();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedProjectIDs, setSelectedProjectIDs] = useState<string[]>([]);

  return (
    <div className="flex w-full flex-col gap-6 p-6">
      <div className="flex-grow type-giant">Program Dashboard</div>
      <ProgramDashboardHeader
        combinedBudget={2000}
        combinedEstimate={1000}
        combinedRunningTotal={3000}
        location="Location"
        numberOfProjects={5}
        programName="Program Name"
        programType="Program Type"
      />
      <ProgramDashboardProjectSelection
        isOpen={isOpen}
        projects={projects}
        selectedProjectIDs={selectedProjectIDs}
        setIsOpen={setIsOpen}
        setSelectedProjectIDs={setSelectedProjectIDs}
      />
    </div>
  );
}
