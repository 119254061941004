import React from 'react';

import { formatCost } from '../../../../utilities/currency';

import { PieChartData } from './ChartsPieGraphUtils';

interface Props {
  colors: string[];
  isCurrency?: boolean;
  pieData: PieChartData[];
  title?: string;
}

const ChartsPieLabelTooltip = ({ colors, isCurrency, pieData, title }: Props) => {
  // Calculate the total share
  const totalShare = pieData.reduce((sum, item) => sum + item.share, 0);

  return (
    <div className="flex flex-col type-body2">
      {title && <div className="mb-2 type-heading3">{`Projects by ${title}`}</div>}
      <div>
        <div className="grid grid-cols-[140px_40px_40px] gap-3 text-left">
          {pieData.map((item, i) => (
            <React.Fragment key={item.name}>
              <div className="flex items-center type-body2">
                <div
                  className="mr-2 h-3 w-3"
                  style={{ backgroundColor: getColor(colors, item, i) }}
                />
                <div className="line-clamp-1 w-full">{item.name}</div>
              </div>
              <div className="text-right type-body2">
                {!isCurrency
                  ? item.share
                  : formatCost(item.share, { short: true, showCents: false })}
              </div>
              {/* Calculate percentage */}
              <div className="text-right type-body2">
                {((item.share / totalShare) * 100).toFixed()}%
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChartsPieLabelTooltip;

export const getColor = (colors: string[], data: PieChartData, index: number) => {
  if (data.name.includes('Other ')) {
    return 'var(--colors-button-primary-hover)';
  }
  if (data.name === 'Unassigned') {
    return 'var(--colors-item-status-not-chosen-tint)';
  }
  return colors[index];
};
