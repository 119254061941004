import * as d3 from 'd3';

import { formatCost } from '../../../../utilities/currency';
import { isNonNullable } from '../../../../utilities/types';
import SVGWithDimensions from '../../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../../Charts/ChartsD3/useChartDimensions';
import useMemoWrapper from '../../../useMemoWrapper';
import CostLabelBlur from '../ChartsCostTrendline/CostLabelBlur';
import InsightsEmptyChart from '../InsightsCost/InsightsEmptyChart';
import TimelineArea from '../TimelineArea';
import TimelineHoverSections from '../TimelineHoverSections';
import TimelineMultiTooltip from '../TimelineMultiTooltip/TimelineMultiTooltip';
import TimelinePath from '../TimelinePath';

import ContingenciesFillPattern from './ContingenciesFillPattern';
import { ContingenciesFields, ContingenciesTimeSeries } from './types';
import { getAreaData, getFieldData, getYDomain } from './utils';

const costFormat = { short: true, showCurrencySymbol: false };

type Props = {
  data: ContingenciesTimeSeries[];
  height: number;
  hoverDate?: Date | undefined;
  hasAllowance: boolean;
  hasContingency: boolean;
  isPrint?: boolean;
  setHoverDate: (date: Date | undefined) => void;
  today: Date;
  totalRange: [string, string];
};

export default function InsightsContingencies(props: Props) {
  const {
    data,
    height,
    hoverDate,
    hasAllowance,
    hasContingency,
    isPrint,
    setHoverDate,
    today,
    totalRange,
  } = props;
  const { ref, dimensions } = useChartDimensions({
    height,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  });
  const { width } = dimensions;
  const margin = {
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  };

  // x domain
  const xMin = new Date(totalRange[0]);
  const xMax = new Date(totalRange[1]);
  const xDomain = [xMin, xMax];
  const xRange: [number, number] = [margin.left, width - margin.right];
  // create x scale
  const x = d3.scaleTime().domain(xDomain).range(xRange);

  // y domain
  const [yDataMin, yDataMax] = useMemoWrapper(getYDomain, data, hasAllowance, hasContingency);
  const yDelta = yDataMax - yDataMin;

  // Add vertical padding
  const yMin = yDataMin - (6 * yDelta) / 100;
  const yMax = yDataMax + (18 * yDelta) / 100;
  const yDomain: [number, number] = [yMin, yMax];
  const yRange: [number, number] = [height - margin.bottom, margin.top];
  // create y scale
  const y = d3.scaleLinear().domain(yDomain).range(yRange);

  // Get cost series data separated
  const pendingContingencies = useMemoWrapper(getAreaData, data, 'contingency');
  const totalContingencies = useMemoWrapper(
    getFieldData,
    data,
    ContingenciesFields.total,
    'contingency'
  );
  const pendingAllowances = useMemoWrapper(getAreaData, data, 'allowances');
  const totalAllowances = useMemoWrapper(
    getFieldData,
    data,
    ContingenciesFields.total,
    'allowances'
  );
  // Last data index, the first point for horizontal lines after today
  const lastIndex = totalContingencies.length - 2;

  const todayData = [
    { date: today, value: yMin },
    { date: today, value: yMax },
  ];

  const hoverData = hoverDate
    ? [
        { date: hoverDate, value: yMin },
        { date: hoverDate, value: yMax },
      ]
    : undefined;

  const bounds = {
    right: x(xMax),
    left: x(xMin),
    top: y(yMin),
    bottom: y(yMax),
  };

  // Hover index for tooltips
  const hoverIndex = totalContingencies.findIndex(
    (data) => data?.date?.getTime() === hoverDate?.getTime()
  );
  const hoverDataIndex =
    hoverIndex + 1 < totalContingencies.length - 1 ? hoverIndex + 1 : hoverIndex;

  if (!data.length) {
    return <InsightsEmptyChart height={height} today={today} totalRange={totalRange} />;
  }

  return (
    <SVGWithDimensions ref={ref} data-cy="line-chart" dimensions={dimensions}>
      {/* Hover Line */}
      {hoverData && (
        <TimelinePath<{ value: number }>
          // current hover date
          data={hoverData}
          stroke="stroke-chart-axis"
          strokeWidth={1.5}
          x={x}
          y={y}
        />
      )}
      {hasContingency && (
        <>
          {/* Pending Area Contingencies */}
          <TimelineArea<{ y0: number; y: number }, { x: Date }>
            data={pendingContingencies}
            fill="url(#pattern-contingencies)"
            x={x}
            y={y}
          />
          {/* Total number Contingencies */}
          <TimelinePath<{ value: number }>
            // Before today
            data={totalContingencies}
            stroke="stroke-entities-contingencies"
            strokeWidth={1.5}
            x={x}
            y={y}
          />
          <TimelinePath<{ value: number }>
            // After today Contingencies
            data={[
              totalContingencies[lastIndex],
              { date: xMax, value: totalContingencies[lastIndex].value },
            ]}
            stroke="stroke-entities-contingencies-future"
            strokeWidth={1.5}
            x={x}
            y={y}
          />
        </>
      )}
      {hasAllowance && (
        <>
          {/* Pending Area Allowances */}
          <TimelineArea<{ y0: number; y: number }, { x: Date }>
            data={pendingAllowances}
            fill="url(#pattern-allowances)"
            x={x}
            y={y}
          />
          {/* Total number Allowances */}
          <TimelinePath<{ value: number }>
            // Before today
            data={totalAllowances}
            stroke="stroke-entities-allowances"
            strokeWidth={1.5}
            x={x}
            y={y}
          />
          <TimelinePath<{ value: number }>
            // After today Allowances
            data={[
              totalAllowances[lastIndex],
              { date: xMax, value: totalAllowances[lastIndex].value },
            ]}
            stroke="stroke-entities-allowances-future"
            strokeWidth={1.5}
            x={x}
            y={y}
          />
        </>
      )}
      {/* Today */}
      <TimelinePath<{ value: number }>
        data={todayData}
        stroke="stroke-selection-focus-fill"
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      {/* Hover Points */}
      {!isPrint && hoverDate && (
        <TimelineMultiTooltip
          key={`tooltip-${hoverIndex}-${hoverDate}`}
          backgroundColor={[
            // Tooltip background colors
            hasContingency ? 'var(--colors-entities-contingencies-label)' : null,
            hasAllowance ? 'var(--colors-entities-allowances-label)' : null,
          ].filter(isNonNullable)}
          content={[
            hasContingency
              ? formatCost(totalContingencies[hoverDataIndex].value, costFormat)
              : null,
            hasAllowance ? formatCost(totalAllowances[hoverDataIndex].value, costFormat) : null,
          ].filter(isNonNullable)}
          data={[
            hasContingency
              ? {
                  ...totalContingencies[hoverDataIndex],
                  date: totalContingencies[hoverIndex].date,
                }
              : null,
            hasAllowance
              ? {
                  ...totalAllowances[hoverDataIndex],
                  date: totalAllowances[hoverIndex].date,
                }
              : null,
          ].filter(isNonNullable)}
          fill={[
            // Points fill colors
            hasContingency ? 'fill-entities-contingencies' : null,
            hasAllowance ? 'fill-entities-allowances' : null,
          ].filter(isNonNullable)}
          isOpen
          x={x}
          y={y}
          yDomain={yDomain}
        />
      )}
      <TimelineHoverSections
        bounds={bounds}
        data={totalContingencies}
        onHoverIndex={(index) =>
          index === -1 ? setHoverDate(undefined) : setHoverDate(totalContingencies[index]?.date)
        }
        x={x}
      />
      <ContingenciesFillPattern
        fill="fill-entities-contingencies"
        fillBgn="fill-entities-contingencies-pending"
        id="pattern-contingencies"
      />
      <ContingenciesFillPattern
        fill="fill-entities-allowances"
        fillBgn="fill-entities-allowances-pending"
        id="pattern-allowances"
      />
      <CostLabelBlur />
    </SVGWithDimensions>
  );
}
