export enum ContingenciesFields {
  total = 'total',
  remaining = 'remaining',
  pending = 'pending',
  draw = 'draw',
  numDraws = 'numDraws',
}

export type ContingenciesTimeSeries = {
  date: Date;
  allowances: {
    total: number;
    remaining: number;
    pending: number;
    draw: number;
    numDraws: number;
  };
  contingency: {
    total: number;
    remaining: number;
    pending: number;
    draw: number;
    numDraws: number;
  };
};
