import { DD_AUTODESK_PCO_INTEGRATION } from '../../../../features';
import { SourceSystem, UserSourcesQuery } from '../../../../generated/graphql';
import { useHasFeature } from '../../../../hooks/useHasFeature';

import { getSourceSystemIcon } from './IntegrationsUtils';
import ProcoreSVGLoggedOut from './ProcoreSVGLoggedOut';

type Props = {
  isLoggedIntoProcore: boolean;
  name: string;
  userSources: UserSourcesQuery['userSources'] | undefined;
};

function IntegrationsHeader(props: Props) {
  const hasAutodeskPCOIntegrationFeature = useHasFeature(DD_AUTODESK_PCO_INTEGRATION);

  const linkedAccounts = props.userSources?.sources?.map((s) => s.sourceSystem);
  const autodeskLoggedIn = linkedAccounts?.includes(SourceSystem.AUTODESK);
  const procoreLoggedIn = linkedAccounts?.includes(SourceSystem.PROCORE);

  return (
    <div className="flex flex-grow  justify-between">
      <div
        className="pt-0.5 text-base font-medium leading-none text-type-primary"
        data-cy="item-details-integrations-panel"
      >
        {props.name}
      </div>
      {hasAutodeskPCOIntegrationFeature ? (
        <div className="flex gap-3">
          {autodeskLoggedIn && getSourceSystemIcon(SourceSystem.AUTODESK)}
          {procoreLoggedIn && getSourceSystemIcon(SourceSystem.PROCORE)}
        </div>
      ) : (
        <div className={`${props.isLoggedIntoProcore ? '' : 'grayscale'}`}>
          <ProcoreSVGLoggedOut />
        </div>
      )}
    </div>
  );
}

export default IntegrationsHeader;
